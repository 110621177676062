export const HIDDEN_FEATURES = [
  'PENS',
  'Deep Learning',
  'Coaching',
  'Can scan documents, courses, content, audio and video files and produce results',
  'Mobile coaching capabilities within an app',
  'Learner can select from a group of "experts" within the system',
  'Microsoft Teams Integration',
  'Knowledge reinforcement tool',
  'Business Intelligence integration',
  'Ad-hoc',
		'Single Sign On',
		'HRIS/ERP/Salesforce/Sharepoint/Other integration(s)',
  'AI',
    'If a learner does not complete a piece of content, the algorithm will still produce similar results to those if the learner did complete the content.',
  'Administrator',
    'Batch upload of learners',
  'Coaching',
    'Coach / mentor can communicate with the learner via web cam or other solution within the system',
    'Coaches\'s expertise is visible on their profiles within the system',
  'Competency/Skill Management',
  'Curation',
    'Learner can share any content, including video, audio, etc.with a single learner, multiple learners, shared groups or in an area or section designated for curation',
    'Manager can accept or decline various curation capabilities',
  'General',
    'Custom Domain',
    'Open REST API',
  'Learning Environment',
    'Learner Transcript or Progress Circle/Bar',
  'Learning Record Store (LRS)',
  'Playlists',
    'Ability to add Podcasts to playlist',
    'Learner\'s interests or topics playlist',
    'Manager can create a learner\'s playlist',
    'Recommend content playlist',
    'System can generate a playlist based on skill rating, skill level or any other skill related variable',
    'The learner can create their own content playlist and share it with others',
  'Reporting and Analytics',
    'Ad - Hoc Reporting',
    'Analytics include not only content within the platform, but also web site links, articles posted on the net, eBooks, etc.',
    'Can create KPIs and / or Heat maps',
  'Security',
  'Skills Management',
    'Skills tied to job roles / job profiles, which can be used to submit to jobs within the company',
  'Social',
    'List Interests, Skills and other information as part of your Profile',
  'Upload 3rd party authoring tool (ex: Articulate Storyline, Captivate, dominKnow, etc.)',
  'Video Management',
    'Ability to add a quiz/assessment to a video (within the video)',
    'Auto detect Frames per second and view',
  'vILT',
    'Web-conferencing integration',
  'Ability to skin/brand each tenant/portal',
]
