import lmsReducer from './reducer'
import rootSaga from "./sagas"
import {
  fetchApplications,
  fetchAudienceTypes,
  fetchCourseStandards,
  fetchFeatures,
  fetchIndustries,
} from './actions'

const getLmsModule = () => ({
  id: 'lms',
  reducerMap: {
    lms: lmsReducer,
  },
  sagas: [
    rootSaga,
  ],
  initialActions: [
			fetchApplications(),
			fetchAudienceTypes(),
			fetchCourseStandards(),
			fetchFeatures(),
			fetchIndustries(),
  ],
  finalActions: [],
})

export default getLmsModule
