import React from 'react'
import {
  styled,
  Checkbox as MuiCheckbox,
  FormControlLabel as MuiFormControlLabel,
} from '@material-ui/core'
import {useAudienceTypesState, useEditApplication} from 'lms/hooks'

const Content = styled('div')({
})

const Heading = styled('h4')(({theme}) => ({
  margin: 0,
  marginTop: 12,
  marginBottom: 6,
  fontSize: 18,
  fontWeight: 500,
  color: theme.palette.text.secondary,
  borderBottom: `1px solid ${theme.palette.divider}`,
}))

const Grid = styled('div')({
  display: 'flex',
})

const FormControlLabel = styled(MuiFormControlLabel)({
})

const nameSorter = (a, b) => a.name.localeCompare(b.name)

const AudienceTypes = (props) => {
  const [application, updateApplication] = useEditApplication()
  const {data} = useAudienceTypesState()

  const appAudienceTypes = application.audienceTypes || []
  const isSelected = id => appAudienceTypes.includes(id)
  const createCheck = id => <MuiCheckbox checked={isSelected(id)} onChange={(e, checked) => updateAudienceTypes(id, checked)}/>

  const items = []
  const sortedAudienceTypes = data.allIds.map(id => data.byId[id]).sort(nameSorter)

  sortedAudienceTypes.forEach((audienceType) => {
    /* Don't show removed audienceType */
    if(audienceType.name === "B2B" || audienceType.name === 'B2C' || audienceType.name === 'Customers' || audienceType.name === 'Other') {
      return
    }
    
    items.push(
      <FormControlLabel
        key={audienceType.id}
        label={audienceType.name}
        control={createCheck(audienceType.id)}
      />
    )
  })

  /* Place 'Other' at the end if it exists */
  sortedAudienceTypes.filter(sortedAudienceTypes => sortedAudienceTypes.name === 'Other').map(audienceType => (
    items.push(
      <FormControlLabel
        key={audienceType.id}
        label={audienceType.name}
        control={createCheck(audienceType.id)}
      />
    )
  ))

  

  

  const updateAudienceTypes = (id, checked) => {
    const set = new Set(appAudienceTypes)
    checked ? set.add(id) : set.delete(id)
    updateApplication({audienceTypes: Array.from(set)})
  }

  
  return (
    <Content {...props}>
      <Heading>Audience Types</Heading>
      <Grid>
        {items}       
      </Grid>
    </Content>
  )
}

export default AudienceTypes
