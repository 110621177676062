import React from 'react'
import {useDispatch} from 'react-redux'
import {
  styled,
} from '@material-ui/core'
import {websiteLinkVisited} from 'lms/store/actions'

const Link = styled('a')(({theme}) => ({
  color: theme.palette.primary.dark,
  display: 'block',
  textDecoration: 'none',
}))

const WebsiteLink = ({lms, ...props}) => {
  const dispatchRedux = useDispatch()

  if (!lms.website) return null

  let websiteUrl = lms.website

  if(!lms.website.includes("https://") && !lms.website.includes("http://")) {
    websiteUrl = 'http://' + websiteUrl
  }

  const handleClick = () => dispatchRedux(websiteLinkVisited(lms.id))

  return (
    <Link
      href={websiteUrl}
      target="_blank"
      rel="noopener"
      onClick={handleClick}
      {...props}
    >
      {lms.website}
    </Link>
  )
}

export default WebsiteLink
