import React from 'react'
import {Link} from 'react-router-dom'
import {styled} from '@material-ui/core/styles'
import logoSrc from 'images/FindAnLMS-logo-ai.png'

const StyledLink = styled(Link)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  height: 44,
  padding: 6,
})

const Logo = styled('img')({
  height: '100%',
  objectFit: 'contain',
})

const HomeLink = (props) => {
  return (
    <StyledLink to="/" {...props}>
      <Logo src={logoSrc}/>
    </StyledLink>
  )
}

export default HomeLink
