export const getApplicationsState = store => store.lms.applications
export const getAudienceTypesState = store => store.lms.audienceTypes
export const getCourseStandardsState = store => store.lms.courseStandards
export const getFeaturesState = store => store.lms.features
export const getIndustriesState = store => store.lms.industries

export const getEditApplicationState = store => store.lms.editing

export const getCompareSystemIds = store => store.lms.compare.systemIds

export const getPlaylistItemViewsData = store => store.lms.playlistItemViews