import moment from 'moment'
import stringify from 'csv-stringify/lib/sync'
import {all, call, put, select, take, takeLatest} from 'redux-saga/effects'
import feathersClient from 'utils/feathers'
import {getCurrentUser, getUsers} from 'users/store/selectors'
import {actionTypes as appActionTypes} from 'app/store/constants'
import {actionTypes as leadsActionTypes} from 'leads/store/constants'
import {actionTypes as searchesActionTypes} from 'searches/store/constants'
import {actionTypes as usersActionTypes} from 'users/store/constants'
import {actionTypes as lmsActionTypes} from 'lms/store/constants'
import {actionTypes} from './constants'
import {fetchLeads} from 'leads/store/actions'
import {fetchSearches} from 'searches/store/actions'
import {fetchUsers} from 'users/store/actions'
import {
  getApplicationsState,
  getAudienceTypesState,
  getCourseStandardsState,
  getFeaturesState,
  getIndustriesState, getPlaylistItemViewsData, playlistItemViewsData,
} from 'lms/store/selectors'
import {getSearchesData} from 'searches/store/selectors'
import {getLeadsData} from 'leads/store/selectors'
import {getMode} from 'app/store/selectors'
import {getReportType, getReportOptions, getReportData} from './selectors'
import {fetchPlaylistItemViews} from "../../lms/store/actions"
import {map, countBy, uniqBy} from 'lodash'

const usersService = feathersClient.service('users')
const applicationsService = feathersClient.service('applications')
const searchesService = feathersClient.service('searches')
const playlistMetricsService = feathersClient.service('playlistItemMetrics')
const leadsService = feathersClient.service('leads')


function* resetCountsSaga(action) {
  yield put({type: actionTypes.RESET_COUNTS})
}

function* fetchCountsSaga(action) {
  const state = yield select()
  const mode = getMode(state)
  const user = getCurrentUser(state)

  const oneWeekAgo = moment().subtract(7, 'days').startOf('day')
  const oneMonthAgo = moment().subtract(1, 'month').startOf('day')

  const leadsQuery = {
    version: 2,
    application: mode === 'vendor' ? {$in: user.applications} : undefined,
    $limit: 0,
  }

  const searchesQuery = {
    version: 2,
    applications: mode === 'vendor' ? {$in: user.applications} : undefined,
    $limit: 0,
  }

  const playlistViewsQuery = {
    application: mode === 'vendor' ? {$in: user.applications} : undefined,
    $limit: 0,
  }

  const leads = {
    total: (yield call([leadsService, 'find'], {query: leadsQuery})).total,
    newThisWeek: (yield call([leadsService, 'find'], {
      query: {...leadsQuery, createdAt: {$gt: oneWeekAgo}},
    })).total,
    newThisMonth: (yield call([leadsService, 'find'], {
      query: {...leadsQuery, createdAt: {$gt: oneMonthAgo}},
    })).total,
  }

  const searches = {
    total: (yield call([searchesService, 'find'], {query: searchesQuery}))
      .total,
    newThisWeek: (yield call([searchesService, 'find'], {
      query: {...searchesQuery, createdAt: {$gt: oneWeekAgo}},
    })).total,
    newThisMonth: (yield call([searchesService, 'find'], {
      query: {...searchesQuery, createdAt: {$gt: oneMonthAgo}},
    })).total,
  }

  const res = yield call([playlistMetricsService, 'find'], {query: playlistViewsQuery})
  const playlistViews = {
    total: (res).total,
    newThisWeek: (yield call([playlistMetricsService, 'find'], {
      query: {...playlistViewsQuery, createdAt: {$gt: oneWeekAgo}},
    })).total,
    newThisMonth: (yield call([playlistMetricsService, 'find'], {
      query: {...playlistViewsQuery, createdAt: {$gt: oneMonthAgo}},
    })).total,
  }

  const users = {
    total: (yield call([usersService, 'find'], {query: {$limit: 0}})).total,
    admin: (yield call([usersService, 'find'], {
      query: {role: 'admin', $limit: 0},
    })).total,
    vendor: (yield call([usersService, 'find'], {
      query: {role: 'vendor', $limit: 0},
    })).total,
    newThisWeek: (yield call([usersService, 'find'], {
      query: {createdAt: {$gt: oneWeekAgo}, $limit: 0},
    })).total,
    newThisMonth: (yield call([usersService, 'find'], {
      query: {createdAt: {$gt: oneMonthAgo}, $limit: 0},
    })).total,
  }

  const systems = {
    total: (yield call([applicationsService, 'find'], {query: {$limit: 0}}))
      .total,
    newThisWeek: (yield call([applicationsService, 'find'], {
      query: {createdAt: {$gt: oneWeekAgo}, $limit: 0},
    })).total,
    newThisMonth: (yield call([applicationsService, 'find'], {
      query: {createdAt: {$gt: oneMonthAgo}, $limit: 0},
    })).total,
    premium: (yield call([applicationsService, 'find'], {
      query: {isPremium: true, $limit: 0},
    })).total,
    platinum: (yield call([applicationsService, 'find'], {
      query: {isPlatinum: true, $limit: 0},
    })).total,
  }

  yield put({
    type: actionTypes.FETCH_COUNTS_SUCCEEDED,
    payload: {leads, searches, playlistViews, users, systems},
  })
}

function* fetchReportDataSaga(reportType,state) {
  const fetchTypes = {}
  const mode = getMode(state)
  const currentUser = getCurrentUser(state)

  let fetchTypeToCheck

  switch (reportType) {
    case 'leads':
      yield put(fetchLeads())
      fetchTypeToCheck = leadsActionTypes.FETCH_LEADS_SUCCEEDED
      break
    case 'searches':
    case 'popularSearches':
      yield put(fetchSearches())
      fetchTypeToCheck = searchesActionTypes.FETCH_SEARCHES_SUCCEEDED
      break
    case 'contentPlayerViews':
    case 'popularContentPlayerViews':
      yield put(fetchPlaylistItemViews({mode,user:currentUser}))
      fetchTypeToCheck = lmsActionTypes.FETCH_PLAYLIST_ITEM_VIEWS_SUCCEEDED
      break

    case 'users':
      yield put(fetchUsers())
      fetchTypeToCheck = usersActionTypes.FETCH_USERS_SUCCEEDED
      break
    default:
      break
  }

  fetchTypes[fetchTypeToCheck] = true

  let stillFetching = true
  while (stillFetching) {
    const action = yield take(fetchTypeToCheck)
    if (action.payload.completed) fetchTypes[action.type] = false
    stillFetching = fetchTypes[fetchTypeToCheck]
  }

  yield put({type: actionTypes.REPORT_DATA_LOADED})
}

function* generateReportSaga(action) {
  const state = yield select()
  const reportType = getReportType(state)
  const reportData = getReportData(state)

  // skip if no report type
  if (!reportType) return

  if (!reportData.loaded) yield fetchReportDataSaga(reportType,state)

  if (reportData.url) window.URL.revokeObjectURL(reportData.url)

  const type = reportType
  const name = yield generateReportName()
  const csv = yield generateCsv()
  const blob = new Blob([csv], {type: 'text/csv'})
  const url = window.URL.createObjectURL(blob)

  const data = {
    type,
    name,
    csv,
    url,
    ready: true,
  }

  yield put({type: actionTypes.REPORT_UPDATED, payload: {data}})
}

function* generateReportName() {
  const state = yield select()
  const reportType = getReportType(state)
  const reportOptions = getReportOptions(state)

  let name = `${reportType}`

  if (reportOptions.range !== 'all') {
    const startDateStr = moment(reportOptions.startDate).format('YYYYMMDD')
    const endDateStr = moment(reportOptions.endDate).format('YYYYMMDD')

    name += `_${startDateStr}`
    if (endDateStr !== startDateStr) name += `-${endDateStr}`
  }

  name += '.csv'

  return name
}

function* generateCsv() {
  const state = yield select()
  const reportType = getReportType(state)

  switch (reportType) {
    case 'leads':
      return yield generateLeadsCsv()

    case 'searches':
      return yield generateSearchesCsv()

    case 'popularSearches':
      return yield generatePopularSearchesCsv()

    case 'contentPlayerViews':
      return yield generatePlayistViewsCsv()

    case 'popularContentPlayerViews':
      return yield generatePopularPlaylistItemsCsv()

    case 'users':
      return yield generateUsersCsv()

    // no default
  }
}

function* generateLeadsCsv() {
  const state = yield select()
  const mode = getMode(state)
  const currentUser = getCurrentUser(state)
  const reportOptions = getReportOptions(state)

  const leadsData = getLeadsData(state)
  const {data: usersData} = getUsers(state)
  const {data: appsData} = getApplicationsState(state)

  let leads, csvOptions
  if (mode === 'admin') {
    csvOptions = {
      header: true,
      columns: [
        'id',
        'createdAt',
        'updatedAt',
        'system',
        'sources',
        'user',
        'company',
        'jobTitle',
        'email',
        'phone',
        'city',
        'state',
        'country',
        'usageType',
        'usageTypeExplanation',
        'purchaseLearningSystem'
      ],
    }

    leads = Object.values(leadsData.byId)

    if (reportOptions.range !== 'all') {
      leads = leads.filter(l =>
        moment(l.updatedAt).isBetween(
          reportOptions.startDate,
          reportOptions.endDate,
          'day',
          '[]'
        )
      )
    }

    leads = leads.reduce((acc, lead) => {
      try {
        const user = usersData.byId[lead.user] || {}
        const app = appsData.byId[lead.application]
        const system = app && app.name
        const triggers = new Set(
          lead.actions.map(a =>
            a.trigger === 'RFP Submitted' ? 'Request Information' : (a.trigger === 'Requested Quote' ? 'Request Information' : a.trigger)
          )
        )

        if (system) {
          acc.push({
            ...lead,
            createdAt: moment(lead.createdAt).format('YYYY-MM-DD'),
            updatedAt: moment(lead.updatedAt).format('YYYY-MM-DD'),
            system,
            sources: Array.from(triggers),
            user: `${user.firstName} ${user.lastName}`,
            company: user.companyName,
            jobTitle: user.jobTitle,
            email: user.email,
            phone: user.phone,
            city: user.city,
            state: user.state,
            country: user.country,
            usageType: user.usageType,
            usageTypeExplanation: user.usageTypeExplanation,
            purchaseLearningSystem: user.purchaseLearningSystem,
          })
        }
      } catch (error) {
        // output empty row
        return {}
      }
      return acc
    }, [])
  } else {
    csvOptions = {
      header: true,
      columns: [
        'createdAt',
        'updatedAt',
        'system',
        'sources',
        'leadName',
        'leadCompany',
        'leadJobTitle',
        'leadEmail',
        'leadPhone',
        'leadCity',
        'leadState',
        'leadCountry',
        'otherSystems',
        'comparedSystems',
        'usageType',
        'usageTypeExplanation',
        'purchaseLearningSystem'
      ],
    }

    leads = []

    const allLeads = Object.values(leadsData.byId)
    const allSystems = Object.values(appsData.byId)

    const vendorSystems = allSystems.filter(
      s => s.admins && s.admins.includes(currentUser.id)
    )

    for (const system of vendorSystems) {
      let systemLeads = allLeads.filter(l => l.application === system.id)

      if (reportOptions.range !== 'all') {
        systemLeads = systemLeads.filter(l =>
          moment(l.updatedAt).isBetween(
            reportOptions.startDate,
            reportOptions.endDate,
            'day',
            '[]'
          )
        )
      }

      for (const lead of systemLeads) {
        try {
          const leadUser = usersData.byId[lead.user] || {}
          const triggers = new Set(
            lead.actions.map(a =>
              //a.trigger === 'RFP Submitted' ? 'Request Information' : a.trigger
              a.trigger === 'RFP Submitted' ? 'Request Information' : (a.trigger === 'Requested Quote' ? 'Request Information' : a.trigger)
            )
          )
          const comparedLeadActions = lead.actions.filter(
            lead => lead.trigger === 'Compared'
          )
          const comparedSystems = comparedLeadActions.reduce(
            (acc, leadActions) => {
              const {otherSystems} = leadActions
              if (otherSystems) {
                otherSystems.forEach(id => acc.push(appsData.byId[id].name))
              }
              return acc
            },
            []
          )
          const compareLeads = allLeads.reduce((acc, l) => {
            const {actions, application} = l
            const areActionsOtherThanCompare = actions.some(
              action => action.trigger === 'Compared'
            )
            if (areActionsOtherThanCompare) {
              acc.push(application)
            }
            return acc
          }, [])
          const otherSystemIds = allLeads
            .filter(l => l.user === lead.user)
            .map(l => l.application)
          const otherSystemIdsWithNames = otherSystemIds.filter(id => {
            const isCompareLead = compareLeads.includes(id)
            return !isCompareLead && Boolean(appsData.byId[id])
          })

          leads.push({
            ...lead,
            createdAt: moment(lead.createdAt).format('YYYY-MM-DD'),
            updatedAt: moment(lead.updatedAt).format('YYYY-MM-DD'),
            system: system.name,
            sources: Array.from(triggers),
            leadName: `${leadUser.firstName} ${leadUser.lastName}`,
            leadCompany: leadUser.companyName,
            leadJobTitle: leadUser.jobTitle,
            leadEmail: leadUser.email,
            leadPhone: leadUser.phone,
            leadCity: leadUser.city,
            leadState: leadUser.state,
            leadCountry: leadUser.country,
            otherSystems: otherSystemIdsWithNames.map(
              id => appsData.byId[id].name
            ),
            comparedSystems: [...new Set(comparedSystems)].map(
              system => system
            ),
            usageType: leadUser.usageType,
            usageTypeExplanation: leadUser.usageTypeExplanation,
            purchaseLearningSystem: leadUser.purchaseLearningSystem,
          })
        } catch (error) {
          // don't output row
        }
      }
    }
  }

  return stringify(leads, csvOptions)
}

function* generateSearchesCsv() {
  const state = yield select()
  const reportOptions = getReportOptions(state)
  const mode = getMode(state)
  const isVendor = mode === 'vendor'
  const currentUser = getCurrentUser(state)

  const searchesData = getSearchesData(state)
  const {data: usersData} = getUsers(state)
  const {data: appsData} = getApplicationsState(state)
  const {data: audienceTypesData} = getAudienceTypesState(state)
  const {data: courseStandardsData} = getCourseStandardsState(state)
  const {data: featuresData} = getFeaturesState(state)
  const {data: industriesData} = getIndustriesState(state)

  const csvOptions = {
    header: true,
    columns: isVendor
      ? [
        'createdAt',
        'updatedAt',
        'user',
        'company',
        'jobTitle',
        'email',
        'phone',
        'source',
        'filters',
        'systems',
      ]
      : [
        'id',
        'createdAt',
        'updatedAt',
        'user',
        'company',
        'jobTitle',
        'email',
        'phone',
        'source',
        'filters',
        'systems',
      ],
  }

  const getSearches = () => {
    let searchesToRender = Object.values(searchesData.byId)
    if (isVendor) {
      const {applications: vendorApplications} = currentUser
      const searchesFromVendorApplications = searchesToRender.filter(search => {
        const {applications: applicationsInSearch} = search
        return applicationsInSearch.some(applicationInSearch =>
          vendorApplications.includes(applicationInSearch)
        )
      })
      searchesToRender = searchesFromVendorApplications
    }
    return searchesToRender
  }

  let searches = getSearches()

  if (reportOptions.range !== 'all') {
    searches = searches.filter(s =>
      moment(s.updatedAt).isBetween(
        reportOptions.startDate,
        reportOptions.endDate,
        'day',
        '[]'
      )
    )
  }

  searches = searches.map(search => {
    try {
      const user = usersData.byId[search.user] || {}

      const getValues = (value, reference) =>
        value.reduce((values, id) => {
          const valueToAdd = reference.byId[id] && reference.byId[id].name
          if (valueToAdd) values.push(valueToAdd)
          return values
        }, [])

      const systems = getValues(search.applications, appsData)
      //
      const source = search.source === 'RFP Submitted' ? 'Request Information' : search.source
      //
      const filters = {}
      for (let [key, value] of Object.entries(search.filters)) {
        switch (key) {
          case 'audienceTypes':
            value = getValues(value, audienceTypesData)
            break

          case 'courseStandards':
            value = getValues(value, courseStandardsData)
            break

          case 'features':
            value = getValues(value, featuresData)
            break

          case 'industries':
            value = getValues(value, industriesData)
            break

          // no default
        }

        filters[key] = value
      }

      return {
        ...search,
        createdAt: moment(search.createdAt).format('YYYY-MM-DD'),
        updatedAt: moment(search.updatedAt).format('YYYY-MM-DD'),
        filters,
        user: `${user.firstName} ${user.lastName}`,
        company: user.companyName,
        jobTitle: user.jobTitle,
        email: user.email,
        phone: user.phone,
        systems,
        source: source
      }
    } catch (error) {
      // output empty row
      return {}
    }
  })

  return stringify(searches, csvOptions)
}

function* generatePopularSearchesCsv() {
  const state = yield select()
  const reportOptions = getReportOptions(state)
  const mode = getMode(state)
  const currentUser = getCurrentUser(state)
  const isVendor = mode === 'vendor'

  const searchesData = getSearchesData(state)
  const {data: audienceTypesData} = getAudienceTypesState(state)
  const {data: courseStandardsData} = getCourseStandardsState(state)
  const {data: featuresData} = getFeaturesState(state)
  const {data: industriesData} = getIndustriesState(state)

  const csvOptions = {
    header: true,
    columns: ['Filter Type', 'Value', 'Count'],
  }

  const getSearches = () => {
    let searchesToRender = Object.values(searchesData.byId)
    if (isVendor) {
      const {applications: vendorApplications} = currentUser
      const searchesFromVendorApplications = searchesToRender.filter(search => {
        const {applications: applicationsInSearch} = search
        return applicationsInSearch.some(applicationInSearch =>
          vendorApplications.includes(applicationInSearch)
        )
      })
      searchesToRender = searchesFromVendorApplications
    }
    return searchesToRender
  }

  let searches = getSearches()

  if (reportOptions.range !== 'all') {
    searches = searches.filter(s =>
      moment(s.updatedAt).isBetween(
        reportOptions.startDate,
        reportOptions.endDate,
        'day',
        '[]'
      )
    )
  }

  const getValuesFromValueArray = ({
    acc,
    values,
    getIndexOfExistingFilter,
    filterType,
    typeData,
  }) => {
    values.forEach(value => {
      const valueForReport = typeData.byId[value] && typeData.byId[value].name
      if (valueForReport) {
        const indexOfExistingFilter = getIndexOfExistingFilter({
          filterType,
          value: valueForReport,
        })
        const hasValueBeenCounted = indexOfExistingFilter > -1
        if (hasValueBeenCounted) {
          const existingSearch = acc[indexOfExistingFilter]
          existingSearch['Count'] = existingSearch['Count'] + 1
        } else {
          acc.push({
            'Filter Type': filterType,
            Value: valueForReport,
            Count: 1,
          })
        }
      }
    })
  }

  const searchFilters = searches.map(search => search.filters)
  const searchResultsForCsv = searchFilters.reduce((acc, filter) => {
    // get the index of the existing counted filter if it exist
    // if it does not exist or it has not been counted, this returns -1
    const getIndexOfExistingFilter = ({filterType, value}) =>
      acc.findIndex(
        countedSearch =>
          countedSearch['Filter Type'] === filterType &&
          (countedSearch['Value'] === value ||
            JSON.stringify(countedSearch['Value']) === JSON.stringify(value) ||
            JSON.stringify(countedSearch['Value']).toLowerCase() ===
              JSON.stringify(value).toLowerCase())
      )
    // converting the object of filters to a list of filter types
    const filterTypes = Object.keys(filter)
    // looping over each filter type and getting the value
    filterTypes.forEach(filterType => {
      let value = filter[filterType]
      if (value) {
        const isValueArray = Array.isArray(value)
        const isValueObject = typeof value === 'object'
        if (isValueArray) {
          switch (filterType) {
            case 'audienceTypes':
              getValuesFromValueArray({
                acc,
                values: value,
                getIndexOfExistingFilter,
                filterType: 'audienceTypes',
                typeData: audienceTypesData,
              })
              break

            case 'courseStandards':
              getValuesFromValueArray({
                acc,
                values: value,
                getIndexOfExistingFilter,
                filterType: 'courseStandards',
                typeData: courseStandardsData,
              })
              break

            case 'features':
              getValuesFromValueArray({
                acc,
                values: value,
                getIndexOfExistingFilter,
                filterType: 'features',
                typeData: featuresData,
              })
              break

            case 'industries':
              getValuesFromValueArray({
                acc,
                values: value,
                getIndexOfExistingFilter,
                filterType: 'industries',
                typeData: industriesData,
              })
              break
            case 'deploymentTypes':
              value.forEach(deploymentType => {
                const indexOfExistingFilter = getIndexOfExistingFilter({
                  filterType: 'deploymentTypes',
                  value: deploymentType,
                })
                const hasValueBeenCounted = indexOfExistingFilter > -1
                if (hasValueBeenCounted) {
                  const existingSearch = acc[indexOfExistingFilter]
                  existingSearch['Count'] = existingSearch['Count'] + 1
                } else {
                  acc.push({
                    'Filter Type': 'deploymentTypes',
                    Value: deploymentType,
                    Count: 1,
                  })
                }
              })
              break

            // no default
          }
        } else if (isValueObject) {
          const valueObjectKeys = Object.keys(value)
          const doesValueObjectExist = valueObjectKeys.some(objectKey =>
            Boolean(value[objectKey])
          )
          if (doesValueObjectExist) {
            const minValue = value['min']
            const maxValue = value['max']
            const valueForReport = maxValue
              ? `${Number(minValue.toFixed(2)).toLocaleString(
                'en-US'
              )}-${Number(maxValue.toFixed(2)).toLocaleString('en-US')}`
              : `${Number(minValue.toFixed(2)).toLocaleString('en-US')}`
            const indexOfExistingFilter = getIndexOfExistingFilter({
              filterType,
              value: valueForReport,
            })
            const hasFeatureBeenCounted = indexOfExistingFilter > -1
            if (hasFeatureBeenCounted) {
              const existingSearch = acc[indexOfExistingFilter]
              existingSearch['Count'] = existingSearch['Count'] + 1
            } else {
              acc.push({
                'Filter Type': filterType,
                Value: valueForReport,
                Count: 1,
              })
            }
          }
        } else {
          const indexOfExistingFilter = getIndexOfExistingFilter({
            filterType,
            value,
          })
          const doesExistingFilterTypeExist = indexOfExistingFilter > -1
          if (doesExistingFilterTypeExist) {
            const existingSearch = acc[indexOfExistingFilter]
            existingSearch['Count'] = existingSearch['Count'] + 1
          } else {
            const newlyCountedSearch = {
              'Filter Type': filterType,
              Value: value,
              Count: 1,
            }
            acc.push(newlyCountedSearch)
          }
        }
      }
    })
    // returning the sorted array by count (largest to smallest)
    return acc.sort((a, b) => b.Count - a.Count)
  }, [])

  return stringify(searchResultsForCsv, csvOptions)
}

function* generatePopularPlaylistItemsCsv() {
  const state = yield select()
  const csvOptions = {
    header: true,
    columns: ['System', 'Title', 'Views'],
  }

  const data = getPlaylistItemViewsData(state)


  const countsByItemId = countBy(data,'playlistItem.id')

  const uniq = uniqBy(data,d => d.playlistItem.id)
  const csv = map(uniq,d => ({
    System: d.application.name,
    Title: d.playlistItem.title,
    Views: countsByItemId[d.playlistItem.id]
  }))

  csv.sort((a, b) => {
    if(a.Views < b.Views) {
      return 1
    }
    if(a.Views > b.Views) {
      return -1
    }
    else {
      return 0
    }
  })

  return stringify(csv, csvOptions)
}

function * generatePlayistViewsCsv(){
  const state = yield select()
  const csvOptions = {
    header: true,
    columns: ['Created At', 'System', 'Title', 'Duration Viewed','User', 'Company', 'Job Title','Email', 'Phone'],
  }

  const data = getPlaylistItemViewsData(state)


  const csv = map(data,d => ({
    'Created At': moment(d.createdAt).format('YYYY-MM-DD'),
    System: d.application.name,
    Title: d.playlistItem.title,
    'Duration Viewed': d.duration ? moment.utc(1000 * d.duration).format('HH:mm:ss') : '00:00:00',
    User: d.user.email ? `${d.user.firstName} ${d.user.lastName}` : 'user not found',
    Company: d.user.companyName,
    'Job Title': d.user.jobTitle,
    Email: d.user.email,
    Phone: d.user.phone
  }))

  return stringify(csv, csvOptions)
}

function* generateUsersCsv() {
  const state = yield select()
  const reportOptions = getReportOptions(state)

  const {data: usersData} = getUsers(state)
  const {data: appsData} = getApplicationsState(state)

  const csvOptions = {
    header: true,
    columns: [
      'id',
      'createdAt',
      'updatedAt',
      'isVerified',
      'firstName',
      'lastName',
      'companyName',
      'jobTitle',
      'email',
      'phone',
      'city',
      'state',
      'country',
      'favorites',
      'usageType',
      'usageTypeExplanation',
      'contactConsent',
      'purchaseLearningSystem',
    ],
  }

  let users = Object.values(usersData.byId)

  if (reportOptions.range !== 'all') {
    users = users.filter(u =>
      moment(u.updatedAt).isBetween(
        reportOptions.startDate,
        reportOptions.endDate,
        'day',
        '[]'
      )
    )
  }

  users = users.map(user => {
    try {
      const favorites = user.applications.map(id => appsData.byId[id].name)
      const isVerified = user.isVerified ? 'true' : 'false'

      return {
        ...user,
        createdAt: moment(user.createdAt).format('YYYY-MM-DD'),
        updatedAt: moment(user.updatedAt).format('YYYY-MM-DD'),
        favorites,
        isVerified,
      }
    } catch (error) {
      // output empty row
      return {}
    }
  })

  return stringify(users, csvOptions)
}

function* watchModeChange() {
  yield takeLatest(appActionTypes.SET_MODE, resetCountsSaga)
}

function* watchFetchCounts() {
  yield takeLatest(actionTypes.FETCH_COUNTS_REQUESTED, fetchCountsSaga)
}

function* watchGenerateReport() {
  const generateReportActions = [
    actionTypes.OPEN_REPORT_DIALOG,
    actionTypes.SET_REPORT_OPTIONS,
  ]

  yield takeLatest(generateReportActions, generateReportSaga)
}

function* rootSaga() {
  yield all([watchModeChange(), watchFetchCounts(), watchGenerateReport()])
}

export default rootSaga
