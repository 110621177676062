import React from 'react'
import {useDispatch} from 'react-redux'
import {styled} from '@material-ui/core/styles'
import MuiFormControlLabel from '@material-ui/core/FormControlLabel'
import MuiCheckbox from '@material-ui/core/Checkbox'
import {useCurrentSearchFilters} from 'searches/hooks'
import {updateCurrentSearchFilters} from 'searches/store/actions'
import {useCookies} from 'react-cookie'
import {useCurrentUser} from 'users/hooks'
import { THIRTY_DAYS } from 'constants/timeIntervals'

const Fieldset = styled('fieldset')(({theme}) => ({
  margin: 0,
  borderColor: 'rgba(0, 0, 0, 0.23)',
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: theme.shape.borderRadius
}))

const Legend = styled('legend')(({theme}) => ({
  padding: 5,
  fontSize: 12,
  color: theme.palette.text.secondary
}))

const Row = styled('div')({
  display: 'flex',
  padding: '0 7px'
})

const FormControlLabel = styled(MuiFormControlLabel)({})

const Checkbox = styled(MuiCheckbox)({})

const DeploymentTypes = props => {
  const {deploymentTypes} = useCurrentSearchFilters()
  const dispatchRedux = useDispatch()
  const [cookies, setCookie] = useCookies(['guestSearchCount'])
  const user = useCurrentUser()
  const isGuestUser = !user
  const isChecked = type => deploymentTypes.includes(type)

  const setCheck = (type, checked) => {
    const set = new Set(deploymentTypes)
    checked ? set.add(type) : set.delete(type)
    if (isGuestUser) {
      const guestSearchCount = cookies.guestSearchCount || 0
      setCookie(
        'guestSearchCount',
        Number(guestSearchCount) + 1,
        {path: '/'},
        THIRTY_DAYS
      )
    }
    dispatchRedux(
      updateCurrentSearchFilters({deploymentTypes: Array.from(set)})
    )
  }

  const createCheck = id => (
    <Checkbox
      checked={isChecked(id)}
      onChange={(e, checked) => setCheck(id, checked)}
    />
  )

  return (
    <Fieldset {...props}>
      <Legend>Deployment Types</Legend>
      <Row>
        <FormControlLabel label='SaaS' control={createCheck('saas')} />
        <FormControlLabel
          label='Client Self-Hosted'
          control={createCheck('self-hosted')}
        />
      </Row>
    </Fieldset>
  )
}

export default DeploymentTypes
