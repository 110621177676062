import React from 'react'
import {useDispatch} from 'react-redux'
import {styled} from '@material-ui/core/styles'
import MuiMenuItem from '@material-ui/core/MenuItem'
import MuiOutlinedInput from '@material-ui/core/OutlinedInput'
import MuiFormControl from '@material-ui/core/FormControl'
import MuiInputLabel from '@material-ui/core/InputLabel'
import MuiSelect from '@material-ui/core/Select'
import {useLabelWidth} from 'ui/hooks'
import {useCurrentSearchFilters} from 'searches/hooks'
import {updateCurrentSearchFilters} from 'searches/store/actions'
import {useCookies} from 'react-cookie'
import {useCurrentUser} from 'users/hooks'
import { THIRTY_DAYS } from 'constants/timeIntervals'

const FormControl = styled(MuiFormControl)({
  marginTop: 12,
  marginBottom: 0
})

const Budget = ({labelText = 'Max Budget', ...props}) => {
  const filters = useCurrentSearchFilters()
  const dispatchRedux = useDispatch()
  const [cookies, setCookie] = useCookies(['guestSearchCount'])
  const user = useCurrentUser()
  const isGuestUser = !user
  const selectValue = filters.maxPrice ? `${filters.maxPrice}` : 'max'

  const handleSelect = event => {
    const {value} = event.target

    const maxPrice = value === 'max' ? null : Number(value)
    if (isGuestUser) {
      const guestSearchCount = cookies.guestSearchCount || 0
      setCookie(
        'guestSearchCount',
        Number(guestSearchCount) + 1,
        {path: '/'},
        THIRTY_DAYS
      )
    }
    dispatchRedux(updateCurrentSearchFilters({maxPrice}))
  }

  const [labelWidth, labelRef] = useLabelWidth()

  const input = (
    <MuiOutlinedInput id='maxPrice' labelWidth={labelWidth} name='maxPrice' />
  )

  return (
    <FormControl variant='outlined' margin='dense' fullWidth {...props}>
      <MuiInputLabel ref={labelRef} htmlFor='maxPrice'>
        {labelText}
      </MuiInputLabel>
      <MuiSelect
        name='maxBudget'
        value={selectValue}
        onChange={handleSelect}
        input={input}
      >
        <MuiMenuItem value="max"><em>No maximum budget</em></MuiMenuItem>
        <MuiMenuItem value="20000">$20K and under</MuiMenuItem>
        <MuiMenuItem value="50000">$21K to $50K</MuiMenuItem>
        <MuiMenuItem value="100000">$51K to $100K</MuiMenuItem>
        <MuiMenuItem value="250000">$101K to $250K</MuiMenuItem>
        <MuiMenuItem value="500000">$251K to $500K</MuiMenuItem>
        <MuiMenuItem value="999999">$500K to $1M</MuiMenuItem>
        <MuiMenuItem value="1000000">$1M+</MuiMenuItem>
      </MuiSelect>
    </FormControl>
  )
}

export default Budget
