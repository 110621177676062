import React from 'react'
import {
  styled,
  Checkbox as MuiCheckbox,
  FormControlLabel as MuiFormControlLabel,
} from '@material-ui/core'
import {useEditApplication} from 'lms/hooks'

const PlatinumLabel =  styled(MuiFormControlLabel)({
  marginTop: 8,
})

const Platinum = (props) => {
  const [application, updateApplication] = useEditApplication()

  const updatePlatinum = e => updateApplication({isPlatinum: e.target.checked})

  const platinumCheck = <MuiCheckbox color="primary" checked={!!application.isPlatinum} onChange={updatePlatinum}/>
  
  return (
    <PlatinumLabel
      label="Platinum Vendor"
      control={platinumCheck}
      labelPlacement="end"
      disabled={!application.isPremium}
    />
  )
}

export default Platinum
