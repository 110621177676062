import React from 'react'
import Budget from 'searches/components/Budget'
import Timeframe from 'searches/components/Timeframe'

const Price = (props) => {
  return (
    <>
      <Budget labelText="Select your max budget"/>
      <Timeframe/>
    </>
  )
}

export default Price
