import React from 'react'
import {useDispatch} from 'react-redux'
import {styled} from '@material-ui/core/styles'
import MuiMenuItem from '@material-ui/core/MenuItem'
import MuiOutlinedInput from '@material-ui/core/OutlinedInput'
import MuiFormControl from '@material-ui/core/FormControl'
import MuiInputLabel from '@material-ui/core/InputLabel'
import MuiSelect from '@material-ui/core/Select'
import {useLabelWidth} from 'ui/hooks'
import {useIndustriesState} from 'lms/hooks'
import {useCurrentSearchFilters} from 'searches/hooks'
import {updateCurrentSearchFilters} from 'searches/store/actions'

const FormControl = styled(MuiFormControl)({
  marginTop: 12,
  marginBottom: 0,
})

const PrimaryIndustry = ({labelText = "Industries", ...props}) => {
  const {data} = useIndustriesState()
  const {industries} = useCurrentSearchFilters()
  const dispatchRedux = useDispatch()
  const [labelWidth, labelRef] = useLabelWidth(labelText)

  // TODO: store sorted IDs in Redux
  const nameSorter = (a, b) => data.byId[a].name.localeCompare(data.byId[b].name)
  const sortedIds = data.allIds.sort(nameSorter)
  const items = sortedIds.map(id => <MuiMenuItem key={id} value={id}>{data.byId[id].name}</MuiMenuItem>)

  const selectValue = industries[0] || ""

  const handleSelect = (event) => {
    const {value} = event.target
    const industries = value ? [value] : []
    dispatchRedux(updateCurrentSearchFilters({industries}))
  }

  const input = <MuiOutlinedInput id="industries" labelWidth={labelWidth} name="industries" />

  return (
    <FormControl
      variant="outlined"
      margin="dense"
      fullWidth
      {...props}
    >
      <MuiInputLabel ref={labelRef} htmlFor="industries">{labelText}</MuiInputLabel>
      <MuiSelect
        name="industries"
        value={selectValue}
        onChange={handleSelect}
        input={input}
      >
        {items}
      </MuiSelect>
    </FormControl>
  )
}

export default PrimaryIndustry
