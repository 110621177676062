import React, {useState} from 'react'
import {Redirect} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {styled} from '@material-ui/styles'
import MuiStepper from '@material-ui/core/Stepper'
import MuiStep from '@material-ui/core/Step'
import MuiStepLabel from '@material-ui/core/StepLabel'
import MuiStepContent from '@material-ui/core/StepContent'
import MuiButton from '@material-ui/core/Button'
import {useCurrentUser} from 'users/hooks'
import {saveCurrentSearch} from 'searches/store/actions'
import Organization from './Organization'
import Features from './Features'
import Price from './Price'
import {useCurrentSearchFilters} from 'searches/hooks'
import {onboardingSchema} from './validations'

const steps = [
  {name: "About Your Organization", Component: Organization},
  {name: "Learning System Settings & Features", Component: Features},
  {name: "Price & Implementation", Component: Price},
]

const Content = styled('div')({
})

const Actions = styled('div')({
  marginTop: 10,
  display: 'grid',
  gridAutoFlow: 'column',
  gridColumnGap: 8,
  justifyContent: 'flex-end',
})

const Wizard = (props) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [errors, setErrors] = useState({})
  const [completed, setCompleted] = useState(false)
  const currentUser = useCurrentUser()
  const filters = useCurrentSearchFilters()
  const dispatchRedux = useDispatch()

  if (completed && currentUser.latestSearch) return <Redirect to="/catalog"/>

  const handlePrev = () => setCurrentStep(currentStep - 1)
  const handleNext = () => {
    try {
      onboardingSchema[currentStep].validateSync(filters, {abortEarly: false})

      setErrors({})

      if (currentStep === steps.length - 1) {
        setCompleted(true)
        dispatchRedux(saveCurrentSearch("Onboarding"))
      } else {
        setCurrentStep(currentStep + 1)
      }
    } catch ({inner}) {
      const errors = {}

      inner.forEach(e => errors[e.path.split(".")[0]] = e.message)

      setErrors(errors)
    }
  }

  return (
    <Content {...props}>
      <MuiStepper activeStep={currentStep} orientation="vertical">
        {steps.map(({name, Component}, step) => {
          let prevButton
          if (step > 0) prevButton = <MuiButton onClick={handlePrev}>Previous</MuiButton>

          let nextButton
          if (step === steps.length - 1) {
            nextButton = <MuiButton color="primary" variant="contained" onClick={handleNext} disabled={completed}>View Results</MuiButton>
          } else {
            nextButton = <MuiButton color="primary" variant="contained" onClick={handleNext}>Next</MuiButton>
          }

          return (
            <MuiStep key={step}>
              <MuiStepLabel>{name}</MuiStepLabel>
              <MuiStepContent>
                <Component step={step} errors={errors}/>
                <Actions>
                  {prevButton}
                  {nextButton}
                </Actions>
              </MuiStepContent>
            </MuiStep>
          )
        })}
      </MuiStepper>
    </Content>
  )
}

export default Wizard
