import React from 'react'
import {styled, alpha} from '@material-ui/core/styles'
import {NavLink} from 'react-router-dom'
import MuiShowChartIcon from '@material-ui/icons/ShowChart'
import MuiLibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import MuiBookmarksIcon from '@material-ui/icons/Bookmarks'
import MuiUsersIcon from '@material-ui/icons/SupervisorAccount'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import {useMode} from 'app/hooks'

const NavBar = styled('div')({
  display: 'flex'
})

const NavItem = styled(NavLink)(({theme}) => ({
  display: 'flex',
  textDecoration: 'none',
  padding: '10px 20px',
  color: theme.palette.primary.light,
  fontSize: 16,

  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    transitionDuration: theme.transitions.duration.standard
  },

  '&.active': {
    color: theme.palette.primary.dark,
    backgroundColor: alpha(
      theme.palette.primary.light,
      theme.palette.action.hoverOpacity
    ),
    textDecoration: 'underline'
  }
}))

const ResourceLink = styled('p')(({theme}) => ({
  display: 'flex',
  textDecoration: 'none',
  padding: '10px 20px',
  color: theme.palette.primary.light,
  margin: 0,
  fontSize: 16,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    transitionDuration: theme.transitions.duration.standard,
    cursor: 'pointer'
  },

  '&.active': {
    color: theme.palette.primary.dark,
    backgroundColor: alpha(
      theme.palette.primary.light,
      theme.palette.action.hoverOpacity
    ),
    textDecoration: 'underline'
  }
}))

const ShowChartIcon = styled(MuiShowChartIcon)(({theme}) => ({
  marginRight: theme.spacing(1)
}))

const CatalogIcon = styled(MuiLibraryBooksIcon)(({theme}) => ({
  marginRight: theme.spacing(1)
}))

const BookmarksIcon = styled(MuiBookmarksIcon)(({theme}) => ({
  marginRight: theme.spacing(1)
}))

const UsersIcon = styled(MuiUsersIcon)(({theme}) => ({
  marginRight: theme.spacing(1)
}))

const ReportsNav = () => {
  const [mode] = useMode()

  if (mode === 'user') return null

  return (
    <NavItem to='/reports' exact>
      <ShowChartIcon />
      Reports
    </NavItem>
  )
}

const SystemsNav = () => {
  const [mode] = useMode()

  let text = 'Saved Systems'
  switch (mode) {
    case 'admin':
      return null
    case 'vendor':
      text = 'My Systems'
      break

    // no default
  }

  return (
    <NavItem to='/systems' exact>
      <BookmarksIcon />
      {text}
    </NavItem>
  )
}

const CatalogNav = () => {
  return (
    <NavItem to='/catalog' exact>
      <CatalogIcon />
      Learning System Catalog
    </NavItem>
  )
}

const ResourceNav = () => {
  const handleResourceClick = () => {
    window.open('https://views.paperflite.com/collections/62f5165ce9b2933defe2a97d?utm_name=falsite', '_blank')
  }
  return (
    <ResourceLink onClick={handleResourceClick}>
      <MenuBookIcon
        style={{marginRight: '10px', position: 'relative', bottom: '3px'}}
      />
      Learning Library
    </ResourceLink>
  )
}

const UsersNav = () => {
  const [mode] = useMode()

  if (mode !== 'admin') return null

  return (
    <NavItem to='/users' exact>
      <UsersIcon style={{position: 'relative', bottom: '3px'}} />
      Users
    </NavItem>
  )
}

const NavMenu = ({location, ...props}) => {
  return (
    <NavBar {...props}>
      <ReportsNav />
      <SystemsNav />
      <CatalogNav />
      <UsersNav />
      <ResourceNav />
    </NavBar>
  )
}

export default NavMenu
