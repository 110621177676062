import React, {useState} from 'react'
import {styled} from '@material-ui/core/styles'
import {Button as MuiButton} from '@material-ui/core'
import {openRfpDialog} from 'rfps/store/actions'
import {useDispatch, useSelector} from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip'
import history from 'utils/history'
import {getCurrentUser} from 'users/store/selectors'

const Content = styled('div')({
  margin: 0,
  marginBottom: 10,
  display: 'flex',
  flexDirection: 'column'
})

const TextContainer = styled('div')({})

const ButtonContainer = styled('div')({
  marginTop: '.5rem'
})

const ButtonWithoutShadow = styled(MuiButton)({
  boxShadow: 'none'
})

const Description = ({
  description = '',
  systemId,
  isGuestUser,
  isPremium,
  ...props
}) => {
  const [expanded, setExpanded] = useState(false)
  const dispatchRedux = useDispatch()
  const currentUser = useSelector(getCurrentUser)
  const isLong = description.length > 500

  let shortDescription = description.substring(0, 499)

  const text = expanded ? description : shortDescription

  const handleRequestPricing = event => {
    event.preventDefault()
    event.stopPropagation()
    if (!isGuestUser) {
      dispatchRedux(openRfpDialog(systemId))
    } else {
      // go so signup
      history.push('/register')
    }
  }

  let buttonText = 'Request Information'
  let disabled, hasRequestedQuote

  if (
    currentUser &&
    currentUser.rfps &&
    currentUser.rfps.some(r => r.application === systemId)
  ) {
    buttonText = 'Information Requested'
    disabled = true
    hasRequestedQuote = true
  }

  let ellipsis
  if (isLong) {
    ellipsis = <span>&hellip; </span>
  }

  const expandButton = (
    <ButtonWithoutShadow
      color='primary'
      variant='contained'
      style={{marginRight: '1rem'}}
      onClick={() => setExpanded(!expanded)}
    >
      { expanded ? 'Less' : 'Read More' }
    </ButtonWithoutShadow>
  )

  return (
    <Content {...props}>
      <TextContainer>
        {text}
        {ellipsis}
      </TextContainer>
      <ButtonContainer>
        {expandButton}
        {isPremium &&
          <Tooltip
            title='Create a FREE account to access this feature'
            disableHoverListener={!isGuestUser}
          >
            <span>
              <MuiButton
                onClick={handleRequestPricing}
                color='primary'
                variant='outlined'
                disabled={disabled}
                style={{
                  color: isGuestUser
                    ? '#D3D3D3'
                    : hasRequestedQuote
                      ? 'rgb(67, 160, 71)'
                      : '',
                  border: `1px solid ${
                    isGuestUser || hasRequestedQuote ? '#D3D3D3' : ''
                  }`
                }}
              >
                {buttonText}
              </MuiButton>
            </span>
          </Tooltip>
        }
      </ButtonContainer>
    </Content>
  )
}

export default Description
