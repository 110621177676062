import React from 'react'
import {useDispatch} from 'react-redux'
import {styled} from '@material-ui/core/styles'
import MuiFormControlLabel from '@material-ui/core/FormControlLabel'
import MuiRadioGroup from '@material-ui/core/RadioGroup'
import MuiRadio from '@material-ui/core/Radio'
import {useCurrentSearchFilters} from 'searches/hooks'
import {updateCurrentSearchFilters} from 'searches/store/actions'

const Fieldset = styled(({theme, error, ...props}) => <fieldset {...props} />)(({theme, error}) => ({
  margin: 0,
  borderColor: error ? theme.palette.error.main : 'rgba(0, 0, 0, 0.23)',
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: theme.shape.borderRadius,
}))

const Legend = styled(({theme, error, ...props}) => <legend {...props} />)(({theme, error}) => ({
  padding: 5,
  fontSize: 12,
  color: error ? theme.palette.error.main : theme.palette.text.secondary,
}))

const RadioGroup = styled(MuiRadioGroup)({
  padding: '0 7px',
})

const Required = () => <span>&thinsp;*</span>

const ErrorMessage = styled('p')(({theme}) => ({
  color: theme.palette.error.main,
  margin: '8px 12px 0',
  fontSize: 12,
}))

const DeploymentType = ({labelText="Deployment Type", required, error, ...props}) => {
  const {deploymentTypes} = useCurrentSearchFilters()
  const dispatchRedux = useDispatch()

  const defaultValue = (deploymentTypes.length === 1) ? deploymentTypes[0] : null

  const handleChange = (event) => {
    const {value} = event.target

    dispatchRedux(updateCurrentSearchFilters({deploymentTypes: [value]}))
  }

  let errorMessage
  if (error) {
    errorMessage = <ErrorMessage>{error}</ErrorMessage>
  }

  return (
    <>
      <Fieldset error={!!error} {...props}>
        <Legend error={!!error}>
          {labelText}
          {required ? <Required /> : null}
        </Legend>
        <RadioGroup row defaultValue={defaultValue} onChange={handleChange}>
          <MuiFormControlLabel
            value="saas"
            label="SaaS"
            control={<MuiRadio/>}
          />
          <MuiFormControlLabel
            value="self-hosted"
            label="Client Self-Hosted"
            control={<MuiRadio/>}
          />
        </RadioGroup>
      </Fieldset>
      {errorMessage}
    </>
  )
}

export default DeploymentType
