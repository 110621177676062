import React from 'react'
import {styled} from '@material-ui/core'
import {useSelector} from 'react-redux'
import {getEditApplicationState} from 'lms/store/selectors'
import {getMode} from 'app/store/selectors'
import BackButton from 'ui/components/BackButton'
import DeleteButton from './DeleteButton'
import SaveButton from './SaveButton'
import TypeAndPublished from './TypeAndPublished'
import BasicInfo from './BasicInfo'
import ContactInfo from './ContactInfo'
import Description from './Description'
import AudienceTypes from './AudienceTypes'
import CourseStandards from './CourseStandards'
import DeploymentTypes from './DeploymentTypes'
import Learners from './Learners'
import Price from './Price'
import Setup from './Setup'
import Features from './Features'
import SystemData from './SystemData'
// import Videos from './Videos'
// import Whitepapers from './Whitepapers'
// import InstilledPlayer from './InstilledPlayer'
import Playlist from "../../Detail/Playlist"


const Content = styled('form')({})

const Header = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 20
})

const Title = styled('div')({
  fontSize: 24,
  fontWeight: 500
})

const Actions = styled('div')({
  display: 'grid',
  gridAutoFlow: 'column',
  gridColumnGap: 10
})

const Form = () => {
  const {data} = useSelector(getEditApplicationState)
  const mode = useSelector(getMode)

  if (!data) return null

  const title = data.id ? 'Edit Learning System' : 'New Learning System'


  let playlistItems = data.playlistItems ? data.playlistItems : []
  let sorted = playlistItems.slice(0).sort((a,b) => {
    if (a.order < b.order){ return -1 }
    if (a.order > b.order) { return 1 }
    else { return 0 }
  })

  return (
    <Content>
      <Header>
        <Title>{title}</Title>
        <Actions>
          <DeleteButton />
          <BackButton disabled={data.uploadingFiles?.length > 0} />
          <SaveButton disabled={data.uploadingFiles?.length > 0}  />
        </Actions>
      </Header>

      <SystemData />
      <TypeAndPublished />

      <BasicInfo />
      <Description />
      <Playlist isEdit items={sorted} isPremium={data.isPremium}/>
      <ContactInfo />
      {/*<InstilledPlayer mode={mode} />*/}
      <AudienceTypes />
      <CourseStandards />
      <DeploymentTypes />
      <Learners />
      <Price />
      <Setup />
      <Features />
      {/*<Videos />*/}
      {/*<Whitepapers />*/}
    </Content>
  )
}

export default Form
