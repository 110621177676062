import React, {useState} from 'react'
import DeleteIcon from 'ui/components/DeleteIcon'
import {withRouter} from 'react-router-dom'
import ConfirmModal from 'ui/components/Modals/ConfirmModal'
import {useDispatch} from 'react-redux'
import {deleteApplication, fetchApplications} from 'lms/store/actions'
import {fetchUsers} from 'users/store/actions'
import {fetchLeads} from 'leads/store/actions'
import {useCurrentUser} from 'users/hooks'

const DeleteButton = ({history}) => {
  const [isDeleting, setDeletingStatus] = useState(false)
  const currentUser = useCurrentUser()

  const isAdmin = currentUser.role === 'admin'
  const dispatchRedux = useDispatch()

  const handleDelete = () => {
    setDeletingStatus(!isDeleting)
  }

  const handleConfirm = async () => {
    // deleting the application then refetching the appropriate data that has been updated
    dispatchRedux(deleteApplication())
    dispatchRedux(fetchApplications())
    dispatchRedux(fetchLeads())
    dispatchRedux(fetchUsers())
    history.goBack()
  }

  return (
    isAdmin && <>
      <DeleteIcon onClick={handleDelete} />
      <ConfirmModal
        open={isDeleting}
        handleConfirm={handleConfirm}
        handleClose={() => setDeletingStatus(false)}
      />
    </>
  )
}

export default withRouter(DeleteButton)
