import React from 'react'
import { styled } from '@material-ui/core/styles'
import NumberFormat from 'react-number-format'

const Title = styled('span')(({ theme }) => ({}))

const Values = styled('span')(({ theme }) => ({
  fontWeight: 300
}))

const Learners = ({ minLearners, maxLearners }) => {
  const more = maxLearners === 100000 ? '+' : ''

  return (
    <>
      <Title>Number of Users: </Title>
      <Values>
      {minLearners >= 0 &&
        <NumberFormat
          value={minLearners}
          displayType={'text'}
          thousandSeparator={true}
        />
      }
      {minLearners >= 0 && maxLearners && 
        <>
        &nbsp;-&nbsp;
        </>
      }
      {maxLearners &&
        <>        
        <NumberFormat
          value={maxLearners}
          displayType={'text'}
          thousandSeparator={true}
        />
        {more}
        </>
      }
      </Values>
    </>
  )
}

export default Learners
