import React from 'react'
import {
  Layout,
  Body,
  Info,
  Main,
} from 'ui/components/DividedLayout'
import Wizard from './Wizard'

const Onboarding = (props) => {
  return (
    <Layout>
      <Body>
        <Info>
        </Info>
        <Main>
          <Wizard/>
        </Main>
      </Body>
    </Layout>
  )
}

export default Onboarding
