import React from 'react'
import {
  styled,
  Checkbox as MuiCheckbox,
  FormControlLabel as MuiFormControlLabel,
} from '@material-ui/core'
import {useEditApplication} from 'lms/hooks'

const Content = styled('div')({
})

const Heading = styled('h4')(({theme}) => ({
  margin: 0,
  marginTop: 12,
  marginBottom: 6,
  fontSize: 18,
  fontWeight: 500,
  color: theme.palette.text.secondary,
  borderBottom: `1px solid ${theme.palette.divider}`,
}))

const Grid = styled('div')({
  display: 'flex',
})

const FormControlLabel = styled(MuiFormControlLabel)({
})

const DeploymentTypes = (props) => {
  const [application, updateApplication] = useEditApplication()

  const appDeploymentTypes = application.deploymentTypes || []

  const isSelected = name => appDeploymentTypes.includes(name)

  const updateDeploymentTypes = (name, checked) => {
    const set = new Set(appDeploymentTypes)
    checked ? set.add(name) : set.delete(name)
    updateApplication({deploymentTypes: Array.from(set)})
  }

  const createCheck = name => <MuiCheckbox checked={isSelected(name)} onChange={(e, checked) => updateDeploymentTypes(name, checked)}/>

  return (
    <Content {...props}>
      <Heading>Deployment Types</Heading>
      <Grid>
        <FormControlLabel
          label="SaaS"
          control={createCheck('saas')}
        />
        <FormControlLabel
          label="Self-Hosted"
          control={createCheck('self-hosted')}
        />
      </Grid>
    </Content>
  )
}

export default DeploymentTypes
