import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  styled,
  Checkbox as MuiCheckbox,
  FormControlLabel as MuiFormControlLabel,
  TextField as MuiTextField
} from '@material-ui/core'
import { getRfpData } from 'rfps/store/selectors'
import { updateRfpData } from 'rfps/store/actions'
import Section from 'rfps/components/Dialog/Section'

const Group = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center'
}))

const AudienceTypes = props => {
  const { audienceTypes } = useSelector(getRfpData)
  const dispatchRedux = useDispatch()
  const [other, setOther] = useState('')

  const isChecked = key => {
    const set = new Set(audienceTypes)

    if (key === 'Other' && other !== '') {
      return set.has(other)
    }

    return set.has(key)
  }

  const handleCheck = (type, value) => {
    const set = new Set(audienceTypes)

    let key = type
    if (key === 'Other' && other !== '') {
      key = other
    }

    value ? set.add(key) : set.delete(key)

    dispatchRedux(updateRfpData({ audienceTypes: Array.from(set) }))
  }

  const handleText = event => {
    const { value } = event.target
    const set = new Set(audienceTypes)

    const oldValue = other === '' ? 'Other' : other
    const newValue = value === '' ? 'Other' : value

    set.delete(oldValue)
    set.add(newValue)

    setOther(value)
    dispatchRedux(updateRfpData({ audienceTypes: Array.from(set) }))
  }
/*
  const b2bCheck = (
    <MuiCheckbox
      checked={isChecked('B2B')}
      onChange={e => handleCheck('B2B', e.target.checked)}
    />
  )
  */
  // const b2cCheck = <MuiCheckbox checked={isChecked('B2C')} onChange={e => handleCheck('B2C', e.target.checked)}/>
  /*
  const customersCheck = (
    <MuiCheckbox
      checked={isChecked('Customers')}
      onChange={e => handleCheck('Customers', e.target.checked)}
    />
  )
  */
  const customersPartnersCheck = (
    <MuiCheckbox
      checked={isChecked('Customers/Partners')}
      onChange={e => handleCheck('Customers/Partners', e.target.checked)}
    />
  )
  const employeesCheck = (
    <MuiCheckbox
      checked={isChecked('Employees')}
      onChange={e => handleCheck('Employees', e.target.checked)}
    />
  )
  const membersCheck = (
    <MuiCheckbox
      checked={isChecked('Members')}
      onChange={e => handleCheck('Members', e.target.checked)}
    />
  )
  const studentsCheck = (
    <MuiCheckbox
      checked={isChecked('Students')}
      onChange={e => handleCheck('Students', e.target.checked)}
    />
  )
  const otherCheck = (
    <MuiCheckbox
      checked={isChecked('Other')}
      onChange={e => handleCheck('Other', e.target.checked)}
    />
  )

  const error = !audienceTypes || audienceTypes.length === 0

  return (
    <Section title='Who will be using the system?' error={error} {...props}>
      {/* <MuiFormControlLabel label='B2B' control={b2bCheck} /> */}
      {/* <MuiFormControlLabel label="B2C" control={b2cCheck} /> */}
      {/* <MuiFormControlLabel label='Customers' control={customersCheck} /> */}
      <MuiFormControlLabel label='Customers/Partners' control={customersPartnersCheck} />
      <MuiFormControlLabel label='Employees' control={employeesCheck} />
      <MuiFormControlLabel label='Members' control={membersCheck} />
      <MuiFormControlLabel label='Students' control={studentsCheck} />
      <Group>
        <MuiFormControlLabel label='Other:' control={otherCheck} />
        <MuiTextField
          disabled={!isChecked('Other')}
          value={other}
          onChange={handleText}
        />
      </Group>
    </Section>
  )
}

export default AudienceTypes
