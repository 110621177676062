import React from 'react'
import { useDispatch } from 'react-redux'
import { styled } from '@material-ui/core/styles'
import MuiFormControlLabel from '@material-ui/core/FormControlLabel'
import MuiCheckbox from '@material-ui/core/Checkbox'
import { useCourseStandardsState } from 'lms/hooks'
import { useCurrentSearchFilters } from 'searches/hooks'
import { updateCurrentSearchFilters } from 'searches/store/actions'
import { useCookies } from 'react-cookie'
import { useCurrentUser } from 'users/hooks'
import { HIDDEN_FEATURES } from 'constants/hiddenFeatures'
import { THIRTY_DAYS } from 'constants/timeIntervals'

const Fieldset = styled('fieldset')(({ theme }) => ({
  margin: 0,
  borderColor: 'rgba(0, 0, 0, 0.23)',
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: theme.shape.borderRadius
}))

const Legend = styled('legend')(({ theme }) => ({
  padding: 5,
  fontSize: 12,
  color: theme.palette.text.secondary
}))

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 7px'
})

const FormControlLabel = styled(MuiFormControlLabel)({})

const Checkbox = styled(MuiCheckbox)({})

const CourseStandards = ({ labelText = 'Course Standards', ...props }) => {
  const { data } = useCourseStandardsState()
  const { courseStandards } = useCurrentSearchFilters()
  const dispatchRedux = useDispatch()
  const [cookies, setCookie] = useCookies(['guestSearchCount'])
  const user = useCurrentUser()
  const isGuestUser = !user

  const checkCourseStandardVisibility = cs => {
    return !HIDDEN_FEATURES.includes(cs.name)
  }

  const isChecked = id => courseStandards.includes(id)

  const setCheck = (id, checked) => {
    const set = new Set(courseStandards)
    checked ? set.add(id) : set.delete(id)
    if (isGuestUser) {
      const guestSearchCount = cookies.guestSearchCount || 0
      setCookie(
        'guestSearchCount',
        Number(guestSearchCount) + 1,
        { path: '/' },
        THIRTY_DAYS
      )
    }
    dispatchRedux(
      updateCurrentSearchFilters({ courseStandards: Array.from(set) })
    )
  }

  const createCheck = id => (
    <Checkbox
      checked={isChecked(id)}
      onChange={(e, checked) => setCheck(id, checked)}
    />
  )

  return (
    <Fieldset {...props}>
      <Legend>{labelText}</Legend>
      <Row>
        {data.allIds.map(id => {
          const cs = data.byId[id]
          const shouldShowCs = checkCourseStandardVisibility(cs)

          return (
            shouldShowCs && (
              <FormControlLabel
                key={cs.id}
                label={cs.name}
                control={createCheck(cs.id)}
              />
            )
          )
        })}
      </Row>
    </Fieldset>
  )
}

export default CourseStandards
