import React, { useState } from 'react'
import { styled } from '@material-ui/core/styles'
import MuiBeenhereIcon from '@material-ui/icons/Beenhere'
import MuiStarIcon from '@material-ui/icons/Stars'
import Logo from 'lms/components/Logo'
import RankingBadge from 'lms/components/RankingBadge'
import ContactInfo from './ContactInfo'
//import SystemType from 'lms/components/Detail/SystemType'
import AudienceTypes from 'lms/components/Detail/AudienceTypes'
import Learners from 'lms/components/Detail/Learners'
import DeploymentTypes from 'lms/components/Detail/DeploymentTypes'
import Pricing from 'lms/components/Detail/Pricing'
import Timeframe from 'lms/components/Detail/Timeframe'
import RfpButton from 'rfps/components/Button'
import WebsiteLink from './WebsiteLink'
import SaveButton from 'lms/components/SaveButton'
import { viewApplicationContactInfo } from 'lms/store/actions'
import { Button as MuiButton } from '@material-ui/core'
import { useDispatch } from 'react-redux'

const Content = styled('div')({
  display: 'flex'
})

const StyledLogo = styled(Logo)({
  marginRight: 48
})

const Details = styled('div')({
  flexGrow: 1,
  marginTop: 20
})

const Info = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start'
})

const Company = styled('div')({
  marginRight: 24,
  flexGrow: 1
})

const Grid = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'auto auto',
  gridColumnGap: 24
})

const NameAndRank = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 24
})

const Name = styled('h2')(({ theme }) => ({
  color: theme.palette.primary.main,
  margin: 0,
  fontSize: 24,
  marginRight: theme.spacing(1)
}))

const Badges = styled('div')(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  marginRight: theme.spacing(2)
}))

const StyledRanking = styled(RankingBadge)(({theme}) => ({
  marginLeft: theme.spacing(2),
}))
  

const Featured = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.secondary.light,
  marginLeft: theme.spacing(2)
}))

const FeaturedText = styled('div')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500
}))

const FeaturedIcon = styled(MuiBeenhereIcon)(({ theme }) => ({
  fontSize: 18,
  marginRight: '.28em',
  color: theme.palette.primary.dark
}))

const FeaturedIconPlatinum = styled(MuiStarIcon)(({ theme }) => ({
  fontSize: 18,
  marginRight: '.25em',
  color: theme.palette.primary.dark
}))

const CompanyName = styled('h4')(({ theme }) => ({
  margin: 0,
  fontSize: 16,
  fontWeight: 500
}))

const Country = styled('p')({
  margin: 0
})

const StyledRfpButton = styled(RfpButton)(({ theme }) => ({
  marginLeft: -theme.spacing(1)
}))

const Header = ({ lms, ...props }) => {
  const {
    id,
    thumbnail,
    name,
    company,
    country,
    rank,
    audienceTypes,
    minLearners,
    maxLearners,
    deploymentTypes,
    priceFrom,
    priceTo,
    minSetupTime,
    maxSetupTime,
    isPlatinum,
    isPremium
  } = lms

  const [expanded, setExpanded] = useState(false)
  const dispatchRedux = useDispatch()

  const handleClick = () => {
    setExpanded(true)
    dispatchRedux(viewApplicationContactInfo(lms.id))
  }

  let featured, rfpButton
  if (isPlatinum) {
    featured = (
      <Featured>
        <FeaturedIconPlatinum />
        <FeaturedText>Platinum</FeaturedText>
      </Featured>
    )
  } else if (isPremium) {
    featured = (
      <Featured>
        <FeaturedIcon />
        <FeaturedText>Premium</FeaturedText>
      </Featured>
    )
  }

  rfpButton = <StyledRfpButton systemId={id} />

  return (
    <Content {...props}>
      <StyledLogo thumbnail={thumbnail} />
      <Details>
        <NameAndRank>
          <Name>{name}</Name>
          <Badges>
            <StyledRanking rank={rank} size="medium"/>
            {featured}
          </Badges>
          <SaveButton lms={lms} />
        </NameAndRank>
        <Info>
          <Company>
            <CompanyName>{company}</CompanyName>
            <Country>{country}</Country>
            <WebsiteLink lms={lms} />
            <ContactInfo lms={lms} expanded={expanded} />
            <MuiButton
              color='primary'
              variant='contained'
              onClick={handleClick}
              style={{ marginRight: '1.5rem', boxShadow: 'none' }}
              disabled={expanded}
            >
              Contact Us
            </MuiButton>
            {isPremium && rfpButton}
          </Company>
          <Grid>
            {/*<SystemType type={type} />*/}
            <AudienceTypes audienceTypes={audienceTypes} />
            <Learners minLearners={minLearners} maxLearners={maxLearners} />
            <DeploymentTypes deploymentTypes={deploymentTypes} />
            <Pricing priceFrom={priceFrom} priceTo={priceTo} />
            <Timeframe
              minSetupTime={minSetupTime}
              maxSetupTime={maxSetupTime}
            />
          </Grid>
        </Info>
      </Details>
    </Content>
  )
}

export default Header
