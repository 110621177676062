import {useSelector} from 'react-redux'
import {getCompareSystemIds} from 'lms/store/selectors'
import {
  useSystemData,
  useAudienceTypesState,
  useCourseStandardsState,
  useIndustriesState,
  useFeaturesState,
} from 'lms/hooks'

export const useCompareData = () => {
  const systemIds = useSelector(getCompareSystemIds)

  let systems = []
  systems[0] = useSystemData(systemIds[0])
  systems[1] = useSystemData(systemIds[1])
  systems[2] = useSystemData(systemIds[2])
  systems[3] = useSystemData(systemIds[3])

  // filter empty systems
  systems = systems.filter(s => s)

  const {data: audienceTypeData} = useAudienceTypesState()
  const {data: courseStandardsData} = useCourseStandardsState()
  const {data: industriesData} = useIndustriesState()
  const {data: featuresData} = useFeaturesState()

  return {
    systems,
    audienceTypeData,
    courseStandardsData,
    industriesData,
    featuresData,
  }
}
