import React from 'react'
import {
  styled,
  TextField as MuiTextField,
} from '@material-ui/core'
import {useEditApplication} from 'lms/hooks'

const Content = styled('div')(({theme}) => ({
  marginTop: theme.spacing(1),
}))

const Description = (props) => {
  const [application, updateApplication] = useEditApplication()

  return (
    <Content {...props}>
      <MuiTextField
        defaultValue={application.description}
        onChange={(e) => updateApplication({description: e.target.value})}
        label="Description"
        variant="outlined"
        multiline
        maxRows={12}
        fullWidth
      />
    </Content>
  )
}

export default Description
