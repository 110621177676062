import {actionTypes} from "./constants"

export const updateCurrentSearchFilters = (filters, id) => ({
  type: actionTypes.UPDATE_CURRENT_SEARCH_FILTERS_REQUESTED,
  payload: {
    id,
    filters
  },
})

export const updateCurrentSearchResults = () => ({
  type: actionTypes.UPDATE_CURRENT_SEARCH_RESULTS_REQUESTED,
})

export const saveCurrentSearch = (source) => ({
  type: actionTypes.SAVE_CURRENT_SEARCH_REQUESTED,
  payload: {
    source,
  },
})

export const fetchSearches = ({limit=100, skip=0}={}) => ({
  type: actionTypes.FETCH_SEARCHES_REQUESTED,
  payload: {
    limit,
    skip,
  },
})