import React from 'react'
import {
  styled,
  Checkbox as MuiCheckbox,
  FormControlLabel as MuiFormControlLabel
} from '@material-ui/core'
import { useCourseStandardsState, useEditApplication } from 'lms/hooks'
import { HIDDEN_FEATURES } from 'constants/hiddenFeatures'

const Content = styled('div')({})

const Heading = styled('h4')(({ theme }) => ({
  margin: 0,
  marginTop: 12,
  marginBottom: 6,
  fontSize: 18,
  fontWeight: 500,
  color: theme.palette.text.secondary,
  borderBottom: `1px solid ${theme.palette.divider}`
}))

const Grid = styled('div')({
  display: 'flex'
})

const FormControlLabel = styled(MuiFormControlLabel)({})

const nameSorter = (a, b) => a.name.localeCompare(b.name)

const CourseStandards = props => {
  const [application, updateApplication] = useEditApplication()
  const { data } = useCourseStandardsState()

  const appCourseStandards = application.courseStandards || []

  const sortedCourseStandards = data.allIds
    .map(id => data.byId[id])
    .sort(nameSorter)

  const isSelected = id => appCourseStandards.includes(id)
  const checkCourseStandardVisibility = cs => !HIDDEN_FEATURES.includes(cs.name)

  const updateCourseStandards = (id, checked) => {
    const set = new Set(appCourseStandards)
    checked ? set.add(id) : set.delete(id)
    updateApplication({ courseStandards: Array.from(set) })
  }

  const createCheck = id => (
    <MuiCheckbox
      checked={isSelected(id)}
      onChange={(e, checked) => updateCourseStandards(id, checked)}
    />
  )

  return (
    <Content {...props}>
      <Heading>Course Standards</Heading>
      <Grid>
        {sortedCourseStandards.map(courseStandard => {
          const isVisible = checkCourseStandardVisibility(courseStandard)
          return (
            isVisible && (
              <FormControlLabel
                key={courseStandard.id}
                label={courseStandard.name}
                control={createCheck(courseStandard.id)}
              />
            )
          )
        })}
      </Grid>
    </Content>
  )
}

export default CourseStandards
