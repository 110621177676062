import React from 'react'
import { styled } from '@material-ui/core/styles'
import { useFeaturesState } from 'lms/hooks'
import SectionTitle from 'lms/components/Detail/SectionTitle'
import { HIDDEN_FEATURES } from 'constants/hiddenFeatures'
import { Subsection } from '../../Compare/Table/Sections'

const List = styled('ul')(({ theme }) => ({
  fontSize: 14,
  margin: 0,
  marginLeft: theme.spacing(2),
  paddingLeft: theme.spacing(2)
}))

const ListItem = styled('li')(({ theme }) => ({
  whiteSpace: 'pre-line',
  marginBottom: theme.spacing(0.5)
}))

const BooleanValue = styled('span')({
  textAlign: 'left',
  marginLeft: '1rem'
})

const Row = styled('div')({
  margin: '1rem 0'
})

const Features = ({ features = [] }) => {
  const { data } = useFeaturesState()

  if (features.length === 0) return null
  const checkIfVisible = feature =>
				feature && !HIDDEN_FEATURES.includes(feature.name)
				
  const checkIfChildFeaturesSelected = childFeatures =>
    childFeatures.some(
      childFeature =>
        features.includes(childFeature.id) &&
        !HIDDEN_FEATURES.includes(childFeature.name)
    )

  return (
    <>
      <SectionTitle>Features</SectionTitle>
      {data.allIds.map(id => {
        const feature = data.byId[id]
        const isFeatureVisible = checkIfVisible(feature)
        const { children } = feature
        if (isFeatureVisible) {
          let rowContent
          if (children) {
            const areChildrenFeaturesSelected = checkIfChildFeaturesSelected(
              children
            )
            const sortedChildrenFeatures = children
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))
            if (areChildrenFeaturesSelected) {
              rowContent = (
                <List key={id}>
                  {sortedChildrenFeatures.map(childFeature => {
                    const isFeatureSelected = features.includes(childFeature.id)
                    const isFeatureVisible = checkIfVisible(childFeature)
                    const shouldShowFeature =
                      isFeatureSelected && isFeatureVisible
                    return (
                      shouldShowFeature && (
                        <ListItem key={childFeature.name}>
                          {childFeature.name}
                        </ListItem>
                      )
                    )
                  })}
                </List>
              )
            }
          } else {
            rowContent = features.includes(id) ? (
              <BooleanValue>Yes</BooleanValue>
            ) : (
              <BooleanValue>No</BooleanValue>
            )
          }
          return (
            rowContent && (
              <React.Fragment key={id}>
                <Subsection>{feature.name}</Subsection>
                <Row>{rowContent}</Row>
              </React.Fragment>
            )
          )
        } else {
          return null
        }
      })}
    </>
  )
}

export default Features
