import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {getRfpData} from 'rfps/store/selectors'
import {updateRfpData} from 'rfps/store/actions'
import {
  Checkbox as MuiCheckbox,
  FormControlLabel as MuiFormControlLabel
} from '@material-ui/core'

const Status = props => {
  const {hasConsented} = useSelector(getRfpData)
		
  const dispatchRedux = useDispatch()

  const handleChange = e =>
    dispatchRedux(updateRfpData({hasConsented: e.target.checked}))

  const ConsentCheckbox = (
    <MuiCheckbox
      checked={hasConsented || false}
      onChange={handleChange}
      required
    />
  )

  return (
    <MuiFormControlLabel
      label='I agree to receive quotes and related information from FindAnLMS. I understand that I can withdraw my consent at any time'
      control={ConsentCheckbox}
    />
  )
}

export default Status
