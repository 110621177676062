import React from 'react'
import {styled} from '@material-ui/core/styles'
import MuiPaper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import Keywords from 'searches/components/Keywords'
import AudienceTypes from 'searches/components/AudienceTypes'
import Learners from 'searches/components/Learners'
import CourseStandards from 'searches/components/CourseStandards'
import DeploymentTypes from 'searches/components/DeploymentTypes'
import Features from 'searches/components/Features'
import Ranking from 'searches/components/Ranking'
import Budget from 'searches/components/Budget'
import Timeframe from 'searches/components/Timeframe'
import SupportsMigration from 'searches/components/SupportsMigration'
import {useDispatch} from 'react-redux'
import {updateCurrentSearchFilters} from 'searches/store/actions'
import {initialFilters} from 'searches/store/constants'

const Content = styled(MuiPaper)(({theme}) => ({
  maxWidth: 400,
  padding: 10,
}))

const Heading = styled('div')({
  marginBottom: 10,
  display: 'flex',
  justifyContent: 'space-between',
})

const Title = styled('h4')({
  margin: 0,
  fontSize: 20,
  fontWeight: 500,
})

const Filters = (props) => {
  const dispatchRedux = useDispatch()

  const clearFilters = () => dispatchRedux(updateCurrentSearchFilters(initialFilters))

  return (
    <Content {...props}>
      <Heading>
        <Title>Filters</Title>
        <MuiButton size="small" onClick={clearFilters}>Clear</MuiButton>
      </Heading>
      <Keywords/>
      {/*<SystemType/>*/}
      <AudienceTypes/>
      <Learners/>
      <CourseStandards/>
      <DeploymentTypes/>
      <Features/>
      <Ranking/>
      <Budget/>
      <Timeframe/>
      <SupportsMigration/>
    </Content>
  )
}

export default Filters
