import React, { useReducer } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  withStyles,
  styled,
  Button as MuiButton,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  MenuItem as MuiMenuItem,
  OutlinedInput as MuiOutlinedInput,
  Select as MuiSelect,
  TextField as MuiTextField,
  FormControlLabel as MuiFormControlLabel,
  Checkbox as MuiCheckbox
} from '@material-ui/core'
import BackButton from 'ui/components/BackButton'
import DeleteButton from './DeleteButton'
import { reducer, init, updateField } from './state'
import { useLabelWidth } from 'ui/hooks'
import { patchUser, createUser, sendInviteEmail } from 'users/store/actions'
import { useCurrentUser } from 'users/hooks'
import Applications from './Applications'

const Content = styled('form')({})

const Header = styled('div')({
  marginBottom: 20,
  display: 'flex',
  justifyContent: 'space-between'
})

const Title = styled('div')({
  fontSize: 24,
  fontWeight: 500
})

const Body = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 8px 1fr',
  maxWidth: 800,

  // default text fields
  '& > *': {
    gridColumn: '1 / 4'
  },

  [theme.breakpoints.up('md')]: {
    // first name field
    '& > :nth-child(1)': {
      gridColumn: '1'
    },

    // last name field
    '& > :nth-child(2)': {
      gridColumn: '3'
    }
  }
}))

const Footer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 20
})

const Actions = styled('div')({
  display: 'grid',
  gridAutoFlow: 'column',
  gridColumnGap: 10
})

const Label = withStyles(theme => ({
  root: {
    marginLeft: 0
  },
  label: {
    fontSize: 14,
    fontWeight: 500
  }
}))(MuiFormControlLabel)

const renderCheckBox = checked => <MuiCheckbox checked={checked} />

const Form = ({ userData, staticContext, history, ...props }) => {
  const currentUser = useCurrentUser()
  const [state, dispatch] = useReducer(reducer, userData, init)
  const [labelWidth, labelRef] = useLabelWidth('Role')
  const dispatchRedux = useDispatch()

  const { user } = state

  const title = user.id ? 'Edit User' : 'New User'
  const buttonTitle = user.id ? 'Update User' : 'Save User'

  const roleInput = (
    <MuiOutlinedInput id='role' name='role' labelWidth={labelWidth} />
  )

  const handleChange = (name, value) => dispatch(updateField(name, value))

  const handleSave = () => {
    user.id
      ? dispatchRedux(patchUser(user))
      : dispatchRedux(createUser({ ...user, sendInviteEmail: true }))
    history.push('/users')
  }

  const resendInvite = () => dispatchRedux(sendInviteEmail(user.id))

  const disabled = user.id === currentUser.id

  let resendInviteButton
  if (user.id && !user.lastSignedIn) {
    resendInviteButton = (
      <MuiButton color='primary' onClick={resendInvite}>
        Resend Invite Email
      </MuiButton>
    )
  }

  const hasLeadNotificationSettings =
    user.settings && user.settings.leadNotifications
  const isAdminOrVendor = user.role === 'vendor' || user.role === 'admin'
  const isCurrentUserAdmin = currentUser.role === 'admin'

  return (
    <Content {...props}>
      <Header>
        <Title>{title}</Title>
        {resendInviteButton}
      </Header>
      <Body>
        <MuiTextField
          label='First Name'
          defaultValue={user.firstName}
          onChange={e => handleChange('firstName', e.target.value)}
          variant='outlined'
          margin='dense'
        />
        <MuiTextField
          label='Last Name'
          defaultValue={user.lastName}
          onChange={e => handleChange('lastName', e.target.value)}
          variant='outlined'
          margin='dense'
        />
        <MuiTextField
          label='Company Name'
          defaultValue={user.companyName}
          onChange={e => handleChange('companyName', e.target.value)}
          variant='outlined'
          margin='dense'
        />
        <MuiTextField
          label='Job Title'
          defaultValue={user.jobTitle}
          onChange={e => handleChange('jobTitle', e.target.value)}
          variant='outlined'
          margin='dense'
        />
        <MuiTextField
          label='Email'
          defaultValue={user.email}
          onChange={e => handleChange('email', e.target.value)}
          variant='outlined'
          margin='dense'
        />
        <MuiTextField
          label='Phone'
          defaultValue={user.phone}
          onChange={e => handleChange('phone', e.target.value)}
          variant='outlined'
          margin='dense'
        />
        <MuiFormControl variant='outlined' margin='dense' disabled={disabled}>
          <MuiInputLabel ref={labelRef} htmlFor='role'>
            Role
          </MuiInputLabel>
          <MuiSelect
            name='role'
            value={user.role}
            onChange={e => handleChange('role', e.target.value)}
            input={roleInput}
          >
            <MuiMenuItem value='admin'>Admin</MuiMenuItem>
            <MuiMenuItem value='vendor'>Vendor</MuiMenuItem>
            <MuiMenuItem value='user'>User</MuiMenuItem>
          </MuiSelect>
        </MuiFormControl>
        <Applications ids={user.applications} role={user.role} />
        {isCurrentUserAdmin && isAdminOrVendor && (
          <Label
            label={
              <p>
                Allow user to be notified when a lead is generated
                <span style={{ color: '#808080' }}>
                  {' '}
                  (Only applies to Premium Systems)
                </span>
              </p>
            }
            control={renderCheckBox(hasLeadNotificationSettings)}
            onClick={() =>
              handleChange('settings', {
                leadNotifications: !hasLeadNotificationSettings
              })
            }
            disabled={false}
            {...props}
          />
        )}
      </Body>
      <Footer>
        <Actions>
          <DeleteButton id={user.id} disabled={disabled} />
        </Actions>
        <Actions>
          <BackButton />
          <MuiButton color='primary' variant='contained' onClick={handleSave}>
            {buttonTitle}
          </MuiButton>
        </Actions>
      </Footer>
    </Content>
  )
}

export default withRouter(Form)
