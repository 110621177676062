import React from 'react'
import {
  styled,
} from '@material-ui/core'
//import SystemType from './SystemType'
import PublishedCheck from './PublishedCheck'

const Content = styled('div')(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  marginBottom: theme.spacing(1),
}))

const TypeAndPublished = (props) => {
  return (
    <Content {...props}>
      {/*<SystemType/>*/}
      <PublishedCheck/>
    </Content>
  )
}

export default TypeAndPublished
