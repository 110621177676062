import {actionTypes} from "./constants"

export const openRfpDialog = (systemId) => ({
  type: actionTypes.OPEN_RFP_DIALOG,
  payload: {
    systemId,
  },
})

export const closeRfpDialog = () => ({
  type: actionTypes.CLOSE_RFP_DIALOG,
})

export const updateRfpData = (data) => ({
  type: actionTypes.UPDATE_RFP_DATA,
  payload: data,
})

export const createRfp = () => ({
  type: actionTypes.CREATE_RFP_REQUESTED,
})
