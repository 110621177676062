import React from 'react'
import {withRouter} from 'react-router-dom'
import {
  styled,
  Button as MuiButton,
} from '@material-ui/core'

const Button = styled(MuiButton)(({theme}) => ({
  color: theme.palette.primary.contrastText,
}))

const CreateAppButton = ({history, ...props}) => {
  const handleClick = () => {
    history.push("/systems/new")
  }

  return (
    <Button onClick={handleClick}>
      Create Learning System
    </Button>
  )
}

export default withRouter(CreateAppButton)
