import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  styled,
  Button as MuiButton,
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions
} from '@material-ui/core'

import { getShowDialog, getShowErrors } from 'rfps/store/selectors'
import { closeRfpDialog, createRfp } from 'rfps/store/actions'
import ContactCard from './ContactCard'
//import OrganizationType from './OrganizationType'
import AudienceTypes from './AudienceTypes'
import Status from './Status'
import Timeframe from './Timeframe'
import DeploymentType from './DeploymentType'
import CurrentSystem from './CurrentSystem'
import NumOfUsers from './NumOfUsers'
import Migration from './Migration'
import ConsentCheckbox from './ConsentCheckbox'

const Title = styled('h2')({
  margin: 0
})

const Error = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  color: theme.palette.error.main,
  fontSize: 16,
  fontWeight: 500
}))

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2)
}))

const RfpsForm = styled('form')({})

const RfpDialog = props => {
  const showDialog = useSelector(getShowDialog)
  const showErrors = useSelector(getShowErrors)
  const dispatchRedux = useDispatch()

  const handleClose = () => dispatchRedux(closeRfpDialog())
  const handleSubmit = e => {
    e.preventDefault()
    dispatchRedux(createRfp())
  }

  let errorMessage
  if (showErrors) {
    errorMessage = (
      <Error>Please complete all the fields highlighted below:</Error>
    )
  }

  return (
    <MuiDialog open={showDialog} onClose={handleClose} {...props}>
      <MuiDialogTitle disableTypography>
        <Title>Request Information</Title>
        {errorMessage}
      </MuiDialogTitle>
      <RfpsForm onSubmit={handleSubmit}>
        <MuiDialogContent>
          <ContactCard />
          {/*<OrganizationType />*/}
          <AudienceTypes />
          <Status />
          <Timeframe />
          <DeploymentType />
          <CurrentSystem />
          <Migration />
          <NumOfUsers />
          <ConsentCheckbox />
        </MuiDialogContent>
        <DialogActions>
          <MuiButton color='primary' onClick={handleClose}>
            Cancel
          </MuiButton>
          <MuiButton variant='contained' color='primary' type='submit'>
            Submit Request
          </MuiButton>
        </DialogActions>
      </RfpsForm>
    </MuiDialog>
  )
}

export default RfpDialog
