import * as yup from 'yup'

export const numLearnersSchema = yup.object().shape({
  min: yup.number().nullable().required("You must select how many learners will be using the system"),
  max: yup.number().nullable().notRequired(),
})

export const audienceTypesSchema = yup.array()
  .of(yup.string())
  .min(1, "You must select at least one type of user")
  .required("You must select at least one type of user")

export const deploymentTypesSchema = yup.array()
  .of(yup.string())
  .min(1, "You must select a deployment type")

export const featuresSchema = yup.array()
  .of(yup.string())
  .min(1, "You must select one or more features")

export const onboardingSchema = [
  yup.object().shape({
    numLearners: numLearnersSchema,
    audienceTypes: audienceTypesSchema,
  }),
  yup.object().shape({
    deploymentTypes: deploymentTypesSchema,
    features: featuresSchema,
  }),
  yup.object(),
]
