import React from 'react'
import {
  styled,
} from '@material-ui/core'
import Users from './Users'
import Systems from './Systems'
import Searches from './Searches'
import Leads from './Leads'
import ReportDialog from 'reports/components/Generate/Dialog'
import Playlist from "./Playlist";

const Content = styled('div')(({theme}) => ({
  marginTop: theme.spacing(4),
}))

const AdminDashboard = (props) => {
  return (
    <Content>
      <Leads/>
      <Searches/>
      <Playlist />
      <Users/>
      <Systems/>
      <ReportDialog/>
    </Content>
  )
}

export default AdminDashboard
