import React from 'react'
import {styled} from '@material-ui/core'
import {useFeaturesState, useEditApplication} from 'lms/hooks'
import CheckBoxTree from './CheckBoxTree.js'

const Content = styled('div')({})

const Heading = styled('div')(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  margin: 0,
  marginTop: 24,
  marginBottom: 6,
  borderBottom: `1px solid ${theme.palette.divider}`
}))

const Title = styled('h4')(({theme}) => ({
  margin: 0,
  fontSize: 18,
  fontWeight: 500,
  color: theme.palette.text.secondary
}))

const Grid = styled('div')({
  display: 'flex',
  flexWrap: 'wrap'
})

const nameSorter = (a, b) => a.name.localeCompare(b.name)

const Features = props => {
  const [application, updateApplication] = useEditApplication()

  const {data} = useFeaturesState()

  const appFeatures = application.features || []


  const removeFeature = id => {
    const set = new Set(appFeatures)
    set.delete(id)
    updateApplication({features: Array.from(set)})
  }

  const selectedFeatures = appFeatures.map(id => data.byId[id]).sort(nameSorter)

  return (
    <Content {...props}>
      <Heading>
        <Title>Features</Title>
      </Heading>
      <Grid>
        <CheckBoxTree
          selectedFeatures={selectedFeatures}
          removeFeature={removeFeature}
        />
      </Grid>
    </Content>
  )
}

export default Features
