import React from 'react'
import {styled} from '@material-ui/core/styles'
import MuiDialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiIconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Detail from 'lms/components/Detail'

const CloseButton = styled(MuiIconButton)(({theme}) => ({
  position: 'absolute',
  top: theme.spacing(0.5),
  right: theme.spacing(0.5),
  zIndex: 1000,
}))

const DetailDialog = ({lms, onClose, ...props}) => {
  return (
    <MuiDialog
      maxWidth="lg"
      fullWidth
      scroll="body"
      {...props}
    >
      <CloseButton onClick={onClose}>
        <CloseIcon/>
      </CloseButton>
      <MuiDialogContent>
        <Detail lms={lms}/>
      </MuiDialogContent>
    </MuiDialog>
  )
}

export default DetailDialog
