import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import { Link } from 'react-router-dom'
import { styled } from '@material-ui/core/styles'
import { Button as MuiButton } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '33rem',
    '&:focus': {
      outlineColor: '#fff'
    }
  }
}))

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  margin: '1.5rem'
}))

const Text = styled('p')(({ theme }) => ({
  margin: 0
}))

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.secondary.main,
  marginLeft: '0.28em'
}))

export default function ConfirmModal ({ open }) {
  const classes = useStyles()

  return (
    <Modal className={classes.modal} open={open}>
      <div className={classes.paper}>
        <div style={{ width: '100%' }}>
          <h2 style={{ marginBottom: 0 }}>Keep the search going.</h2>
          <h2 style={{ marginBottom: '1rem', marginTop: 0 }}>
            Sign up for unlimited access for free!
          </h2>
        </div>
        <p style={{ marginTop: 0 }}>
								You've completed your member preview, but when you sign up for a FREE FindanLMS account, you will receive FREE benefits such as saving your favorites, request a quote, unlimited comparisons, and unlimited access.
        </p>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-around'
          }}
        >
          <MuiButton
            variant='contained'
            component={Link}
            to='/register'
            color='primary'
          >
            Sign up
          </MuiButton>
        </div>
        <Content>
          <Text>Already have an account?</Text>
          <StyledLink to='/login'>Sign in</StyledLink>
        </Content>
      </div>
    </Modal>
  )
}
