import React from 'react'
import {
  styled,
  InputAdornment as MuiInputAdornment,
  TextField as MuiTextField,
} from '@material-ui/core'
import {useEditApplication} from 'lms/hooks'

const Content = styled('div')({
})

const Heading = styled('h4')(({theme}) => ({
  margin: 0,
  marginTop: 12,
  marginBottom: 6,
  fontSize: 18,
  fontWeight: 500,
  color: theme.palette.text.secondary,
  borderBottom: `1px solid ${theme.palette.divider}`,
}))

const Grid = styled('div')({
  display: 'grid',
  gridColumnGap: 10,
  gridTemplateColumns: '120px 120px auto',
  alignItems: 'center',
})

const Note = styled('div')(({theme}) => ({
  fontSize: 14,
  color: theme.palette.grey[500],
}))

const Price = (props) => {
  const [application, updateApplication] = useEditApplication()

  const handleMinChange = event => {
    const result = event.target.value.replace(/\D/g, '')

    updateApplication({priceFrom: result})
  }

  const handleMaxChange = event => {
    const result = event.target.value.replace(/\D/g, '')

    updateApplication({priceTo: result})
  }

  return (
    <Content {...props}>
      <Heading>Pricing</Heading>
      <Grid>
        <MuiTextField
          defaultValue={application.priceFrom}
          label="Min"
          variant="outlined"
          margin="dense"
          InputProps={{
            startAdornment: <MuiInputAdornment position="start">$</MuiInputAdornment>
          }}
          onChange={handleMinChange}
          //onChange={(e) => updateApplication({priceFrom: e.target.value})}
        />
        <MuiTextField
          defaultValue={application.priceTo}
          label="Max"
          variant="outlined"
          margin="dense"
          InputProps={{
            startAdornment: <MuiInputAdornment position="start">$</MuiInputAdornment>
          }}
          onChange={handleMaxChange}
          //onChange={(e) => updateApplication({priceTo: e.target.value})}
        />
        <Note>Note: A max price equal to or greater than $1,000,000 represents no maximum budget.</Note>
      </Grid>
    </Content>
  )
}

export default Price
