import React from 'react'
import {styled} from '@material-ui/core/styles'

const Title = styled('span')(({theme}) => ({
}))

const Values = styled('span')(({theme}) => ({
  fontWeight: 300,
}))

const DeploymentTypes = ({deploymentTypes}) => {
  const names = deploymentTypes.map((type) => {
    switch (type) {
      case 'saas':
        return 'SaaS'

      case 'self-hosted':
        return 'Self-Hosted'

      default:
        return type
    }
  })

  return (
    <>
      <Title>Deployment Types: </Title>
      <Values>{names.join(", ")}</Values>
    </>
  )
}

export default DeploymentTypes
