import React from 'react'
import { useDispatch } from 'react-redux'
import { styled } from '@material-ui/core/styles'
import MuiMenuItem from '@material-ui/core/MenuItem'
import MuiOutlinedInput from '@material-ui/core/OutlinedInput'
import MuiFormControl from '@material-ui/core/FormControl'
import MuiInputLabel from '@material-ui/core/InputLabel'
import MuiSelect from '@material-ui/core/Select'
import MuiChip from '@material-ui/core/Chip'
import { useLabelWidth } from 'ui/hooks'
import { useFeaturesState } from 'lms/hooks'
import { useCurrentSearchFilters } from 'searches/hooks'
import { updateCurrentSearchFilters } from 'searches/store/actions'
import { useCookies } from 'react-cookie'
import { useCurrentUser } from 'users/hooks'
import { HIDDEN_FEATURES } from 'constants/hiddenFeatures'
import { THIRTY_DAYS } from 'constants/timeIntervals'

const Fieldset = styled(({ theme, error, ...props }) => (
  <fieldset {...props} />
))(({ theme, error }) => ({
  margin: 0,
  borderColor: error ? theme.palette.error.main : 'rgba(0, 0, 0, 0.23)',
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: theme.shape.borderRadius
}))

const Legend = styled(({ theme, error, ...props }) => <legend {...props} />)(
  ({ theme, error }) => ({
    padding: 5,
    fontSize: 12,
    color: error ? theme.palette.error.main : theme.palette.text.secondary
  })
)

const FormControl = styled(MuiFormControl)({
  margin: 0
})

const SelectedFeatures = styled('div')({
  marginTop: 5
})

const Chip = styled(MuiChip)({
  marginTop: 5,
  marginBottom: 5,
  marginRight: 5
})

const Feature = styled(MuiMenuItem)({})

const ParentFeature = styled(MuiMenuItem)({
  fontStyle: 'italic'
})

const ChildFeature = styled(MuiMenuItem)({
  paddingLeft: 40
})

const ellipsis = '\u2026'
const truncate = name => {
  if (name.length > 45) {
    return name.substring(0, 44) + ellipsis
  }

  return name
}

const Required = () => <span>&thinsp;*</span>

const ErrorMessage = styled('p')(({ theme }) => ({
  color: theme.palette.error.main,
  margin: '8px 12px 0',
  fontSize: 12
}))

const Features = ({ labelText = 'Features', required, error, ...props }) => {
  const { status, data } = useFeaturesState()
  const { features } = useCurrentSearchFilters()
  const dispatchRedux = useDispatch()
  const [labelWidth, labelRef] = useLabelWidth(labelText)
  const [cookies, setCookie] = useCookies(['guestSearchCount'])
  const user = useCurrentUser()
  const isGuestUser = !user

  if (status !== 'succeeded') return null

  // TODO: store sorted IDs in Redux
  const nameSorter = (a, b) =>
    data.byId[a].name.localeCompare(data.byId[b].name)
  const sortedIds = data.allIds.slice().sort(nameSorter)

  const checkIfVisible = feature =>
    feature && !HIDDEN_FEATURES.includes(feature.name)

  const items = []
  sortedIds.forEach(id => {
    const feature = data.byId[id]
    const children = feature.children

    //Determine if there's at least one visible child
    let aChildIsVisible = false
    if(children) {
      children.forEach(childFeature => {
        const isChildFeatureVisible = checkIfVisible(childFeature)
        if(isChildFeatureVisible) {
          aChildIsVisible = true
        }
      })
    }

    if (children && aChildIsVisible) {
      items.push(
        <ParentFeature key={feature.id} value={feature.id} disabled>
          {feature.name}
        </ParentFeature>
      )
      const sortedChildrenFeatures = children
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name))
								sortedChildrenFeatures.forEach(child => {
        const disabled = features.includes(child.id)
        const isChildVisible = checkIfVisible(child)

        if (isChildVisible && child.name !== 'Other') {
          items.push(
            <ChildFeature key={child.id} value={child.id} disabled={disabled}>
              {child.name}
            </ChildFeature>
          )
        }
      })

      children.filter(children => children.name === 'Other').map(filteredChild => (
        items.push(
          <ChildFeature key={filteredChild.id} value={filteredChild.id} disabled={features.includes(filteredChild.id)}>
            {filteredChild.name}
          </ChildFeature>
        )
      ))


    } else {
      const disabled = features.includes(feature.id)
      const isVisible = checkIfVisible(feature)
      if (isVisible) {
        items.push(
          <Feature key={feature.id} value={feature.id} disabled={disabled}>
            {feature.name}
          </Feature>
        )
      }
    }
  })

  const addFeature = event => {
    const set = new Set(features)
    set.add(event.target.value)
    if (isGuestUser) {
      const guestSearchCount = cookies.guestSearchCount || 0
      setCookie(
        'guestSearchCount',
        Number(guestSearchCount) + 1,
        { path: '/' },
        THIRTY_DAYS
      )
    }
    dispatchRedux(updateCurrentSearchFilters({ features: Array.from(set) }))
  }

  const removeFeature = id => {
    const set = new Set(features)
    set.delete(id)
    dispatchRedux(updateCurrentSearchFilters({ features: Array.from(set) }))
  }

  const input = (
    <MuiOutlinedInput id='features' name='features' labelWidth={labelWidth} />
  )

  let errorMessage
  if (error) {
    errorMessage = <ErrorMessage>{error}</ErrorMessage>
  }

  return (
    <>
      <Fieldset error={!!error} {...props}>
        <Legend error={!!error}>
          {labelText}
          {required ? <Required /> : null}
        </Legend>
        <FormControl variant='outlined' margin='dense' fullWidth>
          <MuiInputLabel ref={labelRef} htmlFor='features'>
            Choose...
          </MuiInputLabel>
          <MuiSelect
            name='features'
            value=''
            input={input}
            onChange={addFeature}
          >
            {items}
          </MuiSelect>
        </FormControl>
        <SelectedFeatures>
          {features.map(id => {
            const feature = data.byId[id]
            const label = truncate(feature.name)
            const handleDelete = () => removeFeature(id)
            const isVisible = checkIfVisible(feature)

            return (
              isVisible && (
                <Chip
                  key={id}
                  label={label}
                  color='secondary'
                  variant='outlined'
                  onDelete={handleDelete}
                />
              )
            )
          })}
        </SelectedFeatures>
      </Fieldset>
      {errorMessage}
    </>
  )
}

export default Features
