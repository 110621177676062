import produce from 'immer'
import {actionTypes} from './constants'

const initialState = {
  showDialog: false,
  showErrors: false,
  rfpData: {
    systemId: null,
    contact: {
      fullName: null,
      email: null,
      phone: null,
      companyName: null,
      jobTitle: null,
      city: null,
      state: null,
      country: null,
    },
    //organizationType: null,
    audienceTypes: null,
    currentStatus: null,
    timeframe: null,
    deploymentType: null,
    currentSystem: null,
    currentVendor: null,
    migration: null,
				numberOfUsers: null,
				hasConsented: true
  },
}

const reducer = produce((draft, action) => {
  switch (action.type) {
    case actionTypes.OPEN_RFP_DIALOG:
      draft.showDialog = true
      draft.rfpData.systemId = action.payload.systemId
      break

    case actionTypes.CLOSE_RFP_DIALOG:
      draft.showDialog = false
      draft.showErrors = false
      draft.systemId = null
      break

    case actionTypes.UPDATE_RFP_DATA:
      Object.assign(draft.rfpData, action.payload)
      break

    case actionTypes.CREATE_RFP_SUCCEEDED:
      break

    case actionTypes.CREATE_RFP_FAILED:
      draft.showErrors = true
      break

    // no default
  }
}, initialState)

export default reducer
