import mirrorCreator from 'mirror-creator'

export const businessSegmentList = [
  {
    value: 'association',
    display: 'Association'
  },
  {
    value: 'customerEducationTraining',
    display: 'Customer Education/Training'
  },
  {
    value: 'enterprise',
    display: 'Enterprise'
  },
  {
    value: 'extendedEnterprise',
    display: 'Extended Enterprise'
  },
  {
    value: 'government',
    display: 'Government'
  },
  {
    value: 'higherEducation',
    display: 'Higher Education'
  },
  {
    value: 'largeEnterprise',
    display: 'Large Enterprise'
  },
  {
    value: 'midMarket',
    display: 'Mid-Market'
  },
  {
    value: 'nonProfit',
    display: 'Non-profit'
  },
  {
    value: 'smallBusiness',
    display: 'Small Business'
  }
]

export const actionTypes = mirrorCreator(
  [
    'OPEN_RFP_DIALOG',
    'CLOSE_RFP_DIALOG',

    'UPDATE_RFP_DATA',

    'CREATE_RFP_REQUESTED',
    'CREATE_RFP_SUCCEEDED',
    'CREATE_RFP_FAILED'
  ],
  { prefix: 'rfps/' }
)
