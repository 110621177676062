import React from 'react'
//import SystemType from 'searches/components/SystemType'
import CourseStandards from 'searches/components/CourseStandards'
import DeploymentType from './DeploymentType'
import Features from 'searches/components/Features'

const FeaturesPanel = ({errors={}, ...props}) => {
  return (
    <>
      {/*<SystemType/>*/}
      <CourseStandards labelText="Select Course Standards"/>
      <DeploymentType required error={errors['deploymentTypes']}/>
      <Features error={errors['features']}/>
    </>
  )
}

export default FeaturesPanel
