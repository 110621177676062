import React from 'react'
import { styled } from '@material-ui/core'
import { useMode } from 'app/hooks'
import Ranking from './Ranking'
import Platinum from './Platinum'
import Premium from './Premium'
import Admins from './Admins'

const Content = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  paddingBottom: 20,
  marginBottom: 20
}))

const Body = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridColumnGap: 20,

  // default fields
  '& > *': {
    gridColumn: '1 / 3'
  },

  [theme.breakpoints.up('md')]: {
    // ranking select
    '& > :nth-child(1)': {
      // gridColumn: '1',
    },

    // premium checkbox
    '& > :nth-child(2)': {
      // gridColumn: '2',
      // justifySelf: 'start',
    }
  }
}))

const Grid = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
})

const SystemData = props => {
  const [mode] = useMode()

  if (mode !== 'admin') return null

  return (
    <Content {...props}>
      <Body>        
        <Grid>          
          <Premium />
          <Platinum />
        </Grid>
        <Ranking/>
        <Admins />
      </Body>
    </Content>
  )
}

export default SystemData
