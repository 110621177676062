import React from 'react'
import {useDispatch} from 'react-redux'
import {styled} from '@material-ui/core/styles'
import MuiFormControlLabel from '@material-ui/core/FormControlLabel'
import MuiRadioGroup from '@material-ui/core/RadioGroup'
import MuiRadio from '@material-ui/core/Radio'
import {useCurrentSearchFilters} from 'searches/hooks'
import {updateCurrentSearchFilters} from 'searches/store/actions'
import {useCookies} from 'react-cookie'
import {useCurrentUser} from 'users/hooks'
import { THIRTY_DAYS } from 'constants/timeIntervals'

const Fieldset = styled('fieldset')(({theme}) => ({
  margin: 0,
  borderColor: 'rgba(0, 0, 0, 0.23)',
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: theme.shape.borderRadius
}))

const Legend = styled('legend')(({theme}) => ({
  padding: 5,
  fontSize: 12,
  color: theme.palette.text.secondary
}))

const RadioGroup = styled(MuiRadioGroup)({
  padding: '0 7px'
})

const Timeframe = props => {
  const {setupTime} = useCurrentSearchFilters()
  const dispatchRedux = useDispatch()
  const [cookies, setCookie] = useCookies(['guestSearchCount'])
  const user = useCurrentUser()
  const isGuestUser = !user
  const value = `${setupTime.min}`

  const handleChange = event => {
    const {value} = event.target

    const setupTime = {
      min: null,
      max: null
    }

    switch (value) {
      case '0':
        setupTime.min = 0
        setupTime.max = 3
        break

      case '3':
        setupTime.min = 3
        setupTime.max = 6
        break

      case '6':
        setupTime.min = 6
        setupTime.max = null
        break

      // no default
    }
    if (isGuestUser) {
      const guestSearchCount = cookies.guestSearchCount || 0
      setCookie(
        'guestSearchCount',
        Number(guestSearchCount) + 1,
        {path: '/'},
        THIRTY_DAYS
      )
    }
    dispatchRedux(updateCurrentSearchFilters({setupTime}))
  }

  return (
    <Fieldset {...props}>
      <Legend>Implementation Timeframe</Legend>
      <RadioGroup value={value} onChange={handleChange}>
        <MuiFormControlLabel
          value='0'
          label='0-3 months'
          labelPlacement='end'
          control={<MuiRadio />}
        />
        <MuiFormControlLabel
          value='3'
          label='3-6 months'
          labelPlacement='end'
          control={<MuiRadio />}
        />
        <MuiFormControlLabel
          value='6'
          label='6+ months'
          labelPlacement='end'
          control={<MuiRadio />}
        />
      </RadioGroup>
    </Fieldset>
  )
}

export default Timeframe
