import React from 'react'
import {styled} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import PrimaryIndustry from './PrimaryIndustry'
import Learners from 'searches/components/Learners'
import AudienceTypes from 'searches/components/AudienceTypes'
import SupportsMigration from 'searches/components/SupportsMigration'

const Content = styled('div')({
})

const Form = styled('form')({
})

const useSizedLeanersLabelText = () => {
  const md = useMediaQuery(theme => theme.breakpoints.down('md'))
  const xs = useMediaQuery(theme => theme.breakpoints.down('xs'))

  let labelText = "How many learners do you anticipate in the first year?"

  // adjust the label content based on screen size
  if (md) labelText = "How many learners do you anticipate?"
  if (xs) labelText = "How many learners?"

  return labelText
}

const Organization = ({errors={}, ...props}) => {
  const learnersLabelText = useSizedLeanersLabelText()

  return (
    <Content {...props}>
      <Form>
        <PrimaryIndustry labelText="What is your primary industry?"/>
        <Learners labelText={learnersLabelText} required error={errors['numLearners']}/>
        <AudienceTypes labelText="Who will be using the system?" required error={errors['audienceTypes']}/>
        <SupportsMigration labelText="Do you need to migrate from another Learning System?"/>
      </Form>
    </Content>
  )
}

export default Organization
