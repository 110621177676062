import React from 'react'
import { useDispatch } from 'react-redux'
import { styled } from '@material-ui/core/styles'
import MuiFormControlLabel from '@material-ui/core/FormControlLabel'
import MuiRadioGroup from '@material-ui/core/RadioGroup'
import MuiRadio from '@material-ui/core/Radio'
import { useCurrentSearchFilters } from 'searches/hooks'
import { updateCurrentSearchFilters } from 'searches/store/actions'
import { useCookies } from 'react-cookie'
import { THIRTY_DAYS } from 'constants/timeIntervals'
import { useCurrentUser } from 'users/hooks'
//import { withStyles, Button as MuiButton } from '@material-ui/core'
//import StarBorderIcon from '@material-ui/icons/StarBorder'
//import Rating from '@material-ui/lab/Rating'

//const ClearButton = styled(MuiButton)(({ theme }) => ({}))

const Fieldset = styled('fieldset')(({ theme }) => ({
  margin: 0,
  borderColor: 'rgba(0, 0, 0, 0.23)',
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: theme.shape.borderRadius
}))
/*
const RatingContainer = styled('div')({
  display: 'flex',
  alignItems: 'center'
})

const StyledRating = withStyles(theme => ({
  iconFilled: {
    color: theme.palette.primary.main
  },
  iconHover: {
    color: theme.palette.primary.main
  }
}))(Rating)

const RatingContentWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
})

const Text = styled('p')({})
*/
const Legend = styled('legend')(({ theme }) => ({
  padding: 5,
  fontSize: 12,
  color: theme.palette.text.secondary
}))

const Ranking = props => {
  const { ranking } = useCurrentSearchFilters()
  //const { craigsRating } = useCurrentSearchFilters()
  const dispatchRedux = useDispatch()
  const [cookies, setCookie] = useCookies(['guestSearchCount'])
  const user = useCurrentUser()
  const isGuestUser = !user
  const value = ranking ? `${ranking}` : 'all'

  const handleChange = event => {
    const {value} = event.target

    const ranking = value === 'all' ? null : Number(value)
    if (isGuestUser) {
      const guestSearchCount = cookies.guestSearchCount || 0
      setCookie(
        'guestSearchCount',
        Number(guestSearchCount) + 1,
        {path: '/'},
        THIRTY_DAYS
      )
    }
    dispatchRedux(updateCurrentSearchFilters({ranking}))
  }
  

  /*const handleChange = ({ ranking }) => {
    if (ranking) {
      if (isGuestUser) {
        const guestSearchCount = cookies.guestSearchCount || 0
        setCookie(
          'guestSearchCount',
          Number(guestSearchCount) + 1,
          { path: '/' },
          THIRTY_DAYS
        )
      }
      dispatchRedux(updateCurrentSearchFilters({ craigsRating: ranking }))
    }
  }*/

  /*const clearRatingsFilter = () => {
    dispatchRedux(updateCurrentSearchFilters({ craigsRating: null }))
		}
		
		const roundedRating = Math.floor(craigsRating * 2) / 2
*/
  return (
    <Fieldset {...props}>      
      <Legend>Craig's Top Learning Systems</Legend>
      <MuiRadioGroup row value={value} onChange={handleChange}>
        <MuiFormControlLabel
          value='all'
          label='All'
          labelPlacement='top'
          control={<MuiRadio />}
        />
        <MuiFormControlLabel
          value='50'
          label='Top 50'
          labelPlacement='top'
          control={<MuiRadio />}
        />
        <MuiFormControlLabel
          value='20'
          label='Top 20'
          labelPlacement='top'
          control={<MuiRadio />}
        />
        <MuiFormControlLabel
          value='10'
          label='Top 10'
          labelPlacement='top'
          control={<MuiRadio />}
        />
      </MuiRadioGroup>

      {/*
      <Legend>Craig's Overall Rating</Legend>
      <RatingContentWrapper>
        <RatingContainer>
          <StyledRating
            name='rating-search'
												value={roundedRating}
												precision={0.5}
            emptyIcon={<StarBorderIcon fontSize='inherit' />}
            onChange={(e, ranking) => handleChange({ ranking })}
          />
          <Text>& Up</Text>
        </RatingContainer>
        <ClearButton onClick={clearRatingsFilter}>Clear</ClearButton>
    </RatingContentWrapper>*/}
  </Fieldset>
  )
}

export default Ranking
