// import {LOCATION_CHANGE} from 'connected-react-router'
// import {
  // all,
  // select,
  // takeEvery,
// } from 'redux-saga/effects'
// import {getCurrentUser} from 'users/store/selectors'
// import {actionTypes as userActionTypes} from 'users/store/constants'
// import {actionTypes as lmsActionTypes} from 'lms/store/constants'
// import {actionTypes as rfpActionTypes} from 'rfps/store/constants'
// import {getApplicationsState} from 'lms/store/selectors'
// import {getRfpData} from 'rfps/store/selectors'

// const dataLayer = window.dataLayer

// function* analyticsSaga(action) {
//   const state = yield select()
//   const currentUser = getCurrentUser(state)
//   const {data: appData} = getApplicationsState(state)
//   const rfpData = getRfpData(state)
//
//   const data = {
//     event: action.type,
//   }
//
//   if (currentUser) {
//     data.user = {
//       name: `${currentUser.firstName} ${currentUser.lastName}`,
//       email: currentUser.email,
//     }
//   }
//
//   switch (action.type) {
//     case userActionTypes.LOGIN_REQUESTED:
//       data.email = action.payload.credentials.email
//       break
//
//     case userActionTypes.CREATE_USER_REQUESTED:
//       data.userData = action.payload.user
//       break
//
//     case userActionTypes.SEND_PASSWORD_RESET:
//       data.email = action.payload.value.email
//       break
//
//     case userActionTypes.ADD_LMS_TO_LIST:
//       data.system = action.payload.map(id => appData.byId[id].name)
//       break
//
//     case userActionTypes.REMOVE_LMS_FROM_LIST:
//       data.system = appData.byId[action.payload].name
//       break
//
//     case lmsActionTypes.VIEW_APPLICATION_DETAILS:
//     case lmsActionTypes.VIEW_APPLICATION_CONTACT_INFO:
//       data.system = appData.byId[action.payload.id].name
//       break
//
//     case rfpActionTypes.OPEN_RFP_DIALOG:
//       data.system = appData.byId[action.payload.systemId].name
//       break
//
//     case rfpActionTypes.CREATE_RFP_REQUESTED:
//       data.rfpData = rfpData
//       break
//
//     case userActionTypes.LOGIN_SUCCEEDED:
//     case userActionTypes.LOGIN_FAILED:
//     case userActionTypes.LOGOUT_REQUESTED:
//     case userActionTypes.CREATE_USER_SUCCEEDED:
//     case userActionTypes.CREATE_USER_FAILED:
//     case userActionTypes.UPDATE_PASSWORD_REQUESTED:
//     case userActionTypes.UPDATE_PASSWORD_SUCCEEDED:
//     case userActionTypes.UPDATE_PASSWORD_FAILED:
//       break
//
//     default:
//       // data.payload = action.payload
//       break
//   }
//
//  // yield console.log(data)
//   yield dataLayer.push(data)
// }

// function* pageViewSaga(action) {
//   const {pathname} = action.payload.location
//
//   const data = {
//     event: 'location_change',
//     pathname,
//   }
//
//   // yield console.log({data})
//
//   yield dataLayer.push(data)
// }

// function* watchAnalytics() {
//   const analyticsActions = [
//     userActionTypes.LOGIN_REQUESTED,
//     // userActionTypes.LOGIN_SUCCEEDED,
//     userActionTypes.LOGIN_FAILED,
//     // userActionTypes.LOGOUT_REQUESTED,
//     userActionTypes.CREATE_USER_REQUESTED,
//     userActionTypes.CREATE_USER_SUCCEEDED,
//     userActionTypes.CREATE_USER_FAILED,
//     userActionTypes.SEND_PASSWORD_RESET,
//     userActionTypes.UPDATE_PASSWORD_REQUESTED,
//     userActionTypes.UPDATE_PASSWORD_SUCCEEDED,
//     userActionTypes.UPDATE_PASSWORD_FAILED,
//     userActionTypes.ADD_LMS_TO_LIST,
//     userActionTypes.REMOVE_LMS_FROM_LIST,
//     lmsActionTypes.VIEW_APPLICATION_DETAILS,
//     lmsActionTypes.VIEW_APPLICATION_CONTACT_INFO,
//     rfpActionTypes.OPEN_RFP_DIALOG,
//     rfpActionTypes.CREATE_RFP_REQUESTED,
//   ]
//
//   yield takeEvery(analyticsActions, analyticsSaga)
// }

// function* watchPageViews() {
//   const pageViewActions = [
//     LOCATION_CHANGE,
//   ]
//
//   yield takeEvery(pageViewActions, pageViewSaga)
// }

function* rootSaga() {
  // yield all([
  //   // watchAnalytics(),
  //   watchPageViews(),
  // ])
}

export default rootSaga
