import React from 'react'
import {styled} from '@material-ui/core/styles'
import Header from './Header'
import Description from './Description'
import CourseStandards from './CourseStandards'
import Features from './Features'
import Playlist from "./Playlist"
import Videos from './Videos'
import Whitepapers from './WhitePapers'
import whitePapers from "./WhitePapers";

const Content = styled('div')(({theme}) => ({
  position: 'relative',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2)
}))

const Body = styled('div')({})

const Detail = ({lms, ...props}) => {
  const {
    description,
    courseStandards,
    features,
    caseStudies
  } = lms

  const videos = caseStudies.filter(cs => cs.mimeType === 'video/mp4')
  const whitepapers = caseStudies.filter(cs => cs.mimeType === 'application/pdf')


  let { playlistItems } = lms
  let sorted = playlistItems.slice(0).sort((a,b) => {
    if (a.order < b.order){ return -1 }
    if (a.order > b.order) { return 1 }
    else { return 0 }
  })

  return (
    <Content {...props}>
      <Header lms={lms} />
      <Body>
        {/*<Ranking rankings={craigsRatings} />*/}
        <Description description={description} />        
          
        {lms.isPremium &&  
        <Playlist isEdit={false} items={sorted} applicationId={lms.id} isPremium={lms.isPremium}/>
        }
        <CourseStandards courseStandards={courseStandards} />
        <Features features={features} />
        {/*<Videos videos={videos} />
        <Whitepapers whitePapers={whitepapers} />*/}
      </Body>
    </Content>
  )
}

export default Detail
