import React from 'react'
import {withRouter} from 'react-router-dom'
import MuiTooltip from '@material-ui/core/Tooltip'
import MuiIconButton from '@material-ui/core/IconButton'
import CreateIcon from '@material-ui/icons/Create'
import {useCurrentUser} from 'users/hooks'
import {useMode} from 'app/hooks'

const EditButton = ({lms, history, staticContext, ...props}) => {
  const [mode] = useMode()
  const user = useCurrentUser()

  const {id, admins} = lms

  switch (mode) {
    case 'user':
      return null
					
    case 'User':
      return null

    case 'vendor':
      if (!admins || !admins.includes(user.id)) return null

    // no default
  }

  const buttonIcon = <CreateIcon/>
  const tooltipText = "Edit System"

  const handleClick = (event) => {
    event.stopPropagation()

    history.push(`/systems/${id}/edit`)
  }

  return (
    <MuiTooltip title={tooltipText}>
      <MuiIconButton
        color="secondary"
        onClick={handleClick}
        {...props}
      >
        {buttonIcon}
      </MuiIconButton>
    </MuiTooltip>
  )
}

export default withRouter(EditButton)
