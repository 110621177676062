import React from 'react'
import {
  styled,
} from '@material-ui/core'
import Count from './Count'

const Content = styled('div')(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-around',
}))

const Counts = ({data, ...props}) => {
  if (!data) return null

  const {
    total,
    newThisWeek,
    newThisMonth,
    admin,
    vendor,
    premium,
    platinum,
  } = data

  return (
    <Content {...props}>
      <Count name="Total" value={total}/>
      <Count name="New This Week" value={newThisWeek}/>
      <Count name="New This Month" value={newThisMonth}/>
      <Count name="Admins" value={admin}/>
      <Count name="Vendors" value={vendor}/>
      <Count name="Premium" value={premium}/>
      <Count name="Platinum" value={platinum}/>
    </Content>
  )
}

export default Counts
