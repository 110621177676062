import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
  InputBase as MuiInputBase,
} from '@material-ui/core'
import {getRfpData} from 'rfps/store/selectors'
import {updateRfpData} from 'rfps/store/actions'
import Section from 'rfps/components/Dialog/Section'

const NumOfUsers = (props) => {
  const {numberOfUsers} = useSelector(getRfpData)
  const dispatchRedux = useDispatch()

  const handleChange = e => dispatchRedux(updateRfpData({numberOfUsers: e.target.value}))

  const error = !numberOfUsers || numberOfUsers === ""

  return (
    <Section title="How many end-users will be using the system in the first year? (estimate is fine)" error={error} {...props}>
      <MuiInputBase fullWidth placeholder="Enter number of users" value={numberOfUsers || ""} onChange={handleChange}/>
    </Section>
  )
}

export default NumOfUsers
