import reducer from './reducer'
import rootSaga from "./sagas"

const getRfpsModule = () => ({
  id: 'rfps',
  reducerMap: {
    rfps: reducer,
  },
  sagas: [
    rootSaga,
  ],
  initialActions: [],
  finalActions: [],
})

export default getRfpsModule
