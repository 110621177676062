import React from 'react'
import {useDispatch} from 'react-redux'
import {
  styled,
  IconButton as MuiIconButton,
} from '@material-ui/core'
import MuiCloseIcon from '@material-ui/icons/Close'
import {useSystemData} from 'lms/hooks'
import {removeSystemsFromCompareList} from 'lms/store/actions'

const EmptyBox = styled('div')(({theme}) => ({
  backgroundColor: theme.palette.grey[50],
  marginLeft: 5,
  marginRight: 5,
  height: 75,
  width: 75,
}))

const Content = styled('div')(({theme}) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.primary.main,
  marginLeft: 5,
  marginRight: 5,
  height: 75,
  width: 75,
  padding: 3,
}))

const IconButton = styled(MuiIconButton)(({theme}) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  margin: 0,
}))

const Thumbnail = styled('img')({
  objectFit: 'contain',
  width: '100%',
})

const System = ({id, ...props}) => {
  const system = useSystemData(id)
  const dispatchRedux = useDispatch()

  if (!id) return <EmptyBox/>

  const src = system.thumbnail?.uri

  const handleRemove = () => dispatchRedux(removeSystemsFromCompareList(id))

  return (
    <Content>
      <IconButton size="small" onClick={handleRemove}>
        <MuiCloseIcon fontSize="inherit"/>
      </IconButton>
      <Thumbnail src={src}/>
    </Content>
  )
}

export default System
