import React from 'react'
import {styled} from '@material-ui/core/styles'
import SectionTitle from 'lms/components/Detail/SectionTitle'

const Content = styled('div')({
  fontSize: 16,
})

const Description = ({description}) => {
  return (
    <>
      <SectionTitle>Description</SectionTitle>
      <Content>{description}</Content>
    </>
  )
}

export default Description
