import React from 'react'
import { useDispatch } from 'react-redux'
import { styled } from '@material-ui/core/styles'
import MuiMenuItem from '@material-ui/core/MenuItem'
import MuiOutlinedInput from '@material-ui/core/OutlinedInput'
import MuiFormControl from '@material-ui/core/FormControl'
import MuiInputLabel from '@material-ui/core/InputLabel'
import MuiSelect from '@material-ui/core/Select'
import { useLabelWidth } from 'ui/hooks'
import { useCurrentSearchFilters } from 'searches/hooks'
import { updateCurrentSearchFilters } from 'searches/store/actions'
import { useCookies } from 'react-cookie'
import { useCurrentUser } from 'users/hooks'
import { THIRTY_DAYS } from 'constants/timeIntervals'

const learnersValues = [
  { min: null, max: null },
  { min: 50, max: 999 },
  { min: 1000, max: 5000 },
  { min: 5000, max: 10000 },
  { min: 10000, max: 50000 },
  { min: 50000, max: 200000 },
  { min: 200000, max: 1000000 },
  { min: 1000000, max: null }
]

const items = learnersValues.map(v => {
  let label = v.min ? `${Number(v.min.toFixed(2)).toLocaleString('en-US')}` : ``
  label += v.max ? ` - ${Number(v.max.toFixed(2)).toLocaleString('en-US')}` : `+`

  if(!v.min && !v.max) {
    label = 'No min or max'
  }

  return (
    <MuiMenuItem key={v.min} value={v.min}>
      {label}
    </MuiMenuItem>
  )
})

const FormControl = styled(MuiFormControl)({
  marginTop: 12,
  marginBottom: 0
})

const StyledLabel = styled(MuiInputLabel)({
  whiteSpace: 'nowrap'
})

const ErrorMessage = styled('p')(({ theme }) => ({
  color: theme.palette.error.main,
  margin: '8px 12px',
  fontSize: 12
}))

const Learners = ({ labelText = 'Number of Learners', error, ...props }) => {
  const filters = useCurrentSearchFilters()
  const dispatchRedux = useDispatch()
  const [cookies, setCookie] = useCookies(['guestSearchCount'])
  const user = useCurrentUser()
  const isGuestUser = !user
  const selectValue = filters.numLearners.min || ''

  const handleSelect = event => {
    const { value } = event.target

    const numLearners = learnersValues.find(v => v.min === value) || {
      min: null,
      max: null
    }
    if (isGuestUser) {
      const guestSearchCount = cookies.guestSearchCount || 0
      setCookie(
        'guestSearchCount',
        Number(guestSearchCount) + 1,
        { path: '/' },
        THIRTY_DAYS
      )
    }
    dispatchRedux(updateCurrentSearchFilters({ numLearners }))
  }

  const [labelWidth, labelRef] = useLabelWidth(labelText)

  const input = (
    <MuiOutlinedInput id='learners' labelWidth={labelWidth} name='learners' />
  )

  let errorMessage
  if (error) {
    errorMessage = <ErrorMessage>{error}</ErrorMessage>
  }

  return (
    <FormControl
      variant='outlined'
      margin='dense'
      fullWidth
      error={!!error}
      {...props}
    >
      <StyledLabel ref={labelRef} htmlFor='learners'>
        {labelText}
      </StyledLabel>
      <MuiSelect
        name='learners'
        value={selectValue}
        onChange={handleSelect}
        input={input}
      >
        <MuiMenuItem value=''></MuiMenuItem>
        {items}
      </MuiSelect>
      {errorMessage}
    </FormControl>
  )
}

export default Learners
