import React from 'react'
import {useSelector} from 'react-redux'
import {
  makeStyles,
  styled,
} from '@material-ui/core'
import {getShowErrors} from 'rfps/store/selectors'

const Fieldset = styled('fieldset')(({theme}) => ({
  marginBottom: theme.spacing(1),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.divider,
  borderRadius: theme.shape.borderRadius,
}))

const Legend = styled('legend')({
  whiteSpace: 'normal',
  maxWidth: '100%',
})

const useStyles = makeStyles((theme) => ({
  fieldsetError: {
    borderColor: theme.palette.error.main,
  },
  legendError: {
    color: theme.palette.error.main,
  },
}))

const Section = ({title, error, children, ...props}) => {
  const classes = useStyles()
  const showErrors = useSelector(getShowErrors)

  let fieldsetClassname, legendClassname
  if (showErrors && error) {
    fieldsetClassname = classes.fieldsetError
    legendClassname = classes.legendError
  }

  return (
    <Fieldset className={fieldsetClassname} {...props}>
      <Legend className={legendClassname}>{title}</Legend>
      {children}
    </Fieldset>
  )
}

export default Section
