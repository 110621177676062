import React from 'react'
import {
  styled,
  Checkbox as MuiCheckbox,
  FormControlLabel as MuiFormControlLabel,
} from '@material-ui/core'
import {useEditApplication} from 'lms/hooks'

const PublishedLabel = styled(MuiFormControlLabel)({
})

const PublishedCheck = (props) => {
  const [application, updateApplication] = useEditApplication()

  const updatePublished = e => updateApplication({isPublished: e.target.checked})

  const publishedCheck = <MuiCheckbox color="primary" checked={!!application.isPublished} onChange={updatePublished} />

  return (
    <PublishedLabel
      label="Published?"
      control={publishedCheck}
      labelPlacement="end"
      {...props}
    />
  )
}

export default PublishedCheck
