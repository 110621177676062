import React from 'react'
import {styled} from '@material-ui/styles'
import imageSrc from 'images/FindAnLMS-logo-ai.png'

const Image = styled('img')({
  width: '100%',
  objectFit: 'contain',
})

const Logo = (props) => {
  return (
    <Image src={imageSrc} {...props}/>
  )
}

export default Logo
