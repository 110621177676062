import React, {useEffect, useState} from "react"
import {generateVideoThumbnails} from "@rajesh896/video-thumbnails-generator"
import MuiInputLabel from "@material-ui/core/InputLabel"
import MuiButton from "@material-ui/core/Button"
import PublishIcon from "@material-ui/icons/Publish"
import {CircularProgress, styled} from "@material-ui/core"
import './ThumbnailSelection.css'
import {getDefaultThumbnail} from "./thumbnailUtils"

const ThumbnailSelection = ({fileType, video, selectedThumbnailChanged, existingThumbnail, existingItem}) => {
  const [thumbnails,setThumbnails] = useState([])
  const [generatingThumbnails,setGeneratingThumbnails] = useState(false)
  const [selectedPlaylistThumbnail, setSelectedPlaylistThumbnail] = useState()


  useEffect(() => {
    generateThumbnails(video)
  }, [video])

  const renderSelectedThumbnail = thumbnail => {
    if (thumbnail instanceof Blob){
      return URL.createObjectURL(selectedPlaylistThumbnail)
    }else{
      return selectedPlaylistThumbnail
    }
  }

  const generateThumbnails = async (video) => {
    if (video && !existingItem) {
      setGeneratingThumbnails(true)
      const thumbnails = await generateVideoThumbnails(video, 5,'file')
      setSelectedPlaylistThumbnail(thumbnails[1])
      selectedThumbnailChanged(thumbnails[1])
      setThumbnails(thumbnails)
      setGeneratingThumbnails(false)
    }
  }

  const handleThumbnailInput = event => {
    const {files} = event.target
    const file = files[0]
    selectedThumbnailChanged(file)
    setSelectedPlaylistThumbnail(file)

  }

  const onThumbnailSelected = thumbnail => {
    setSelectedPlaylistThumbnail(thumbnail)
    selectedThumbnailChanged(thumbnail)
  }

  const GeneratedThumbnail = styled('img')({
    marginRight: '0.5rem',
    cursor: 'pointer'
  })

  const FileInput = styled('input')({
    display: 'none',
  })

  const SelectThumbnail = () => {
    return (
      <>
        <MuiInputLabel htmlFor='playlist-thumbnail-file-input'>
          <MuiButton component='span'
            startIcon={<PublishIcon />}
            size="small"
            color="primary"
            variant='outlined'
          >Upload custom thumbnail</MuiButton>
        </MuiInputLabel>
        <FileInput
          id="playlist-thumbnail-file-input"
          accept="image/png, image/jpeg"
          type="file"
          onChange={handleThumbnailInput}
        />
      </>
    )
  }

  return (
    <div className='thumbnail-selection'>

      { existingThumbnail && !selectedPlaylistThumbnail &&
          <img src={existingThumbnail.uri}
               height='200px'
               width='355px'
               alt='Existing playlist thumbnail'
               className='thumbnail-selection-image' />
      }
      { selectedPlaylistThumbnail &&
          <img src={renderSelectedThumbnail(selectedPlaylistThumbnail)}
            height='200px'
            width='355px'
            alt='Selected playlist thumbnail'
            className='thumbnail-selection-image' />
      }
      { !existingThumbnail && !selectedPlaylistThumbnail && !generatingThumbnails &&
          <span>
            { getDefaultThumbnail(fileType) }
          </span>
      }
      <SelectThumbnail />
      { generatingThumbnails &&
          <>
            <label className="thumbnail-selection-instructions">Generating thumbnails, please wait</label>
            <CircularProgress />
          </>
      }
      { thumbnails && thumbnails.length > 0 &&
          <>
            <h5 className="thumbnail-selection-instructions">Generated Thumbnails (click to change selected thumbnail)</h5>
            {thumbnails.map(t => ( <GeneratedThumbnail
              onClick={() => onThumbnailSelected(t)}
              alt="thumbnail"
              height='100px'
              src={t}
              key={t} /> ))
            }
          </>
      }
    </div>
  )

}

export default ThumbnailSelection