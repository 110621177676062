import React, {useRef} from 'react'
import {
  styled,
  FormControlLabel as MuiFormControlLabel,
  InputAdornment as MuiInputAdornment,
  Checkbox as MuiCheckbox,
  TextField as MuiTextField,
} from '@material-ui/core'
import {useEditApplication} from 'lms/hooks'

const Content = styled('div')({
})

const Heading = styled('h4')(({theme}) => ({
  margin: 0,
  marginTop: 12,
  marginBottom: 6,
  fontSize: 18,
  fontWeight: 500,
  color: theme.palette.text.secondary,
  borderBottom: `1px solid ${theme.palette.divider}`,
}))

const Grid = styled('div')({
  display: 'grid',
  gridColumnGap: 10,
  gridTemplateColumns: '120px 120px auto',
})

const Migration = styled(MuiFormControlLabel)({
  marginLeft: 0,
  marginTop: 8,
})

const Setup = (props) => {
  const [application, updateApplication] = useEditApplication()
  const minInputRef = useRef(null)
  const maxInputRef = useRef(null)

  const updateMigration = e => updateApplication({migration: e.target.checked})

  const migrationCheck = <MuiCheckbox color="primary" checked={!!application.migration} onChange={updateMigration}/>

  return (
    <Content {...props}>
      <Heading>Setup</Heading>
      <Grid>
        <MuiTextField
          defaultValue={application.minSetupTime}
          label="Min"
          variant="outlined"
          margin="dense"
          inputRef={minInputRef}
          InputProps={{
            endAdornment: <MuiInputAdornment position="end" onClick={() => minInputRef.current.focus()}>months</MuiInputAdornment>
          }}
          onChange={(e) => updateApplication({minSetupTime: e.target.value})}
        />
        <MuiTextField
          defaultValue={application.maxSetupTime}
          label="Max"
          variant="outlined"
          margin="dense"
          inputRef={maxInputRef}
          InputProps={{
            endAdornment: <MuiInputAdornment position="end" onClick={() => maxInputRef.current.focus()}>months</MuiInputAdornment>
          }}
          onChange={(e) => updateApplication({maxSetupTime: e.target.value})}
        />
        <Migration
          label="Supports migration from another learning system"
          control={migrationCheck}
          labelPlacement="end"
        />
      </Grid>
    </Content>
  )
}

export default Setup
