import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {styled} from '@material-ui/core'
import {editApplicationBegin} from 'lms/store/actions'
import Form from './Form'
import {getEditApplicationState} from "../../store/selectors";

const Content = styled('div')(({theme}) => ({
  marginTop: 20,
  marginBottom: 20,
  padding: 20,
  backgroundColor: theme.palette.background.paper,
}))

const Edit = ({match}) => {
  const {id} = match.params
  const dispatchRedux = useDispatch()
  const editState = useSelector(getEditApplicationState)
  if (!editState || editState.data.id !== id) {
    dispatchRedux(editApplicationBegin(id))
  }

  return (
    <Content>
      <Form/>
    </Content>
  )
}

export default Edit
