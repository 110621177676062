import React from 'react'
import {
  styled,
  TextField as MuiTextField,
} from '@material-ui/core'
import {useEditApplication} from 'lms/hooks'

const Content = styled('div')({
})

const Heading = styled('h4')(({theme}) => ({
  margin: 0,
  marginTop: 12,
  marginBottom: 6,
  fontSize: 18,
  fontWeight: 500,
  color: theme.palette.text.secondary,
  borderBottom: `1px solid ${theme.palette.divider}`,
}))

const Grid = styled('div')({
  display: 'grid',
  gridColumnGap: 10,
  gridTemplateColumns: '120px 120px',
})

const Learners = (props) => {
  const [application, updateApplication] = useEditApplication()

  return (
    <Content {...props}>
      <Heading>Number of Learners</Heading>
      <Grid>
        <MuiTextField
          defaultValue={application.minLearners}
          label="Min"
          variant="outlined"
          margin="dense"
          onChange={(e) => updateApplication({minLearners: e.target.value})}
        />
        <MuiTextField
          defaultValue={application.maxLearners}
          label="Max"
          variant="outlined"
          margin="dense"
          onChange={(e) => updateApplication({maxLearners: e.target.value})}
        />
      </Grid>
    </Content>
  )
}

export default Learners
