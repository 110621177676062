import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
  styled,
  FormControlLabel as MuiFormControlLabel,
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup,
  TextField as MuiTextField,
} from '@material-ui/core'
import {getRfpData} from 'rfps/store/selectors'
import {updateRfpData} from 'rfps/store/actions'
import Section from 'rfps/components/Dialog/Section'

const RadioGroup = styled(MuiRadioGroup)(({theme}) => ({
  flexDirection: 'row',
}))

const Group = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
}))

const CurrentSystem = (props) => {
  const {currentSystem, currentVendor} = useSelector(getRfpData)
  const dispatchRedux = useDispatch()

  const disableText = !currentSystem || currentSystem === "No"

  const handleChange = (event) => {
    let {value} = event.target

    dispatchRedux(updateRfpData({currentSystem: value}))

    if (value === "No") dispatchRedux(updateRfpData({currentVendor: null}))
  }

  const handleText = e => dispatchRedux(updateRfpData({currentVendor: e.target.value}))

  const error = !currentSystem

  return (
    <Section title="Do you currently have a learning system?" error={error} {...props}>
      <RadioGroup value={currentSystem || ""} onChange={handleChange}>
        <MuiFormControlLabel value="No" label="No" control={<MuiRadio />} />
        <Group>
          <MuiFormControlLabel value="Yes" label="Yes" control={<MuiRadio />} />
          <MuiTextField placeholder="Name of current vendor" disabled={disableText} value={currentVendor || ""} onChange={handleText}/>
        </Group>
      </RadioGroup>
    </Section>
  )
}

export default CurrentSystem
