import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
  styled,
  FormControlLabel as MuiFormControlLabel,
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup,
} from '@material-ui/core'
import {getRfpData} from 'rfps/store/selectors'
import {updateRfpData} from 'rfps/store/actions'
import Section from 'rfps/components/Dialog/Section'

const RadioGroup = styled(MuiRadioGroup)(({theme}) => ({
  flexDirection: 'row',
}))

const Timeframe = (props) => {
  const {timeframe} = useSelector(getRfpData)
  const dispatchRedux = useDispatch()

  const handleChange = e => dispatchRedux(updateRfpData({timeframe: e.target.value}))

  const error = !timeframe

  return (
    <Section title="If you are planning on buying a system, when are you planning on making a decision by?" error={error} {...props}>
      <RadioGroup value={timeframe || ""} onChange={handleChange}>
        <MuiFormControlLabel value="0-3 months" label="0-3 months" control={<MuiRadio />} />
        <MuiFormControlLabel value="3-6 months" label="3-6 months" control={<MuiRadio />} />
        <MuiFormControlLabel value="6+ months" label="6+ months" control={<MuiRadio />} />
      </RadioGroup>
    </Section>
  )
}

export default Timeframe
