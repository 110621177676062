import React from 'react'
import {
  styled,
} from '@material-ui/core'
import {useCompareData} from './hooks'
import Row from './Row'
import Description from './Description'
import KeySpecs from './KeySpecs'
import CourseStandards from './CourseStandards'
import Features from './Features'

const Content = styled('div')(({theme}) => ({
}))

const CompareTable = (props) => {
  const {systems} = useCompareData()

  return (
    <Content>
      <Row>
        {systems.map(s => <Description key={s.id} description={s?.description}/>)}
      </Row>

      <KeySpecs/>
      <CourseStandards/>
      <Features/>
    </Content>
  )
}

export default CompareTable
