import React from 'react'
import {
  styled,
  Paper as MuiPaper,
} from '@material-ui/core'
import {useCounts} from 'reports/hooks'
import Loader from 'ui/components/Loader'
import Counts from 'reports/components/Counts'

const Content = styled(MuiPaper)(({theme}) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
}))

const Title = styled('h4')(({theme}) => ({
  fontSize: 24,
  color: theme.palette.text.secondary,
  margin: 0,
  marginBottom: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
}))

const Systems = (props) => {
  const {systems} = useCounts()

  const content = systems.total ? <Counts data={systems}/> : <Loader/>

  return (
    <Content {...props}>
      <Title>Systems</Title>
      {content}
    </Content>
  )
}

export default Systems
