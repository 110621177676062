import {createStore} from 'redux-dynamic-modules'
import {getSagaExtension} from 'redux-dynamic-modules-saga'
import getAppModule from 'app/store/module'
import getLmsModule from 'lms/store/module'
import getSearchesModule from 'searches/store/module'
import getUsersModule from 'users/store/module'
import getLeadsModule from 'leads/store/module'
import getAnalyticsModule from 'analytics/store/module'
import getRfpsModule from 'rfps/store/module'

const store = createStore(
  {
    initialState: {},
    enhancers: [],
    extensions: [
      getSagaExtension(),
    ],
  },

  // dynamic modules
  getAppModule(),
  getLmsModule(),
  getSearchesModule(),
  getUsersModule(),
  getLeadsModule(),
  getAnalyticsModule(),
  getRfpsModule(),
)

export default store
