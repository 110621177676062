import produce from 'immer'
import Fuse from 'fuse.js'
import {delay, select, put, takeLatest, all, call} from 'redux-saga/effects'
import feathersClient from 'utils/feathers'
import {actionTypes as lmsActionTypes} from 'lms/store/constants'
import {actionTypes as rfpActionTypes} from 'rfps/store/constants'
import {actionTypes} from './constants'
import {refetchCurrentUser} from 'users/store/actions'
import {getApplicationsState} from 'lms/store/selectors'
import {getCurrentUser} from 'users/store/selectors'
import {getCurrentSearch, getCurrentSearchFilters} from './selectors'
import {applyFilters} from 'searches/utils'
import {fetchSearches} from './actions'

const fuseOptions = {
  id: 'id',
  threshold: 0.2,
  distance: 10000,
  keys: ['name', 'description']
}

const searchesService = feathersClient.service('searches')

function * updateSearchFiltersSaga (action) {
  const state = yield select()
  const currentFilters = getCurrentSearchFilters(state)

  // produce new filters
  const filters = produce(currentFilters, draft =>
    Object.assign(draft, action.payload.filters)
  )

  // store updated search in Redux
  const {id} = action.payload
  yield put({
    type: actionTypes.UPDATE_CURRENT_SEARCH_FILTERS_COMPLETED,
    payload: {id, filters}
  })
  yield put({type: actionTypes.UPDATE_CURRENT_SEARCH_RESULTS_REQUESTED})
}

function * updateSearchResultsSaga () {
  // debounce the update
  yield delay(250)

  const state = yield select()
  const {data} = getApplicationsState(state)
  const filters = getCurrentSearchFilters(state)
  // ranking is now depricated
  /*
    if (filters.ranking) {
			delete filters.ranking
		}
    */
  // apply new filters
  let applications = yield applyFilters(data, filters)

  // if (filters.craigsRating && applications) {
  //   const content = applications.map(id => data.byId[id])
  //   const filteredApplications = content.reduce((acc, application) => {
  //     const { craigsRatings } = application
  //     if (
  //       craigsRatings &&
  //       craigsRatings.average &&
  //       craigsRatings.average >= filters.craigsRating
  //     ) {
  //       acc.push(application.id)
  // 					}
  // 					return acc
  //   }, [])
  //   applications = filteredApplications
  // }

  // apply keyword matching
  if (filters.keywords) {
    const content = applications ? applications.map(id => data.byId[id]) : []
    const fuse = yield new Fuse(content, fuseOptions)

    const keywords = filters.keywords.split(' ').filter(w => w)

    yield keywords.forEach(word => {
      const results = fuse.search(word)
      applications = results.map(result => result.item._id)
    })
  }

  yield put({
    type: actionTypes.UPDATE_CURRENT_SEARCH_RESULTS_COMPLETED,
    payload: {applications}
  })
}

function * saveCurrentSearchSaga (action) {
  const state = yield select()
  const currentUser = getCurrentUser(state)
  const {id, filters, applications} = getCurrentSearch(state)

  let source
  switch (action.type) {
    case lmsActionTypes.VIEW_APPLICATION_DETAILS:
      source = 'System details viewed'
      break

    case rfpActionTypes.CREATE_RFP_REQUESTED:
      source = 'Request Information'
      break

    case actionTypes.SAVE_CURRENT_SEARCH_REQUESTED:
    default:
      source = action.payload.source
      break
  }

  // if (initialFilters === produce(initialFilters, draft => Object.assign(draft, filters))) {
  //   // don't save search with cleared filters
  //   return
  // }

  if (!id && currentUser) {
    try {
      const newSearch = {
        source,
        user: currentUser.id,
        filters,
        applications
      }

      yield call([searchesService, 'create'], newSearch)
      yield put({type: actionTypes.SAVE_CURRENT_SEARCH_COMPLETED})
      yield put(refetchCurrentUser())
    } catch (error) {
      yield put({type: actionTypes.SAVE_CURRENT_SEARCH_FAILED})
      console.log({error})
    }
  }
}

function * fetchSearchesSaga (action) {
  const {limit, skip} = action.payload
  // DAYS * HOURS * MINUTES * SECONDS + MILLISECONDS
  const DAY_MS = 90 * 24 * 60 * 60 * 1000
  try {
    const query = {version: 2, $limit: limit, $skip: skip, 
      createdAt: {
        $gt: new Date().getTime() - DAY_MS
      }
    }

    /*
    //getReportOptions
    const state = yield select()
    const reportOptions = getReportOptions(state)
    if (reportOptions.range !== 'all') {
      // 
      query.startDate = {$gte: reportOptions.startDate};
      query.endDate  = {$lte: reportOptions.endDate};
    }
    */
    const results = yield call([searchesService, 'find'], {
      query: query
    })

    const fetchedCount = skip + limit
    const completed = fetchedCount >= results.total

    yield put({
      type: actionTypes.FETCH_SEARCHES_SUCCEEDED,
      payload: {results, completed}
    })

    if (!completed) yield put(fetchSearches({limit, skip: fetchedCount}))
  } catch (error) {
    yield put({type: actionTypes.FETCH_SEARCHES_FAILED, payload: {error}})
  }
}

function * watchSearchFiltersUpdate () {
  yield takeLatest(
    actionTypes.UPDATE_CURRENT_SEARCH_FILTERS_REQUESTED,
    updateSearchFiltersSaga
  )
}

function * watchSearchResultsUpdate () {
  yield all([
    yield takeLatest(
      actionTypes.UPDATE_CURRENT_SEARCH_RESULTS_REQUESTED,
      updateSearchResultsSaga
    ),

    // also update search results after new list of applications has been updated
    yield takeLatest(
      lmsActionTypes.FETCH_APPLICATIONS_SUCCEEDED,
      updateSearchResultsSaga
    ),
    yield takeLatest(
      lmsActionTypes.SAVE_APPLICATION_SUCCEEDED,
      updateSearchResultsSaga
    )
  ])
}

function * watchSaveCurrentSearch () {
  const saveSearchActions = [
    actionTypes.SAVE_CURRENT_SEARCH_REQUESTED,
    lmsActionTypes.VIEW_APPLICATION_DETAILS,
    rfpActionTypes.CREATE_RFP_REQUESTED
  ]

  yield takeLatest(saveSearchActions, saveCurrentSearchSaga)
}

function * watchFetchSearches () {
  yield takeLatest(actionTypes.FETCH_SEARCHES_REQUESTED, fetchSearchesSaga)
}

function * rootSaga () {
  yield all([
    watchSearchFiltersUpdate(),
    watchSearchResultsUpdate(),
    watchSaveCurrentSearch(),
    watchFetchSearches()
  ])
}

export default rootSaga
