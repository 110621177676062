import React from 'react'
import {styled} from '@material-ui/core/styles'
import missingImageUrl from 'images/no_image.png'

const Image = styled('img')({
  width: 200,
  height: 200,
  objectFit: 'contain',
})

const Logo = ({thumbnail, ...props}) => {
  const imageUrl = thumbnail ? thumbnail.uri : missingImageUrl

  return (
    <Image src={imageUrl} {...props}/>
  )
}

export default Logo
