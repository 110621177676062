import React, { useState } from 'react'
import {
  styled,
  Button as MuiButton,
  Collapse as MuiCollapse,
} from '@material-ui/core'
import Section from 'rfps/components/Dialog/Section'
import EditForm from './EditForm'
import { useCurrentUser } from 'users/hooks'

const Info = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between'
}))

const Name = styled('div')(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500
}))

const Title = styled('div')(({ theme }) => ({
  fontStyle: 'italic'
}))

const Company = styled('div')(({ theme }) => ({}))
const Email = styled('div')(({ theme }) => ({}))
const Phone = styled('div')(({ theme }) => ({}))
const Location = styled('div')(({ theme }) => ({}))
const Column = styled('div')(({ theme }) => ({}))

const ContactCard = props => {
  const [editing, setEditing] = useState(false)
  const currentUser = useCurrentUser()
  const [user, setUser] = useState(currentUser)

  const closeEditingMode = () => {
    setEditing(false)
  }

  const {
    firstName,
    lastName,
    jobTitle,
    companyName,
    email,
    phone,
    city,
    state,
    country
  } = user

  const fullName = `${firstName} ${lastName}`

  let errors = false
  if (!fullName || fullName === '') errors = true
  if (!jobTitle || jobTitle === '') errors = true
  if (!companyName || companyName === '') errors = true
  if (!email || email === '') errors = true
  if (!phone || phone === '') errors = true
  if (!city || city === '') errors = true
  if (!state || state === '') errors = true
  if (!country || country === '') errors = true

  const cityState = []
  city ? cityState.push(city) : cityState.push('[CITY]')
  state ? cityState.push(state) : cityState.push('[STATE/REGION]')

  return (
    <Section title='Contact Info' error={errors} {...props}>
      <MuiCollapse in={editing}>
        <EditForm
          closeEditingMode={closeEditingMode}
          currentUser={user}
          updateUser={setUser}
        />
      </MuiCollapse>
      <MuiCollapse in={!editing}>
        <Info>
          <Column>
            <Name>{fullName}</Name>
            <Title>{jobTitle}</Title>
            <Company>{companyName}</Company>
            <Email>{email}</Email>
            <Phone>{phone}</Phone>
            <Location>
              {cityState.join(', ')} {country || '[COUNTRY]'}
            </Location>
          </Column>
          <Column>
            <MuiButton
              color='primary'
              size='small'
              onClick={() => setEditing(true)}
            >
              Edit
            </MuiButton>
          </Column>
        </Info>
      </MuiCollapse>
    </Section>
  )
}

export default ContactCard
