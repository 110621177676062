import React from 'react'
import { useSelector } from 'react-redux'
import { styled } from '@material-ui/core/styles'
import MuiPaper from '@material-ui/core/Paper'
import Collapse from '@material-ui/core/Collapse'
import { getApplicationsState } from 'lms/store/selectors'
import Card from 'lms/components/Card'
import CompareCard from 'lms/components/Compare/Card'
import { useCurrentUser } from 'users/hooks'
import RfpDialog from 'rfps/components/Dialog'

const Content = styled('div')({
  marginTop: 20,
  marginBottom: 20
})

const List = styled('ul')({
  margin: 0,
  padding: 0,
  listStyleType: 'none'
})

const ListItem = styled('li')({
  marginBottom: 20
})

const EmptyList = styled(MuiPaper)({
  padding: 50,
  fontSize: 18,
  textAlign: 'center'
})

const MyList = props => {
  const {
    data: { byId }
  } = useSelector(getApplicationsState)
  const { applications } = useCurrentUser()

  // TODO: store sorted IDs in Redux
  const nameSorter = (a, b) => {
    if (byId[a] && byId[b]) {
      return byId[a].name.localeCompare(byId[b].name)
    }
  }
  const sortedIds = applications.slice().sort(nameSorter)
  const lmsFromIds = sortedIds.reduce((acc, id) => {
    const lms = byId[id]
    if (lms) acc.push(lms)
    return acc
  }, [])
  const doLmsExist = lmsFromIds.length > 0

  const totalCount = applications.length
  const shouldShowEmptyList = totalCount === 0 || !doLmsExist

  return (
    <Content>
      <CompareCard />
      <List>
        {lmsFromIds.map(lms => (
          <ListItem key={lms.id}>
            <Card lms={lms} />
          </ListItem>
        ))}
        <Collapse in={shouldShowEmptyList}>
          <li>
            <EmptyList>
              You haven't added any Learning Systems to your list.
            </EmptyList>
          </li>
        </Collapse>
      </List>
      <RfpDialog />
    </Content>
  )
}

export default MyList
