import React from 'react'
import { styled } from '@material-ui/core/styles'
import { useCourseStandardsState } from 'lms/hooks'
import SectionTitle from 'lms/components/Detail/SectionTitle'
import { HIDDEN_FEATURES } from 'constants/hiddenFeatures'

const List = styled('ul')({})

const Value = styled('li')({})

const CourseStandards = ({ courseStandards = [] }) => {
  const { data } = useCourseStandardsState()

  if (courseStandards.length === 0) return null
  const checkForCourseStandardVisibility = cs =>
    !HIDDEN_FEATURES.includes(cs.name)
  const courseStandardsToRender = courseStandards.filter(id => {
    const courseStandardFromId = data.byId[id]
    const isVisible =
      courseStandardFromId &&
      checkForCourseStandardVisibility(courseStandardFromId)
    return Boolean(courseStandardFromId) && isVisible
  })
  return (
    <>
      <SectionTitle>Course Standards</SectionTitle>
      <List>
        {courseStandardsToRender.map(id => (
          <Value key={id}>{data.byId[id].name}</Value>
        ))}
      </List>
    </>
  )
}

export default CourseStandards
