import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
  styled,
  FormControlLabel as MuiFormControlLabel,
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup,
} from '@material-ui/core'
import {getRfpData} from 'rfps/store/selectors'
import {updateRfpData} from 'rfps/store/actions'
import Section from 'rfps/components/Dialog/Section'

const RadioGroup = styled(MuiRadioGroup)(({theme}) => ({
  flexDirection: 'row',
}))

const Migration = (props) => {
  const {migration} = useSelector(getRfpData)
  const dispatchRedux = useDispatch()

  const handleChange = e => dispatchRedux(updateRfpData({migration: e.target.value}))

  const error = !migration

  return (
    <Section title="Do you need to migrate your data?" error={error} {...props}>
      <RadioGroup value={migration || ""} onChange={handleChange}>
        <MuiFormControlLabel value="No" label="No" control={<MuiRadio />} />
        <MuiFormControlLabel value="Yes" label="Yes" control={<MuiRadio />} />
      </RadioGroup>
    </Section>
  )
}

export default Migration
