import React from 'react'
import { useCompareData } from './hooks'
import { Section, Subsection } from './Sections'
import Row from './Row'
import Values from './Values'
import { HIDDEN_FEATURES } from 'constants/hiddenFeatures'

const CourseStandards = props => {
  const { systems, courseStandardsData } = useCompareData()

  const checkIfVisible = cs => cs && !HIDDEN_FEATURES.includes(cs.name)
  const sortedCourseStardIds = courseStandardsData.allIds
    .slice()
    .sort((a, b) =>
      courseStandardsData.byId[a].name.localeCompare(
        courseStandardsData.byId[b].name
      )
    )

  return (
    <>
      <Section>Course Standards</Section>
      {sortedCourseStardIds.map(id => {
        const courseStandard = courseStandardsData.byId[id]
        const isCsVisible = checkIfVisible(courseStandard)
        return (
          isCsVisible && (
            <React.Fragment key={id}>
              <Subsection>{courseStandard.name}</Subsection>
              <Row>
                {systems.map(s => (
                  <Values key={s.id}>
                    {s?.courseStandards.includes(id) ? 'Yes' : 'No'}
                  </Values>
                ))}
              </Row>
            </React.Fragment>
          )
        )
      })}
    </>
  )
}

export default CourseStandards
