import React from 'react'
import {styled} from '@material-ui/core/styles'
import {useAudienceTypesState} from 'lms/hooks'

const Title = styled('span')(({theme}) => ({
}))

const Values = styled('span')(({theme}) => ({
  fontWeight: 300,
}))

const nameSorter = (a, b) => a.localeCompare(b)

const AudienceTypes = ({audienceTypes}) => {
  const {status, data} = useAudienceTypesState()

  if (status !== 'succeeded' || audienceTypes.length === 0) return null

  const names = audienceTypes.map(id => data.byId[id] && data.byId[id].name).sort(nameSorter)

  /* Don't show removed audienceType */
  let namesFiltered = names.filter(function( element ) {
    return element !== undefined && element !== 'B2B' && element !== 'B2C' && element !== 'Customers' && element !== 'Other'
  })

  /* Place 'Other' at the end if it exist */
  if(names.find(n => n === 'Other')){
    namesFiltered.push('Other')
  }

  return (
    <>
      <Title>Audience Types:</Title>
      <Values>{namesFiltered.join(", ")}</Values>
    </>
  )
}

export default AudienceTypes
