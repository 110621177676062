import React, {useEffect, useState} from 'react'
import SectionTitle from 'lms/components/Detail/SectionTitle'
import AddEditPlaylist from "./AddEditPlaylist"
import {savePlaylistItemOrder} from '../../../store/actions'
import {useDispatch} from 'react-redux'
import './playlist.css'

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'

import {SortableItem} from "./SortableItem"
import PlaylistItemViewer from "./PlaylistItemViewer";

const Playlist = ({items = [], applicationId, isEdit = false, isPremium = false}) => {

  const [playlistItems, setPlaylistItems] = useState([])
  const [selectedItem,setSelectedItem] = useState()
  const [selectedEditItem,setSelectedEditItem] = useState()
  const dispatchRedux = useDispatch()

  useEffect(() => {
    setPlaylistItems(items)
  }, [items])

  useEffect(() => {    
    if(selectedEditItem) {
      document.getElementById("contentPlayer").scrollIntoView({ behavior: "smooth"});
    }
  }, [selectedEditItem])

  const sensors = useSensors(
    useSensor(PointerSensor,{
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )




  const handleDragEnd = event => {
    const {active, over} = event

    if (active.id !== over.id) {
      setPlaylistItems(items => {
        const oldIndex = items.findIndex( i => i.id === active.id)
        const newIndex = items.findIndex( i => i.id === over.id)
        const newList = arrayMove(items, oldIndex, newIndex)
        dispatchRedux(savePlaylistItemOrder(newList))
        return newList
      })
    }
  }

  return (
    <>
    { (isEdit || items && items.length > 0) &&
      <SectionTitle id='contentPlayer'>Content Player</SectionTitle>
    }
    
    {isPremium &&
      <AddEditPlaylist isEdit={isEdit} editItem={selectedEditItem} inPlayerViewItem={selectedItem} setSelectedItem={setSelectedItem} onClearEditItem={() => setSelectedEditItem()} />
    }

    {isEdit && !isPremium &&
      <p>Contact the administrator to set up the Content Player for your account.</p>
    }
     
      { items && items.length > 0 &&
      <div className='playlist'>
        <PlaylistItemViewer item={selectedItem} applicationId={applicationId} trackViews={!isEdit} isPremium={isPremium} />
        {
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={playlistItems}
              strategy={verticalListSortingStrategy}
            >
              <div className='playlist-inner-wrapper'>
                {playlistItems.map(item => (
                  <SortableItem editable={isEdit} key={item._id} id={item._id} item={item} setSelectedItem={setSelectedItem} editSelectedItem={setSelectedEditItem}/>
                )
                )}
              </div>
            </SortableContext>
          </DndContext>

        }
      </div>
      }
    </>
  )
}

export default Playlist
