import React, {useState} from 'react'
import {
  styled,
  Button as MuiButton,
  CircularProgress as MuiCircularProgress,
} from '@material-ui/core'
import {useEditApplication, useUploadFile} from 'lms/hooks'

const Content = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  paddingBottom: theme.spacing(1),
}))

const ThumbnailBox = styled('div')(({theme}) => ({
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
  border: `1px dashed ${theme.palette.primary.light}`
}))

const ThumbnailImage = styled('img')({
  objectFit: 'contain',
  maxWidth: '100%',
  maxHeight: 150,
})

const FileInput = styled('input')({
  display: 'none',
})

const InputLabel = styled('label')({
  textAlign: 'center',
})

const Thumbnail = (props) => {
  const [application] = useEditApplication()
  const uploadFile = useUploadFile()
  const [localFileUrl, setLocalFileUrl] = useState()

  const handleFileInput = (event) => {
    if (event.target.files[0]) {
      uploadFile({
        type: 'thumbnail',
        category: 'application/thumbnail',
        file: event.target.files[0],
      })

      // // create a url for the local file
      setLocalFileUrl(URL.createObjectURL(event.target.files[0]))
    }
  }

  let thumbnail
  if (application.thumbnail) {
    const {uri, loaded, size} = application.thumbnail

    if (Number.isInteger(loaded)) {
      if (loaded === size) {
        // display image from local file
        thumbnail = <ThumbnailImage src={localFileUrl}/>
      } else {
        // display progress indicator
        const value = (loaded / size) * 100
        thumbnail = <MuiCircularProgress variant="static" value={value}/>
      }
    } else {
      // display image from url
      thumbnail = <ThumbnailImage src={uri}/>
    }
  }

  return (
    <Content {...props}>
      <ThumbnailBox>
        {thumbnail}
      </ThumbnailBox>
      <FileInput
        id="thumbnail-file-input"
        accept="image/*"
        type="file"
        onChange={handleFileInput}
      />
      <InputLabel htmlFor="thumbnail-file-input">
        <MuiButton color="primary" component="span">
          Upload Logo
        </MuiButton>
      </InputLabel>
    </Content>
  )
}

export default Thumbnail
