import React, {useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
  styled,
  Button as MuiButton,
  Paper as MuiPaper,
  Collapse
} from '@material-ui/core'
import {getCompareSystemIds} from 'lms/store/selectors'
import System from './System'
import {removeSystemsFromCompareList, compareSystems} from 'lms/store/actions'
import CompareDialog from 'lms/components/Compare/Dialog'
import {useCurrentUser} from 'users/hooks'
import {useCookies} from 'react-cookie'
import { THIRTY_DAYS } from 'constants/timeIntervals'

const Paper = styled(MuiPaper)(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2)
}))

const HelpText = styled('div')(({theme}) => ({}))

const Heading = styled('h4')(({theme}) => ({
  margin: 0,
  fontSize: 16
}))

const Subheading = styled('p')(({theme}) => ({
  margin: 0
}))

const Systems = styled('div')(({theme}) => ({
  display: 'flex',
  marginLeft: 5,
  marginRight: 5
}))

const Actions = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-end'
}))

const CompareCard = props => {
  const compareIds = useSelector(getCompareSystemIds)
  const dispatchRedux = useDispatch()
  const [showDialog, setShowDialog] = useState(false)
  const user = useCurrentUser()
  const isGuestUser = !user
  const [cookies, setCookie] = useCookies(['guestCompareViews'])

  const disableCompare = compareIds.length < 2

  const handleClear = () =>
    dispatchRedux(removeSystemsFromCompareList(compareIds))

  const handleCompare = () => {
    setShowDialog(true)
    dispatchRedux(compareSystems(compareIds))
    if (isGuestUser) {
      const guestCompareViews = cookies.guestCompareViews || 0
      setCookie(
        'guestCompareViews',
        Number(guestCompareViews) + 1,
        {path: '/'},
        THIRTY_DAYS
      )
    }
  }

  const handleCloseDialog = event => {
    event.stopPropagation()
    setShowDialog(false)
  }

  return (
    <Collapse in={compareIds.length > 0}>
      <Paper elevation={1} {...props}>
        <CompareDialog open={showDialog} onClose={handleCloseDialog} />
        <HelpText>
          <Heading>Compare Learning Systems</Heading>
          <Subheading>You can select up to 4.</Subheading>
        </HelpText>
        <Systems>
          <System id={compareIds[0]} />
          <System id={compareIds[1]} />
          <System id={compareIds[2]} />
          <System id={compareIds[3]} />
        </Systems>
        <Actions>
          <MuiButton size='small' onClick={handleClear}>
            Clear
          </MuiButton>
          <MuiButton
            size='small'
            color='primary'
            onClick={handleCompare}
            disabled={disableCompare}
          >
            Compare
          </MuiButton>
        </Actions>
      </Paper>
    </Collapse>
  )
}

export default CompareCard
