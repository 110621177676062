import React from 'react'
import {styled} from '@material-ui/core/styles'

const Title = styled('span')(({theme}) => ({
}))

const Values = styled('span')(({theme}) => ({
  fontWeight: 300,
}))

const Timeframe = ({minSetupTime, maxSetupTime}) => {
  const timeframe = (minSetupTime === maxSetupTime) ? minSetupTime : `${minSetupTime}-${maxSetupTime}`
  const unit = (maxSetupTime === 1) ? "month" : "months"

  return (
    <>
      <Title>Setup timeframe: </Title>
      <Values>{timeframe} {unit}</Values>
    </>
  )
}

export default Timeframe
