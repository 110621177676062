import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
  InputBase as MuiInputBase,
} from '@material-ui/core'
import {getRfpData} from 'rfps/store/selectors'
import {updateRfpData} from 'rfps/store/actions'
import Section from 'rfps/components/Dialog/Section'

const Status = (props) => {
  const {currentStatus} = useSelector(getRfpData)
  const dispatchRedux = useDispatch()

  const handleChange = e => dispatchRedux(updateRfpData({currentStatus: e.target.value}))

  const placeholderText = "Please provide features of interest"

  const error = !currentStatus || currentStatus === ""

  return (
    <Section title="What would you like to see in the demo?" error={error} {...props}>
      <MuiInputBase multiline fullWidth placeholder={placeholderText} value={currentStatus || ""} onChange={handleChange}/>
    </Section>
  )
}

export default Status
