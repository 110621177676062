import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {styled} from '@material-ui/core/styles'
import MuiPaper from '@material-ui/core/Paper'
import {getApplicationsState} from 'lms/store/selectors'
import {useCurrentSearchResults} from 'searches/hooks'
import Card from 'lms/components/Card'
import RfpDialog from 'rfps/components/Dialog'
import {useMode} from 'app/hooks'
import CompareCard from 'lms/components/Compare/Card'
import CreateAppButton from './CreateAppButton'
import Loader from '../Loader'
import SortBy from './SortBy'
import LazyLoad from 'react-lazyload'

const Content = styled('ul')({
  flex: '0 1 auto',
  margin: 0,
  padding: 0,
  listStyleType: 'none'
})

const ListHeader = styled('li')(({theme}) => ({}))

const Split = styled('div')(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(1)
}))

const Count = styled('p')(({theme}) => ({
  margin: 0,
  color: theme.palette.primary.contrastText,
  fontSize: 20
}))

const ListItem = styled('li')({
  marginBottom: 20
})

const EmptyList = styled(MuiPaper)({
  padding: 50,
  fontSize: 18,
  textAlign: 'center',
  width: '100%'
})

const LmsCardComponent = ({lms}) => (
  <ListItem>
    <Card lms={lms} />
  </ListItem>
)

const List = ({hasFetchedApplications}) => {
  const {
    data: {allIds, byId, platinumIds, premiumIds}
  } = useSelector(getApplicationsState)
  const filteredIds = useCurrentSearchResults()

  const [sortType, setSortType] = useState('A-Z')

  const [mode] = useMode()

  const updatedAtNewSorter = (a, b) =>
    new Date(byId[b].updatedAt) - new Date(byId[a].updatedAt)
  const updatedAtOldSorter = (a, b) =>
    new Date(byId[a].updatedAt) - new Date(byId[b].updatedAt)

  const ascendingAlphaSorter = (a, b) =>
    byId[a].name.localeCompare(byId[b].name)
  const descendingAlphaSorter = (a, b) =>
    byId[b].name.localeCompare(byId[a].name)

  /* Craig's Ratings - Start*/
  const averageRatingSorter = (a, b) => {
    const aLms = byId[a]
    const bLms = byId[b]
    const {craigsRatings: aLmsCraigsRatings} = aLms
    const {craigsRatings: bLmsCraigsRatings} = bLms
    if (aLmsCraigsRatings && bLmsCraigsRatings) {
      return bLmsCraigsRatings.average - aLmsCraigsRatings.average
    } else if (aLmsCraigsRatings && !bLmsCraigsRatings) {
      return true
    } else if (bLmsCraigsRatings && !aLmsCraigsRatings) {
      return false
    }
  }

  const sortByAverageRating = idsToSort => {
    const rankedIds = idsToSort.reduce(
      (acc, id) => {
        const lms = byId[id]
        const {craigsRatings} = lms
        const hasRankings = Boolean(craigsRatings)
        if (hasRankings) {
          acc.hasRanking.push(id)
        } else {
          acc.noRanking.push(id)
        }
        return acc
      },
      {
        hasRanking: [],
        noRanking: []
      }
    )
    const sortedRanked = rankedIds.hasRanking.slice().sort(averageRatingSorter)
    const sortedAlpha = rankedIds.noRanking.slice().sort(ascendingAlphaSorter)
    return [...sortedRanked, ...sortedAlpha]
  }
  /* Craig's Ratings - End*/

  /* Craig's Top Leanrin Systems - Start*/
  const averageRankingSorter = (a, b) => {
    const aLms = byId[a]
    const bLms = byId[b]
    const {rank: aLmsRank} = aLms
    const {rank: bLmsRank} = bLms

    if (aLmsRank && bLmsRank) {
      return aLmsRank - bLmsRank
    } else if (aLmsRank && !bLmsRank) {
      return true
    } else if (bLmsRank && !aLmsRank) {
      return false
    }
  }

  const sortByAverageRanking = idsToSort => {
    const rankedIds = idsToSort.reduce(
      (acc, id) => {
        const lms = byId[id]
        const {rank} = lms
        const hasRankings = Boolean(rank)
        if (hasRankings) {
          acc.hasRanking.push(id)
        } else {
          acc.noRanking.push(id)
        }
        return acc
      },
      {
        hasRanking: [],
        noRanking: []
      }
    )
    const sortedRanked = rankedIds.hasRanking.slice().sort(averageRankingSorter)
    const sortedAlpha = rankedIds.noRanking.slice().sort(ascendingAlphaSorter)
    return [...sortedRanked, ...sortedAlpha]
  }
  /* Craig's Top Leanrin Systems - End*/

  const displayedIds = filteredIds || allIds
		
  const getSortedIds = () => {
    const allPlatinumIds = displayedIds.filter(displayId =>
      platinumIds.includes(displayId)
    )
    const allPremiumIds = displayedIds.filter(displayId =>
      !platinumIds.includes(displayId) && premiumIds.includes(displayId)
    )
    const allNonPremiumIds = displayedIds.filter(
      displayId => !platinumIds.includes(displayId) && !premiumIds.includes(displayId)
    )
    let platinumSorted, premiumSorted, nonPremiumSorted
    switch (sortType) {
      case 'A-Z':
        platinumSorted = allPlatinumIds.slice().sort(ascendingAlphaSorter)
        premiumSorted = allPremiumIds.slice().sort(ascendingAlphaSorter)
        nonPremiumSorted = allNonPremiumIds.slice().sort(ascendingAlphaSorter)
        break
      case 'Z-A':
        platinumSorted = allPlatinumIds.slice().sort(descendingAlphaSorter)
        premiumSorted = allPremiumIds.slice().sort(descendingAlphaSorter)
        nonPremiumSorted = allNonPremiumIds.slice().sort(descendingAlphaSorter)
        break
      case 'updated-newest':
        platinumSorted = allPlatinumIds.slice().sort(updatedAtNewSorter)
        premiumSorted = allPremiumIds.slice().sort(updatedAtNewSorter)
        nonPremiumSorted = allNonPremiumIds.slice().sort(updatedAtNewSorter)
        break
      case 'updated-oldest':
        platinumSorted = allPlatinumIds.slice().sort(updatedAtOldSorter)
        premiumSorted = allPremiumIds.slice().sort(updatedAtOldSorter)
        nonPremiumSorted = allNonPremiumIds.slice().sort(updatedAtOldSorter)
        break
      case 'rank':
        platinumSorted = sortByAverageRanking(allPlatinumIds)
        premiumSorted = sortByAverageRanking(allPremiumIds)
        nonPremiumSorted = sortByAverageRanking(allNonPremiumIds)
        break
      case 'average':
        platinumSorted = sortByAverageRating(allPlatinumIds)
        premiumSorted = sortByAverageRating(allPremiumIds)
        nonPremiumSorted = sortByAverageRating(allNonPremiumIds)
        break
      default:
        //A-Z is the default
        platinumSorted = allPlatinumIds.slice().sort(ascendingAlphaSorter)
        premiumSorted = allPremiumIds.slice().sort(ascendingAlphaSorter)
        nonPremiumSorted = allNonPremiumIds.slice().sort(ascendingAlphaSorter)
        break
    }
				
    return [...platinumSorted, ...premiumSorted, ...nonPremiumSorted]
  }
  const sortedIds = getSortedIds()

  const lmsIdsToDisplay = sortedIds.filter(displayId =>
    displayedIds.includes(displayId)
  )

  const renderLmsCards = () => {
    return lmsIdsToDisplay.reduce((acc, lmsId) => {
      const lms = byId[lmsId]
      // hide unpublished systems from users
      const shouldHide =
        (mode === 'user' || mode === 'User') && !lms.isPublished
      if (!shouldHide) {
        acc.push(
          <LazyLoad
            key={lmsId}
            height={100}
            offset={2000}
            overflow
            once
          >
            <LmsCardComponent lms={lms} key={lmsId} />
          </LazyLoad>
        )
      }
      return acc
    }, [])
  }

  const resultString = lmsIdsToDisplay.length === 1 ? 'result' : 'results'
  const countString = `${lmsIdsToDisplay.length} ${resultString}`
  const isAdmin = mode === 'admin'

  const createAppButton = isAdmin ? <CreateAppButton /> : null

  const isLoaded = hasFetchedApplications && Boolean(allIds)
  const noAppsToShow = lmsIdsToDisplay.length === 0

  return isLoaded ? (
    noAppsToShow ? (
      <EmptyList>No Applications to Show</EmptyList>
    ) : (
      <>
        <Content>
          <ListHeader>
            <Split>
              <Count>{countString}</Count>
              <SortBy handleSortTypeChange={setSortType} sortType={sortType} />
              {createAppButton}
            </Split>
            <CompareCard />
          </ListHeader>
          {renderLmsCards()}
          <RfpDialog />
        </Content>
      </>
    )
  ) : (
    <Loader />
  )
}

export default List
