import React from 'react'
import {useDispatch} from 'react-redux'
import {
  styled,
  Paper as MuiPaper,
  Button as MuiButton
} from '@material-ui/core'
import {useCounts} from 'reports/hooks'
import Loader from 'ui/components/Loader'
import Counts from 'reports/components/Counts'
import {openReportDialog} from 'reports/store/actions'
import {useApplicationState} from 'lms/hooks'
import {useCurrentUser} from 'users/hooks'

const Content = styled(MuiPaper)(({theme}) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2)
}))

const Header = styled('div')(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`
}))

const ActionWrapper = styled('div')({})

const Title = styled('h4')(({theme}) => ({
  margin: 0,
  fontSize: 24,
  color: theme.palette.text.secondary
}))

const Searches = props => {
  const {playlistViews} = useCounts()
  const {data} = useApplicationState()
  const {applications: applicationIds} = useCurrentUser()
  const dispatchRedux = useDispatch()
  const openGeneralReportDialog = () =>
    dispatchRedux(openReportDialog('contentPlayerViews'))
  const openPopularReportDialog = () =>
    dispatchRedux(openReportDialog('popularContentPlayerViews'))

  const doPlaylistViewsExist =
      playlistViews.total !== undefined && playlistViews.total !== null
  const content = doPlaylistViewsExist ? <Counts data={playlistViews} /> : <Loader />

  let isPremiumVendor = false
  let generalReportButton, popularReportButton

  if (data) {
    isPremiumVendor = applicationIds
      .map(id => data.byId[id])
      .some(app => app && app.isPremium)
  }

  if (isPremiumVendor) {
    generalReportButton = (
      <MuiButton size='small' color='primary' onClick={openGeneralReportDialog}>
          Generate Report
      </MuiButton>
    )
    popularReportButton = (
      <MuiButton size='small' color='primary' onClick={openPopularReportDialog}>
          Popular Report
      </MuiButton>
    )
  }

  return (
    <Content {...props}>
      <Header>
        <Title>Content Player Views</Title>
        <ActionWrapper>
          {popularReportButton}
          {generalReportButton}
        </ActionWrapper>
      </Header>
      {content}
    </Content>
  )
}

export default Searches
