import React from 'react'
import {
  styled,
  TextField as MuiTextField,
} from '@material-ui/core'
import {useEditApplication} from 'lms/hooks'

const Heading = styled('h4')(({theme}) => ({
  margin: 0,
  marginTop: 24,
  marginBottom: 6,
  fontSize: 18,
  fontWeight: 500,
  color: theme.palette.text.secondary,
  borderBottom: `1px solid ${theme.palette.divider}`,
}))

const Grid = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridColumnGap: 20,
})

const ContactInfo = (props) => {
  const [application, updateApplication] = useEditApplication()
  const {contact={}} = application

  const updateContact = (change) => updateApplication({contact: {...contact, ...change}})

  return (
    <>
      <Heading>Contact Person</Heading>
      <Grid>
        <MuiTextField
          defaultValue={contact.fullName}
          label="Contact Name"
          variant="outlined"
          margin="dense"
          onChange={(e) => updateContact({fullName: e.target.value})}
        />
        <MuiTextField
          defaultValue={contact.jobPosition}
          label="Job Position"
          variant="outlined"
          margin="dense"
          onChange={(e) => updateContact({jobPosition: e.target.value})}
        />
        <MuiTextField
          defaultValue={contact.email}
          label="Email"
          variant="outlined"
          margin="dense"
          onChange={(e) => updateContact({email: e.target.value})}
        />
        <MuiTextField
          defaultValue={contact.phone}
          label="Phone"
          variant="outlined"
          margin="dense"
          onChange={(e) => updateContact({phone: e.target.value})}
        />
      </Grid>
    </>
  )
}

export default ContactInfo
