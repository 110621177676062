import React from 'react'
import { useCompareData } from './hooks'
import { Section, Subsection } from './Sections'
import Row from './Row'
import Values from './Values'
import { pricesToString } from 'lms/utils'

const deploymentTypeStrings = {
  saas: 'SaaS',
  'self-hosted': 'Self-Hosted'
}

const nameSorter = (a, b) => a.localeCompare(b)

const KeySpecs = props => {
  const { systems, audienceTypeData } = useCompareData()

  return (
    <>
      <Section>Key Specs</Section>
      <Subsection>Audience Types</Subsection>
      <Row>
        {systems.map(sys => {
          const values = sys?.audienceTypes
            .filter(id => Boolean(audienceTypeData.byId[id]))
            .map(
              id => audienceTypeData.byId[id] && audienceTypeData.byId[id].name
            )
            .sort(nameSorter)

          /* Don't show removed audienceType */
          let namesFiltered = values.filter(function( element ) {
            return element !== 'B2B' && element !== 'B2C' && element !== 'Customers' && element !== 'Other'
          })
          
          /* Place 'Other' at the end if it exist */
          if(values.find(v => v === 'Other')){
            namesFiltered.push('Other')
          }
          

          return <Values key={sys.id}>{namesFiltered.join(", ")}</Values>
        })}
      </Row>
      <Subsection>Deployment Types</Subsection>
      <Row>
        {systems.map(sys => {
          const values = sys?.deploymentTypes
            .map(t => deploymentTypeStrings[t])
            .join(', ')

          return <Values key={sys.id}>{values}</Values>
        })}
      </Row>

      <Subsection>Number of Users</Subsection>
      <Row>
        {systems.map(sys => {
          const learners = []

          if (sys?.minLearners) learners.push(sys.minLearners)
          if (sys?.maxLearners) learners.push(sys.maxLearners)

          if (learners.length > 0) {
            return <Values key={sys.id}>{learners.join(' - ')}</Values>
          } else {
            return null
          }
        })}
      </Row>

      <Subsection>Pricing</Subsection>
      <Row>
        {systems.map(sys => {
          const priceString = pricesToString(sys.priceFrom, sys.priceTo)

          return <Values key={sys.id}>{priceString}</Values>
        })}
      </Row>

      <Subsection>Setup Timeframe</Subsection>
      <Row>
        {systems.map(sys => {
          const times = []

          if (sys?.minSetupTime) times.push(sys.minSetupTime)
          if (sys?.maxSetupTime) times.push(sys.maxSetupTime)

          if (times.length > 0) {
            return <Values key={sys.id}>{times.join(' - ')} months</Values>
          } else {
            return null
          }
        })}
      </Row>
      {/*
      <Subsection>Learning System Type</Subsection>
      <Row>
        {systems.map(s => (
          <Values key={s.id}>{s?.type}</Values>
        ))}
      </Row>
      */}
    </>
  )
}

export default KeySpecs
