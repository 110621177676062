import React from 'react'
import {useDispatch} from 'react-redux'
import {
  Button as MuiButton, CircularProgress,
} from '@material-ui/core'
import {useReportData} from 'reports/hooks'
import {closeReportDialog} from 'reports/store/actions'

const DownloadButton = (props) => {
  const {name, url, ready} = useReportData()
  const dispatchRedux = useDispatch()

  const handleClick = () => dispatchRedux(closeReportDialog())

  return (
    <MuiButton
      color="primary"
      variant="contained"
      disabled={!ready}
      component="a"
      href={url}
      download={name}
      onClick={handleClick}
      style={{minHeight:'36.5px'}}
      {...props}
    >
      {!ready ? 
        <>
          <CircularProgress color="secondary" style={{height: '15px', width: '15px', color: 'rgba(0, 0, 0, 0.26)', marginRight: '6px' }}/>
          <div>
            <div style={{lineHeight:'1'}}>Generating Report</div>
            <div style={{lineHeight:'1', fontSize:'9px'}}>This may take a few seconds</div>
          </div>
        </>
        :
        'Download CSV'
      }
    </MuiButton>
  )
}

export default DownloadButton
