import debounce from 'lodash/debounce'
import { useSelector, useDispatch } from 'react-redux'
import { editApplicationChange, addToUploadQueue } from 'lms/store/actions'
import {
  getApplicationsState,
  getAudienceTypesState,
  getCourseStandardsState,
  getIndustriesState,
  getFeaturesState,
  getEditApplicationState,
} from 'lms/store/selectors'

export const useAppReadyState = () => {
  const { status: applicationState } = useApplicationState()
  const { status: audienceTypesState } = useAudienceTypesState()
  const { status: courseStandardsState } = useCourseStandardsState()
  const { status: industriesState } = useIndustriesState()
  const { status: featuresState } = useFeaturesState()

  return (
    applicationState === 'succeeded' &&
    audienceTypesState === 'succeeded' &&
    courseStandardsState === 'succeeded' &&
    industriesState === 'succeeded' &&
    featuresState === 'succeeded'
  )
}

export const useApplicationState = () => {
  const state = useSelector(getApplicationsState)

  return state
}

export const useAudienceTypesState = () => {
  const state = useSelector(getAudienceTypesState)

  return state
}

export const useCourseStandardsState = () => {
  const state = useSelector(getCourseStandardsState)

  return state
}

export const useIndustriesState = () => {
  const state = useSelector(getIndustriesState)

  return state
}

export const useFeaturesState = () => {
  const state = useSelector(getFeaturesState)

  return state
}

export const useEditApplication = () => {
  const { data } = useSelector(getEditApplicationState)
  const dispatchRedux = useDispatch()

  const update = debounce(
    change => dispatchRedux(editApplicationChange(change)),
    250
  )

  return [data, update]
}

export const useUploadFile = () => {
  const dispatchRedux = useDispatch()

  const uploadFile = upload => dispatchRedux(addToUploadQueue(upload))

  return uploadFile
}

export const useSystemData = id => {
  const { data } = useSelector(getApplicationsState)

  return data.byId[id]
}
