import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
  styled,
  withStyles,
  Checkbox as MuiCheckbox,
  FormControlLabel as MuiFormControlLabel,
} from '@material-ui/core'
import {getCompareSystemIds} from 'lms/store/selectors'
import {addSystemsToCompareList, removeSystemsFromCompareList} from 'lms/store/actions'
import {useMode} from 'app/hooks'
import { useCurrentUser } from 'users/hooks'

const Checkbox = styled(MuiCheckbox)(({theme}) => ({
  color: theme.palette.primary.main,
}))

const Label = withStyles((theme) => ({
  root: {
    marginLeft: 0,
  },
  label: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 500,
  },
}))(MuiFormControlLabel)

const CompareCheck = ({id, ...props}) => {
		const [mode] = useMode()
  const user = useCurrentUser()
		
  const compareIds = useSelector(getCompareSystemIds)
		const dispatchRedux = useDispatch()

		const isGuestUser = !user
		
  if (!isGuestUser && mode !== 'user' &&  mode !== 'User') return null

  const set = new Set(compareIds)
  const checked = set.has(id)

  const disabled = !checked && set.size > 3

  const handleClick = (event) => {
    event.stopPropagation()
    event.preventDefault()

    if (!disabled) {
      checked ? dispatchRedux(removeSystemsFromCompareList(id)) : dispatchRedux(addSystemsToCompareList(id))
    }
  }

  const checkbox = <Checkbox checked={checked}/>

  return (
    <Label
      label="Compare"
      control={checkbox}
      onClick={handleClick}
      disabled={disabled}
      {...props}
    />
  )
}

export default CompareCheck
