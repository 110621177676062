import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'
import { actionTypes } from './constants'
import { refetchCurrentUser } from 'users/store/actions'
import { getCurrentUser } from 'users/store/selectors'
import { getRfpData } from './selectors'
import feathersClient from 'utils/feathers'
import { postMessage } from 'app/store/actions'

const rfpsService = feathersClient.service('rfps')

function * startRfpSaga (action) {
  const state = yield select()
  const rfpData = getRfpData(state)
  const currentUser = getCurrentUser(state)

  const contact = {
    fullName:
      rfpData.contact.fullName ||
      `${currentUser.firstName} ${currentUser.lastName}`,
    email: rfpData.contact.email || currentUser.email,
    phone: rfpData.contact.phone || currentUser.phone,
    companyName: rfpData.contact.companyName || currentUser.companyName,
    jobTitle: rfpData.contact.jobTitle || currentUser.jobTitle,
    city: rfpData.contact.city || currentUser.city,
    state: rfpData.contact.state || currentUser.state,
    country: rfpData.contact.country || currentUser.country
  }

  yield put({ type: actionTypes.UPDATE_RFP_DATA, payload: { contact } })
}

function * createRfpSaga (action) {
  const state = yield select()
		const rfpData = getRfpData(state)
		
  try {
    yield call([rfpsService, 'create'], { version: 2, ...rfpData })

    yield put({
      type: actionTypes.CREATE_RFP_SUCCEEDED,
      payload: { systemId: rfpData.systemId }
    })
    yield put({ type: actionTypes.CLOSE_RFP_DIALOG })
    yield put(postMessage({ message: 'Information Requested' }))
    yield put(refetchCurrentUser())
  } catch (error) {
    console.log({ error })
    yield put({ type: actionTypes.CREATE_RFP_FAILED })
  }
}

function * watchOpenDialog () {
  yield takeLatest(actionTypes.OPEN_RFP_DIALOG, startRfpSaga)
}

function * watchCreateRfp () {
  yield takeEvery(actionTypes.CREATE_RFP_REQUESTED, createRfpSaga)
}

function * rootSaga () {
  yield all([watchOpenDialog(), watchCreateRfp()])
}

export default rootSaga
