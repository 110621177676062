import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useCurrentUser} from 'users/hooks'
import {styled, Button as MuiButton} from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import {openRfpDialog} from 'rfps/store/actions'
import {getCurrentUser} from 'users/store/selectors'

const Button = styled(MuiButton)(({theme}) => ({
  '&:disabled': {
    color: theme.palette.success.main
  }
}))

const RfpButton = ({systemId, ...props}) => {
  const user = useCurrentUser()
  const currentUser = useSelector(getCurrentUser)
  const dispatchRedux = useDispatch()

  const isGuestUser = !user

  let buttonText = 'Request Information'
  let disabled = false
  let hasRequestedQuote = false

  if (
    currentUser &&
    currentUser.rfps &&
    currentUser.rfps.some(r => r.application === systemId)
  ) {
    buttonText = 'Information Requested'
    disabled = true
    hasRequestedQuote = true
  }

  const handleClick = event => {
    event.preventDefault()
    event.stopPropagation()
    if (!isGuestUser) {
      dispatchRedux(openRfpDialog(systemId))
    }
  }

  return (
    <Tooltip
      title='Create a FREE account to access this feature'
      disableHoverListener={!isGuestUser}
    >
      <span>
        <Button
          color='primary'
          variant='outlined'
          onClick={handleClick}
          disabled={disabled}
          style={{
            color: isGuestUser
              ? '#D3D3D3'
              : hasRequestedQuote
                ? 'rgb(67, 160, 71)'
                : '',
            border: `1px solid ${
              isGuestUser || hasRequestedQuote ? '#D3D3D3' : ''
            }`
          }}
          {...props}
        >
          {buttonText}
        </Button>
      </span>
    </Tooltip>
  )
}

export default RfpButton
