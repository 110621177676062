import React from 'react'
import {styled} from '@material-ui/core/styles'
import Filters from 'lms/components/Filters'
import List from './List'
import {useSelector} from 'react-redux'
import {getApplicationsState} from 'lms/store/selectors'


const Content = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  marginTop: 20,
  marginBottom: 20,
})

const StyledFilters = styled(Filters)({
  // position: 'sticky',
  top: 20,
  flex: '0 0 auto',
  marginRight: 20,
})

const FilteredList = (props) => {
  const {status} = useSelector(getApplicationsState)

  const hasFetchedApplications = status === 'succeeded'

  return (
    <Content>
      <StyledFilters/>
      <List hasFetchedApplications={hasFetchedApplications}/>
    </Content>
  )
}

export default FilteredList
