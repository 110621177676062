export const pricesToString = (priceFrom, priceTo) => {
  const prices = []

  if (priceFrom) prices.push(`$${Number(priceFrom.toFixed(2)).toLocaleString('en-US')}`)
  if (priceTo) {
    let priceToStr = `$${Number(priceTo.toFixed(2)).toLocaleString('en-US')}`

    if (priceTo >= 1000000) priceToStr += '+'

    prices.push(priceToStr)
		}
		
  const priceString = prices.length > 0 ? `${prices.join(' - ')} per year` : null

  return priceString
}
