import React from 'react'
import { useDispatch } from 'react-redux'
import { styled } from '@material-ui/core/styles'
import MuiFormControlLabel from '@material-ui/core/FormControlLabel'
import MuiCheckbox from '@material-ui/core/Checkbox'
import { useAudienceTypesState } from 'lms/hooks'
import { useCurrentSearchFilters } from 'searches/hooks'
import { updateCurrentSearchFilters } from 'searches/store/actions'
import { useCookies } from 'react-cookie'
import { useCurrentUser } from 'users/hooks'
import { THIRTY_DAYS } from 'constants/timeIntervals'

const Fieldset = styled(({ theme, error, ...props }) => (
  <fieldset {...props} />
))(({ theme, error }) => ({
  margin: 0,
  borderColor: error ? theme.palette.error.main : 'rgba(0, 0, 0, 0.23)',
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: theme.shape.borderRadius,
}))

const Legend = styled(({ theme, error, ...props }) => <legend {...props} />)(
  ({ theme, error }) => ({
    padding: 5,
    fontSize: 12,
    color: error ? theme.palette.error.main : theme.palette.text.secondary,
  })
)

const Row = styled('div')({
  display: 'flex',
  padding: '0 7px',
})

const Col = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
})

const FormControlLabel = styled(MuiFormControlLabel)({})

const Checkbox = styled(MuiCheckbox)({})

const Required = () => <span>&thinsp;*</span>

const ErrorMessage = styled('p')(({ theme }) => ({
  color: theme.palette.error.main,
  margin: '8px 12px 0',
  fontSize: 12,
}))

const AudienceTypes = ({
  labelText = 'Audience Types',
  required,
  error,
  ...props
}) => {
  const { data } = useAudienceTypesState()
  const { audienceTypes } = useCurrentSearchFilters()
  const user = useCurrentUser()
  const isGuestUser = !user
  const [cookies, setCookie] = useCookies(['guestSearchCount'])
  const dispatchRedux = useDispatch()

  let customers, employees, members, students, other
  data.allIds.forEach(id => {
    const audienceType = data.byId[id]

    switch (audienceType.name) {
      case 'Customers/Partners':
        customers = audienceType
        break

      case 'Employees':
        employees = audienceType
        break

      case 'Members':
        members = audienceType
        break

      case 'Students':
        students = audienceType
        break

      case 'Other':
        other = audienceType
        break

      // no default
    }
  })

  const isChecked = id => audienceTypes.includes(id)

  const setCheck = (id, checked) => {
    const set = new Set(audienceTypes)
    checked ? set.add(id) : set.delete(id)
    if (isGuestUser) {
      const guestSearchCount = cookies.guestSearchCount || 0
      setCookie(
        'guestSearchCount',
        Number(guestSearchCount) + 1,
        { path: '/' },
        THIRTY_DAYS
      )
    }
    dispatchRedux(
      updateCurrentSearchFilters({ audienceTypes: Array.from(set) })
    )
  }

  const createCheck = id => (
    <Checkbox
      checked={isChecked(id)}
      onChange={(e, checked) => setCheck(id, checked)}
    />
  )

  let errorMessage
  if (error) {
    errorMessage = <ErrorMessage>{error}</ErrorMessage>
  }

  return (
    <>
      <Fieldset error={!!error} {...props}>
        <Legend error={!!error}>
          {labelText}
          {required ? <Required /> : null}
        </Legend>
        <Row>
          <Col>           
            {customers && (
              <FormControlLabel
                label={customers.name}
                control={createCheck(customers.id)}
              />
            )}
            {employees && (
              <FormControlLabel
                label={employees.name}
                control={createCheck(employees.id)}
              />
            )}
            {members && (
              <FormControlLabel
                label={members.name}
                control={createCheck(members.id)}
              />
            )}
            {students && (
              <FormControlLabel
                label={students.name}
                control={createCheck(students.id)}
              />
            )}
            {other && (
              <FormControlLabel
                label={other.name}
                control={createCheck(other.id)}
              />
            )}
          </Col>
        </Row>
      </Fieldset>
      {errorMessage}
    </>
  )
}

export default AudienceTypes
