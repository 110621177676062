import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {styled} from '@material-ui/core/styles'
import MuiPaper from '@material-ui/core/Paper'
import MuiBeenhereIcon from '@material-ui/icons/Beenhere'
import MuiStarIcon from '@material-ui/icons/Stars'
import Logo from 'lms/components/Card/Logo'
import RankingBadge from 'lms/components/RankingBadge'
import Description from 'lms/components/Card/Description'
import EditButton from 'lms/components/Card/EditButton'
import SaveButton from 'lms/components/SaveButton'
import DetailDialog from './DetailDialog'
import {viewApplicationDetails} from 'lms/store/actions'
import CompareCheck from 'lms/components/Compare/Checkbox'
import {useCookies} from 'react-cookie'
import {useCurrentUser} from 'users/hooks'
import {THIRTY_DAYS} from 'constants/timeIntervals'

const Paper = styled(MuiPaper)(({theme}) => ({
  position: 'relative',
  padding: theme.spacing(2),
  paddingRight: theme.spacing(6),
  display: 'flex',
  minHeight: 150,

  '&:hover': {
    cursor: 'pointer'
  }
}))

const Left = styled('div')(({theme}) => ({
  flex: '0 0 auto',
  width: 150,
  marginRight: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
}))

const Main = styled('div')(({theme}) => ({
  flex: '1 1 auto'
}))

const Actions = styled('div')(({theme}) => ({
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(-1)
}))

const StyledRanking = styled(RankingBadge)(({theme}) => ({
  marginLeft: theme.spacing(2)
}))

const Title = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1)
}))

const Name = styled('h4')(({theme}) => ({
  margin: 0,
  fontSize: 18,
  fontWeight: 'bold',
  color: theme.palette.primary.main
}))

const Featured = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.secondary.light,
  marginLeft: theme.spacing(2)
}))

const FeaturedText = styled('div')(({theme}) => ({
  fontSize: 14,
  fontWeight: 500
}))

const FeaturedIcon = styled(MuiBeenhereIcon)(({theme}) => ({
  fontSize: 18,
  marginRight: '.25em',
  color: theme.palette.primary.dark
}))

const FeaturedIconPlatinum = styled(MuiStarIcon)(({theme}) => ({
  fontSize: 18,
  marginRight: '.25em',
  color: theme.palette.primary.dark
}))

const StyledEditButton = styled(EditButton)(({theme}) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1)
}))

const Card = ({lms, ...props}) => {
  const user = useCurrentUser()
  const isGuestUser = !user
  const [elevation, setElevation] = useState(1)
  const [showDialog, setShowDialog] = useState(false)
  const [cookies, setCookie] = useCookies(['guestApplicationViews'])
  const dispatchRedux = useDispatch()

  const handleMouseEnter = () => setElevation(20)
  const handleMouseLeave = () => setElevation(1)

  const handleOpenDialog = event => {
    setShowDialog(true)
    dispatchRedux(viewApplicationDetails(lms.id))
    if (isGuestUser) {
      const guestUserViewsCount = cookies.guestApplicationViews || 0
      setCookie(
        'guestApplicationViews',
        Number(guestUserViewsCount) + 1,
        {path: '/'},
        THIRTY_DAYS
      )
    }
  }

  const handleCloseDialog = event => {
    event.stopPropagation()
    setShowDialog(false)
  }

  const {
    id,
    thumbnail,
    rank,
    name,
    description,
    isPremium,
    isPlatinum
  } = lms

  let featured
  if (isPlatinum) {
    featured = (
      <Featured>
        <FeaturedIconPlatinum />
        <FeaturedText>Platinum</FeaturedText>
      </Featured>
    )
  } else if (isPremium) {
    featured = (
      <Featured>
        <FeaturedIcon />
        <FeaturedText>Premium</FeaturedText>
      </Featured>
    )
  }

  return (
    <Paper
      elevation={elevation}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleOpenDialog}
      {...props}
    >
      <Left>
        <Logo thumbnail={thumbnail} />
        <Actions>
          <SaveButton lms={lms} />
          <CompareCheck id={id} />
        </Actions>
      </Left>

      <Main>
        <Title>
          <Name>{name}</Name>
          <StyledRanking rank={rank}/>

          {featured}
        </Title>
        <Description
          description={description}
          systemId={id}
          isGuestUser={isGuestUser}
          isPremium={isPremium}
        />
      </Main>

      <StyledEditButton lms={lms} />

      <DetailDialog lms={lms} open={showDialog} onClose={handleCloseDialog} />
    </Paper>
  )
}

export default Card

