import React from 'react'
import {useDispatch} from 'react-redux'
import {styled} from '@material-ui/styles'
import MuiFormControlLabel from '@material-ui/core/FormControlLabel'
import MuiSwitch from '@material-ui/core/Switch'
import {useCurrentSearchFilters} from 'searches/hooks'
import {updateCurrentSearchFilters} from 'searches/store/actions'
import {useCookies} from 'react-cookie'
import {useCurrentUser} from 'users/hooks'
import { THIRTY_DAYS } from 'constants/timeIntervals'

const Label = styled(MuiFormControlLabel)(({theme}) => ({
  width: '100%',
  justifyContent: 'space-between',
  margin: '10px 0 10px 0',
  padding: '5px 14px',
  borderColor: 'rgba(0, 0, 0, 0.23)',
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: theme.shape.borderRadius
}))

const Migration = ({
  labelText = 'Supports migration from another Learning System',
  ...props
}) => {
  const {supportsMigration} = useCurrentSearchFilters()
  const dispatchRedux = useDispatch()
  const [cookies, setCookie] = useCookies(['guestSearchCount'])
  const user = useCurrentUser()
  const isGuestUser = !user

  const handleChange = (e, checked) => {
    if (isGuestUser) {
      const guestSearchCount = cookies.guestSearchCount || 0
      setCookie(
        'guestSearchCount',
        Number(guestSearchCount) + 1,
        {path: '/'},
        THIRTY_DAYS
      )
    }
    dispatchRedux(updateCurrentSearchFilters({supportsMigration: checked}))
  }

  const migrationSwitch = (
    <MuiSwitch
      color='primary'
      checked={supportsMigration}
      onChange={handleChange}
    />
  )

  return (
    <Label
      label={labelText}
      control={migrationSwitch}
      labelPlacement='start'
      {...props}
    />
  )
}

export default Migration
