import React, {useRef} from 'react'
import {useSortable} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'
import {styled} from '@material-ui/core/styles'
import {CircularProgress,Button as MuiButton} from "@material-ui/core"
import moment from "moment/moment"
import MuiIconButton from "@material-ui/core/IconButton"
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import CreateIcon from "@material-ui/icons/Create"
import {getDefaultThumbnail} from "./thumbnailUtils"
import FileUploadProgress from "./FileUploadProgress"
import {useSelector} from "react-redux"
import {getEditApplicationState} from "../../../store/selectors"

export function SortableItem({id, item, setSelectedItem, editSelectedItem, editable}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: id, disabled: !editable})

  let imgRetries = 0

  const img = useRef(null)

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }


  const {file, thumbnail} = item

  const reloadImage = () => {
    if (imgRetries < 5){
      imgRetries =  imgRetries++
      setTimeout(() => {
        if (img.current) {
          img.current.src = `${img.current.src}?${new Date().getTime()}`
        }
      },2000)
    }
  }

  const appState = useSelector(getEditApplicationState)
  let loaded
  let thumbnailfileUpload

  if (appState) {
    const {data} = appState

    const fileUploadItem = data.uploadingFiles?.find(i => i.id === file?.id)
    if (fileUploadItem) {
      loaded = fileUploadItem.loaded
    }
    thumbnailfileUpload = data.uploadingFiles?.find(i => i.id === thumbnail?.id)
  }

  return (
    <div className={`${editable ? 'playlist-item editable' : 'playlist-item'} `} ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <div className='playlist-item-details' onClick={() => setSelectedItem(item)}>
        {editable &&
        <div className='playlist-item-drag-indicator'><DragIndicatorIcon style={{margin: '0 -4px'}} /></div>
        }
        <div className='playlist-item-thumbnail-outer'>
          {  loaded > 0 &&
        <div className='playlist-item-progress'>
          <FileUploadProgress file={file} />
          <p>uploading in progress</p>          
        </div>
          }
          {!loaded &&
        <>
          <div className='playlist-item-thumbnail'>
            { thumbnailfileUpload &&
              <CircularProgress />
            }
            { !thumbnailfileUpload && thumbnail?.uri &&
              <img ref={img} alt='thumbnail' className='playlist-item-thumbnail-image' src={`${thumbnail.uri}?${new Date().getTime()}`} onError={() => reloadImage()} />
            }
            {  !thumbnail?.id &&
                getDefaultThumbnail(file?.mimeType)
            }
          </div>
            
        </>
          }
        </div>
        <div className='playlist-item-text'>
          <label className='playlist-item-title'>{item.title}</label>
          <label className='playlist-item-description'>{item.description}</label>
          {editable &&
            <label className='playlist-item-updated-date'>Last updated: {moment(item.updatedAt).format('YYYY-MM-DD')}</label>
          }
          <div className='playlist-item-actions'>
            <MuiButton
              color='primary'
              variant='contained'
              size="small"
              style={{marginTop: '0.5rem', padding: '0 10px',  boxShadow: 'none'}}
              onClick={() => setSelectedItem(item) }
            >
            VIEW
            </MuiButton>
          </div>
        </div>
      </div>

      {editable &&
        <div className='playlist-item-edit'>
          <MuiIconButton color="secondary" size="small" onClick={() => editSelectedItem(item)}>
            <CreateIcon/>
          </MuiIconButton>
        </div>
      }


    </div>
  )
}