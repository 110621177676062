import { intersection, isSuperset } from 'utils/sets'

export const applyFilters = (data, filters) => {
  const ids =
    data.allIds &&
    data.allIds.filter(id => {
      const lms = data.byId[id]

      if (filters.systemType) {
        if (lms.type !== filters.systemType) return false
      }

      if (filters.industries.length > 0) {
        // results must include ALL selected industries
        if (!isSuperset(lms.industries, filters.industries)) return false
      }

      if (filters.audienceTypes.length > 0) {
        // results must include ALL selected audience types
        if (!isSuperset(lms.audienceTypes, filters.audienceTypes)) return false
      }

      if (filters.courseStandards.length > 0) {
        // results include ANY selected course standard
        if (
          intersection(filters.courseStandards, lms.courseStandards).length ===
          0
        )
          return false
      }

      if (filters.deploymentTypes.length > 0) {
        // results must include ALL selected deployment types
        if (!isSuperset(lms.deploymentTypes, filters.deploymentTypes))
          return false
      }

      if (filters.features.length > 0) {
        // results must include ALL selected deployment types
        if (!isSuperset(lms.features, filters.features)) return false
      }

      if (filters.numLearners.min) {
        if (lms.maxLearners < filters.numLearners.min) return false
      }

      if (filters.numLearners.max) {
        if (lms.minLearners > filters.numLearners.max) return false
      }

      if (filters.ranking) {
        if (!lms.rank || lms.rank > filters.ranking) return false
      }

      if (filters.setupTime.max) {
        if (lms.minSetupTime > filters.setupTime.max) return false
      }

      if (filters.maxPrice) {
        if (lms.priceFrom > filters.maxPrice) return false
      }

      if (filters.supportsMigration && !lms.migration) return false

      if (filters.businessSegment) {
        return lms.businessSegments && lms.businessSegments.includes(filters.businessSegment)
      }

      return true
    })

  return ids
}
