import React from 'react'
import {styled} from '@material-ui/core/styles'
import SectionTitle from 'lms/components/Detail/SectionTitle'
import MuiPictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'

const List = styled('ul')({
  marginTop: 24,
  paddingLeft: 24,
})

const ListItem = styled('li')({
  listStyleType: 'none',
  display: 'flex',
  marginBottom: 6,
})

const PDFIcon = styled(MuiPictureAsPdfIcon)({
  marginRight: '0.56em',
})

const DownloadLink = styled('a')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  color: theme.palette.primary.dark,
}))

const WhitePapers = ({whitePapers=[]}) => {
  if (whitePapers.length === 0) return null

  return (
    <>
      <SectionTitle>Whitepapers</SectionTitle>
      <List>
        {whitePapers.map(({id, name, uri}) => (
          <ListItem key={id}>
            <DownloadLink href={uri} target="_blank" rel="noopener nofollow">
              <PDFIcon/>
              {name}
            </DownloadLink>
          </ListItem>
        ))}
      </List>
    </>
  )
}

export default WhitePapers
