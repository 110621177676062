import React from 'react'
import {styled} from '@material-ui/core/styles'
import {pricesToString} from 'lms/utils'

const Title = styled('span')(({theme}) => ({
}))

const Values = styled('span')(({theme}) => ({
  fontWeight: 300,
}))

const Pricing = ({priceFrom, priceTo}) => {
		const priceString = pricesToString(priceFrom, priceTo)

  return (
    <>
      <Title>Pricing: </Title>
      <Values>{priceString}</Values>
    </>
  )
}

export default Pricing
