import React from 'react'
import {
  styled,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  MenuItem as MuiMenuItem,
  OutlinedInput as MuiOutlinedInput,
  Select as MuiSelect,
} from '@material-ui/core'
import {useLabelWidth} from 'ui/hooks'
import {useEditApplication} from 'lms/hooks'

//import MuiStarBorderIcon from '@material-ui/icons/StarBorder'
//import { useEditApplication } from 'lms/hooks'
//import Rating from '@material-ui/lab/Rating'
//import { RATING_TYPES } from './constants'

const FormControl = styled(MuiFormControl)({
  marginTop: 12,
  marginBottom: 0,
})
/*
const StyledRating = withStyles(theme => ({
  iconFilled: {
    color: theme.palette.primary.main
  },
  iconHover: {
    color: theme.palette.primary.main
  },
  sizeSmall: {
    fontSize: '1.75em'
  },
  sizeLarge: {
    fontSize: '2.25em'
  }
}))(Rating)

const StarBorderIcon = withStyles(theme => ({}))(MuiStarBorderIcon)

const Content = styled('div')({})
const RatingContainer = styled('div')({
  flexBasis: '50%'
})
const ContentContainer = styled('div')({
  display: 'flex'
})

const RatingWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '.5rem 0',
  alignItems: 'center'
})
const RatingValue = styled('p')({
  margin: 0,
  marginLeft: '.25rem',
  color: 'rgba(0, 0, 0, 0.54)'
})
const RatingValueWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
})

const OverallRatingContainer = styled('div')({
  textAlign: 'center',
  flexBasis: '50%'
})
const OverallRatingTitle = styled('p')({})
const OverallRatingValue = styled('p')({
  margin: '0'
})
const Heading = styled('h4')(({ theme }) => ({
  margin: 0,
  marginTop: 12,
  marginBottom: 6,
  fontSize: 18,
  fontWeight: 500,
  color: theme.palette.text.secondary,
  borderBottom: `1px solid ${theme.palette.divider}`
}))
*/
const InputLabel = styled(MuiInputLabel)({
  whiteSpace: 'nowrap',
  //color: 'rgba(0, 0, 0, 0.87)',
  //position: 'relative',
  //top: '2px'
})

const Ranking = (props) => {
  const [application, updateApplication] = useEditApplication()
  const [labelWidth, labelRef] = useLabelWidth()

  const rank = application.rank || "none"

/*
const defaultRatings = {
  learnerExperience: 0,
  administrationUX: 0,
  nexGenFeatures: 0,
  systemUI: 0,
  metricsAndReporting: 0,
  servicesAndSupport: 0,
  enterpriseStrength: 0,
  customerEducationStrength: 0,
  average: 0
}
*/
const handleChange = (event) => {
  let rank = event.target.value

  if (rank === 'none') rank = undefined
  updateApplication({rank})

}
/*
const Ranking = props => {
  const [application, updateApplication] = useEditApplication()

  const ratingsValues = application.craigsRatings || defaultRatings
  const { average: averageRatingFromDb } = ratingsValues

  const handleRatingChange = ({ value, ratingType }) => {
    const newValue = value
    const newRatings = { ...ratingsValues }
    if (newValue) {
      newRatings[ratingType] = newValue
      updateApplication({ craigsRatings: newRatings })
    }
  }
  */
  const input = <MuiOutlinedInput id="ranking" labelWidth={labelWidth} name="ranking"/>

/*
  const getAverageRating = () => {
    const totalSum = Object.keys(ratingsValues).reduce((total, key) => {
      if (key !== 'average') {
        const currentValue = ratingsValues[key] || 0
        total += currentValue
      }
      return total
    }, 0)
    const numberOfRatings = Object.keys(ratingsValues).length - 1
    return totalSum / numberOfRatings
  }
  const averageRating = getAverageRating()
  const roundedAverageRating = averageRating
    ? Math.floor(averageRating * 2) / 2
				: 0
		const averageRatingToDisplay = (roundedAverageRating || averageRatingFromDb) || 0
    */
  return (
    <>
    <FormControl
    variant="outlined"
    margin="dense"
    fullWidth
    {...props}
  >
    <InputLabel ref={labelRef} htmlFor="Ranking">Craig's Top Learning Systems</InputLabel>
    <MuiSelect
      name="Ranking"
      value={rank}
      onChange={handleChange}
      input={input}
    >
      <MuiMenuItem value="none">Select a ranking</MuiMenuItem>
      <MuiMenuItem value={10}>Top 10</MuiMenuItem>
      <MuiMenuItem value={20}>Top 20</MuiMenuItem>
      <MuiMenuItem value={50}>Top 50</MuiMenuItem>
    </MuiSelect>
    </FormControl>

    {/*<Content>
      <Heading>Craig's Ratings</Heading>
      <ContentContainer>
        <RatingContainer>
          {RATING_TYPES.map(rating => {
            const { ratingType, displayName } = rating
            const value = ratingsValues[ratingType]
            const roundedRating = Math.floor(value * 2) / 2
            return (
              <RatingWrapper key={ratingType}>
                <InputLabel htmlFor={ratingType}>{displayName}</InputLabel>
                <RatingValueWrapper>
                  <StyledRating
                    name={ratingType}
                    value={roundedRating}
                    precision={0.5}
                    emptyIcon={<StarBorderIcon fontSize='inherit' />}
                    size='small'
                    onChange={(e, value) =>
                      handleRatingChange({ value, ratingType })
                    }
                  />
                  <RatingValue>{value ? value.toFixed(1) : '0.0'}</RatingValue>
                </RatingValueWrapper>
              </RatingWrapper>
            )
          })}
        </RatingContainer>
        <OverallRatingContainer>
          <OverallRatingTitle>Overall Rating</OverallRatingTitle>
          <StyledRating
            name='averageRating'
            value={roundedAverageRating || averageRatingFromDb}
            emptyIcon={<StarBorderIcon fontSize='inherit' />}
            precision={0.5}
            size='large'
            readOnly
          />
          <OverallRatingValue>
            {averageRatingToDisplay.toFixed(1)} out of 5
          </OverallRatingValue>
        </OverallRatingContainer>
      </ContentContainer>
        </Content>*/}
        </>
  )
}

export default Ranking
